import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import LoginPage from './views/LoginPage/LoginPage';
import LandingPage from './views/LandingPage/LandingPage';
import RegistrationPage from './views/RegistrationPage/RegistrationPage';
import ResetPassword from './views/ResetPassword/ResetPassword';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import HostelListingPage from './views/HostelPages/HostelListingPage';
import TenantRequestPage from './views/HostelPages/TenantRequestPage';
import AddUpdateHostelPage from './views/HostelPages/AddUpdateHostelPage';
import HostelDetailPage from './views/HostelPages/HostelDetailPage';

import AddUpdateTenantPage from "./views/TenantPages/AddUpdateTenantPage";

import TenantListingPage from './views/TenantPages/TenantListingPage';
import DashboardPage from './views/Dashboard/Dashboard';
import UserProfilePage from './views/UserProfilePage/UserProfilePage';
import MyAccountPage from './views/MyAccountPage/MyAccountPage';
import HostelBookingPage from "./views/HostelPages/HostelBookingPage";

// Footer Pages
import AboutUsPage from "./views/FooterPages/AboutUsPage";
import CareerPage from "./views/FooterPages/CareerPage";
import BlogPage from "./views/FooterPages/BlogPage";
import ContactUsPage from "./views/FooterPages/ContactUsPage";
import PrivacyPolicyPage from "./views/FooterPages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./views/FooterPages/TermsAndConditionsPage";

// Error Pages
import Error404 from "./views/ErrorPages/Error404";
import Error403 from "./views/ErrorPages/Error403";

// API Calls
// import ApiWrapper from "./apis/DefaultApiWrapper";

// ENV Settings
// import config from "./config.json";


import {
	BrowserRouter as Router,
	Switch,
	Route,
	useHistory
} from "react-router-dom";

// Redux store
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers from "./reducers";

// import { createBrowserHistory } from 'history';
// var browserhistory = createBrowserHistory();

const store = createStore(reducers,
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

function App() {
	
	const history = useHistory();

	return (
		<Provider store={store}>
			<Router history={history}>
				<div>
					<Switch>
						<Route exact path="/login/" component={LoginPage} />
						<Route exact path="/create-account/" component={RegistrationPage} />
						<Route exact path="/forgot-password/" component={ForgotPassword} />
						<Route exact path="/reset-password/:token" component={ResetPassword} />

						<Route exact path="/city/:uuid/hostels/" component={HostelListingPage} />
						<Route exact path="/:uuid/hostels/" component={HostelListingPage} />

						<Route exact path="/add-hostel/" component={AddUpdateHostelPage} />
						<Route exact path="/hostel/:uuid/" component={HostelDetailPage} />
						<Route exact path="/update-hostel/:uuid/" component={AddUpdateHostelPage} />
						<Route exact path="/tenant-requests/:uuid/" component={TenantRequestPage} />

						<Route exact path="/hostel-booking/:tenantRequestID/" component={HostelBookingPage} />

						<Route exact path="/my-account/" 
							render={(props) => (
								<MyAccountPage {...props}/>
							)}
						/>
						{/* <Route exact path="/my-account/bookings/" 
							render={(props) => (
								<MyAccountPage {...props} ActiveTab={0} />
							)}
						/>

						<Route exact path="/my-account/reviews/" 
							render={(props) => (
								<MyAccountPage {...props} ActiveTab={1} />
							)}
						/>

						<Route exact path="/my-account/payments/" 
							render={(props) => (
								<MyAccountPage {...props} ActiveTab={2} />
							)}
						/> */}

						{/* Tenant Urls */}
						<Route exact path="/hostel/:uuid/tenants/" component={TenantListingPage} />
						<Route exact path="/add-tenant/" component={AddUpdateTenantPage} />

						{/* Error Pages */}
						<Route exact path="/error404/" component={Error404} />
						<Route exact path="/error403/" component={Error403} />

						{/* Footer Links */}
						<Route exact path="/about-us/" component={AboutUsPage} />
						<Route exact path="/careers/" component={CareerPage} />
						<Route exact path="/blog/" component={BlogPage} />
						<Route exact path="/contact-us/" component={ContactUsPage} />
						<Route exact path="/terms-and-conditions/" component={TermsAndConditionsPage} />
						<Route exact path="/privacy-policy/" component={PrivacyPolicyPage} />

						<Route exact path="/dashboard/" component={DashboardPage} />
						<Route exact path="/user-profile/:uuid/" component={UserProfilePage} />
						<Route exact path="/logout/" component={LoginPage}/>
						<Route exact path="/" component={LandingPage}/>
					</Switch>
				</div>
			</Router>
		</Provider>
	)
}

// const routing = (
// 	<Provider store={store}>
// 		<Router history={browserhistory}>
// 			<div>
// 				<Switch>
// 					<Route path="/login/" component={LoginPage} />
// 					<Route path="/create-account" component={RegistrationPage} />
// 					<Route path="/forgot-password" component={ForgotPassword} />
// 					<Route path="/password-reset" component={ResetPassword} />
// 					<Route path="/dashboard/" component={Dashboard} />
// 					<Route path="/logout/" component={LoginPage}/>
// 					<Route path="/" component={LandingPage}/>
// 				</Switch>
// 			</div>
// 		</Router>
// 	</Provider>
// );

ReactDOM.render(<App/>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
