import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import "../../styles/ImageCarousel.scss";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import hostlr_1 from "../../assets/images/hostlr_1.jpg";
import hostlr_2 from "../../assets/images/hostlr_2.jpg";
import hostlr_3 from "../../assets/images/hostlr_3.jpg";
import hostlr_4 from "../../assets/images/hostlr_4.jpg";
import {
    Card,
    CardMedia,
    Typography,
    Grid
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative',
	},
	title: {
		marginLeft: theme.spacing(2),
		flex: 1,
	},
}));

const items = [
	{
		name: "Affordable Price",
		image: hostlr_1
	},
	{
		name: "As Cheap As You Desire",
		image: hostlr_2
	},
	{
		name: "Contact Us For More Detail",
		image: hostlr_3
	},
	{
		name: "Affordable Price",
		image: hostlr_4
	},
	{
		name: "As Cheap As You Desire",
		image: hostlr_2
	},
	{
		name: "Contact Us For More Detail",
		image: hostlr_3
	}
]

const carouselSetttings = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
	  	items: 1,
	  	slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function Banner(props) {
	
	const { item } = props;

    return (
		<Card 
			raised 
			className="Banner"
		>
            <Grid container spacing={0} className="BannerGrid">
				<Grid item xs={12} key={item.name}>
					<CardMedia
						className="Media"
						image={item.image}
						title={item.name}
					>
						<Typography className="MediaCaption">
							{item.name}
						</Typography>
					</CardMedia>

				</Grid>
            </Grid>
        </Card>
    )
}

function ImageViewer(props) {

	const { open, setOpen, images, title } = props;
	const classes = useStyles();

	const [ medias, setMedias] = useState([]);

	useEffect(() => {

		if(images && images.length > 0) {
			setMedias(images);
		} else {
			setMedias(items);
		}

	}, [images]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className={classes.dialog}>
			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>
						{ title ? title : "Images" }
					</Typography>
					<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
				<div className="Page">

					<Carousel
						additionalTransfrom={0}
						arrows
						autoPlaySpeed={3000}
						centerMode={false}
						className=""
						containerClass="container"
						responsive={carouselSetttings}
						dotListClass=""
						draggable
						focusOnSelect={false}
						infinite={false}
						itemClass=""
						keyBoardControl
						minimumTouchDrag={80}
						renderButtonGroupOutside={false}
						renderDotsOutside={false}
						showDots={true}
					>
						{
							medias.map((item, index) => {
								return <Banner item={item} key={index} />
							})
						}
					</Carousel>
				</div>
			</Dialog>
		
		</div>
	);
}

export default ImageViewer;
