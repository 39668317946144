import React, {useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MaterialUILink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import HostlrImage from '../../assets/images/hostlr_3.jpg';
import HostlrLogo from '../../assets/images/hostlr.png';

import config from "../../config.json";
import ApiWrapper from "../../apis/DefaultApiWrapper";
import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<MaterialUILink color="inherit" href="http://hostlr.in/" target="_blank">
				Hostlr
			</MaterialUILink>2020 - {' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: `url(${HostlrImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
		theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(12, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundImage: `url(${HostlrLogo})`,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
        width: "98%",
		margin: theme.spacing(3, 0, 2),
    },

    cancel: {
        width: "98%",
		margin: theme.spacing(3, 0.5, 2),
    },

    backButtonStyle: {
        top: "50px",
        position: "absolute",
        left: "40px"
	},

	error: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#f50057"
	},

	success: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#317326"
	},

	noUnderScore: {
		textDecoration: "none",
		'&:hover': {
			textDecoration: "underline",
		},
	}

}));

function ResetPassword(props) {

	const classes = useStyles();
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [apiFetchError, setApiFetchError] = useState(null);

	const token = props.match.params.token;

	const [state, setState] = useState({
		password: "",
		confirmPassword: ""
	});

	function validateForm() {
		if(state.password === "" || state.confirmPassword === "" || state.password !== state.confirmPassword) {
			setState({
				...state,
				passwordError: state.passwordError === "" ? true : false,
				confirmPasswordError: state.confirmPasswordError === "" || state.confirmPasswordError ? true : false,
			})
			return false
		} else {
			setState({
				...state,
				passwordError: false,
				confirmPasswordError: false
			})
			return true
		}
	}

	function clearFormData() {
		setState({
			...state,
			password: "",
			confirmPassword: "",
			passwordError: false,
			confirmPasswordError: false

		});
	}

	useEffect(() => {

		const url = `${config.BASE_APP_URL}/forgot-password/?token=${token}`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setError(null);
					setMessage(null);
					setApiFetchError(null);

				} else{
					setError(null);
					setMessage(null);
					setApiFetchError(result.message)
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setMessage(null);
				setApiFetchError('Something went wrong!');
			}
		)
	}, [token]);

	function resetPassword(event) {

		event.preventDefault();

		if(!validateForm()) {
			return false
		}

		setIsLoading(true);

		const data = {
			token: token,
			password: state.password,
			confirm_password: state.confirmPassword
		}

		const url = `${config.BASE_APP_URL}/forgot-password/`;
		const options = {
			method:'put',
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setError(null);
					clearFormData();
					setMessage(result.message);

					setError(null);
					setApiFetchError(null);

				} else{
					setMessage(null);
					setApiFetchError(null);
					setError(result.message);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setMessage(null);
				setApiFetchError(null);
				setError('Something went wrong!');
			}
		)
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />

			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

				{ apiFetchError ?
					<div className={classes.paper}>
						{apiFetchError}
					</div> :

					<div className={classes.paper}>
						<Avatar className={classes.avatar}>
						</Avatar>
						<form className={classes.form} noValidate onSubmit={resetPassword}>
							<TextField
								error={state.passwordError ? true : false}
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="password"
								label="Password"
								name="password"
								type="password"
								autoFocus
								value={state.password}
								onInput = {
									event => setState({
										...state,
										password: event.target.value,
										passwordError: false
									})
								}
								helperText={state.passwordError ? "This is required field" : ""}
							/>

							<TextField
								error={state.confirmPasswordError ? true : false }
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="conformPassword"
								label="Confirm Password"
								name="confirmPassword"
								type="password"
								autoFocus
								value={state.confirmPassword}
								onInput = {
									event => setState({
										...state,
										confirmPassword: event.target.value,
										confirmPasswordError: state.password === event.target.value ? false : true
									})
								}
								helperText={state.confirmPasswordError && state.confirmPassword === "" ? "This is required field" : state.password === state.confirmPassword ? "" : "Password and confirm Password did not match"}
							/>

							<Grid container>
								<Grid item xs>
									<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										Reset Password
									</Button>
								</Grid>
								<Grid item xs>
									<Link 
										to={`/`}
										className={classes.noUnderScore}
									>
										<Button
											fullWidth
											variant="contained"
											size="medium"
											color="secondary"
											className={classes.cancel}
											disabled={true ? isLoading: false}
										>
											Cancel
										</Button>
									</Link>
								</Grid>
							</Grid>

							{ error ?
								<div className={classes.error}>
									{error}
								</div> : ""
							}

							{message ?
								<div className={classes.success}>
									{message}
								</div> : ""
							}

							<Grid container>
								<Grid item xs>
									<Link to="/login" className={classes.noUnderScore}>
										Remember Password?
									</Link>
								</Grid>
								<Grid item>
									<Link to="/create-account/" className={classes.noUnderScore}>
										{"Don't have an account? Sign Up"}
									</Link>
								</Grid>
							</Grid>

							<Box mt={5}>
								<Copyright />
							</Box>
						</form>
						
					</div>
				}

			</Grid>
		
			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/>}
		</Grid>
	);
}

export default ResetPassword;