import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Switch from '@material-ui/core/Switch';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

// DropDown
import Autocomplete from '@material-ui/lab/Autocomplete';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

import ApiWrapper from "../../apis/DefaultApiWrapper";
import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";
import config from "../../config.json";

import ToastNotification from "../../components/CommonFunctions/ToastNotification";


import {
    Paper,
    Grid
} from '@material-ui/core';

// Form Fields
import TextField from '@material-ui/core/TextField';

// import {DropzoneArea} from 'material-ui-dropzone';


const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		minHeight: "100vh",
		background: "#f0f8ff"
	},
	button: {
		marginTop: theme.spacing(1),
		marginRight: theme.spacing(1),
	},
	actionsContainer: {
		marginBottom: theme.spacing(2),
	},
	resetContainer: {
		padding: theme.spacing(3),
	},

	facilities: {
		textAlign: "left !important"
	},

	previousImages: {
		padding: "20px"
	},

	deleteButton: {
		cursor: "pointer"
	},

	fileInput: {
		padding: "20px",
		width: "100%",
		border: "1px solid lightgray",
		color: "#6495ed"
	},

	availableFor: {
		paddingTop: "4px !important"
	}

}));

function getSteps() {
	return ['Basic Detail', 'Address', 'Hostel Occupancy', 
			'Hostel Availability', 'Facilities', 'Images'];
}

function FacilitySwitch(props) {

	const [state, setState] = useState({
		label: props.label,
		// disabled: !props.disabled ? false:true,
		isSelected: props.isSelected
	});

	const { index, currentState, setCurrentState } = props

	const handleChange = (event) => {
		setState({ ...state, isSelected: event.target.checked });

		let updatedFacilities = currentState.facilities.slice();
		updatedFacilities[index].is_selected = event.target.checked;
		
		setCurrentState({ ...currentState, facilities: updatedFacilities })

	};

	return (
		<FormControlLabel
			control={
				<Switch
					// disabled={state.disabled}
					checked={state.isSelected}
					onChange={handleChange}
					name={state.label}
					color="primary"
				/>
			}
			label={state.label}
		/>
	);
}

function HostelBasicDetailContainer(props) {

	const classes = useStyles();
	const { state, setState, genderChoices } = props;

	const [genderValue, setGenderValue] = useState(state.genderValue);
	const [inputGenderValue, setInputGenderValue] = useState(state.inputGenderValue);


	return (
		<Grid container spacing={3}>

			<Grid item xs={12}>
				<TextField 
					error={state.nameError ? true : false}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="name"
					label="Name"
					name="name"
					value={state.name}
					onInput = { 
						event => setState({
							...state,
							name: event.target.value,
							nameError: false
						})
					}
					helperText={state.nameError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12}>
				<TextField 
					error={state.descriptionError ? true : false}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					multiline
					rows={3}
					id="description"
					label="Description"
					name="description"
					value={state.description}
					onInput = { 
						event => setState({
							...state,
							description: event.target.value,
							descriptionError: false
						})
					}
					helperText={state.descriptionError ? "This is required field!" : ""}
				/>
			</Grid>


			<Grid item xs={12} md={4} lg={4} className={classes.availableFor}>	
				<Autocomplete
					id="available-for"
					options={genderChoices}
					getOptionSelected={(option, value) => value && option ? option.title === value.title : ""}
					value={state.genderValue ? state.genderValue : genderValue}
					getOptionLabel={(option) => option ? option.title : ""}
					fullWidth
					required
					onChange={(event, newValue) => {
						setGenderValue(newValue);
						setState({
							...state,
							genderValue: newValue,
							availableFor: newValue ? newValue.value : "",
							availableForError: false
						})
					}}
					inputValue={state.inputGenderValue ? state.inputGenderValue : inputGenderValue}
					onInputChange={(event, newInputValue) => {
						setInputGenderValue(newInputValue);
						setState({
							...state,
							inputGenderValue: newInputValue,
							availableForError: false
						})
					}}
					renderInput={
						(params) => <TextField 
							error={state.availableForError ? true : false}
							{...params} 
							label="Available For" 
							variant="outlined" margin="dense"
							helperText={state.availableForError ? "This is required field!" : ""}
						/>
					}
				/>

			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<FormControl fullWidth variant="outlined">
					{ state.minimumRentError ? 
						<InputLabel htmlFor="outlined-adornment-amount"
							style={{color: "#ed4234"}}
						>
							Minimum Rent
						</InputLabel> : 
						<InputLabel htmlFor="outlined-adornment-amount">
							Minimum Rent
						</InputLabel>
					}
					<OutlinedInput
						error={state.minimumRentError ? true : false}
						variant="outlined"
						margin="dense"
						required
						fullWidth
						label="Minimum Rent"
						name="minimumRent"
						onInput = { 
							event => setState({
								...state,
								minimumRent: event.target.value,
								minimumRentError: isNaN(event.target.value) ? true : false
							})
						}
						id="outlined-adornment-amount"
						value={state.minimumRent}
						// onChange={handleChange('amount')}
						startAdornment={<InputAdornment position="start"> &#x20B9; </InputAdornment>}
						labelWidth={60}
					/>
					<FormHelperText id="my-helper-text" style={{color: "#ed4234"}}>
						{isNaN(state.minimumRent) ? "Minimum Rent should be number" : state.minimumRentError ? "This is required field!" : ""}
					</FormHelperText>
				</FormControl>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<FormControl fullWidth variant="outlined">
					{ state.maximumRentError ? 
						<InputLabel htmlFor="outlined-adornment-amount"
							style={{color: "#ed4234"}}
						>
							Maximum Rent
						</InputLabel> : 
						<InputLabel htmlFor="outlined-adornment-amount">
							Maximum Rent
						</InputLabel>
					}
					<OutlinedInput
						error={state.maximumRentError ? true : false}
						variant="outlined"
						margin="dense"
						required
						fullWidth
						label="Maximum Rent"
						name="maximumRent"
						onInput = { 
							event => setState({
								...state,
								maximumRent: event.target.value,
								maximumRentError: isNaN(event.target.value) ? true : false
							})
						}
						id="outlined-adornment-amount"
						value={state.maximumRent}
						// onChange={handleChange('amount')}
						startAdornment={<InputAdornment position="start"> &#x20B9; </InputAdornment>}
						labelWidth={60}
					/>
					<FormHelperText id="my-helper-text" style={{color: "#ed4234"}}>
						{isNaN(state.maximumRent) ? "Maximum Rent should be number" : state.maximumRentError ? "This is required field!" : ""}
					</FormHelperText>
				</FormControl>
			</Grid>
		</Grid>
	)

}

function HostelAddressContainer(props) {

	// const classes = useStyles();
	const { state, setState, cityOptions } = props;

	const [ cityValue, setCityValue] = useState(state.cityValue);
	const [ inputCityValue, setInputCityValue] = useState(state.inputCityValue);

	function handlePincode(event) {
		if(isNaN(state.pincode) || state.pincode.length > 6 || state.pincode.length < 6) {
			setState({
				...state,
				pincodeError: true
			})
		} else {
			setState({
				...state,
				pincodeError: false
			})
		}
	}

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} md={6} lg={6}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="firstLine"
					label="First Line"
					name="firstLine"
					value={state.firstLine}
					onInput = { 
						event => setState({
							...state,
							firstLine: event.target.value,
							firstLineError: false
						})
					}
					error={state.firstLineError ? true : false}
					helperText={state.firstLineError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={6} lg={6}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="secondLine"
					label="Second Line"
					name="secondLine"
					value={state.secondLine}
					onInput = { 
						event => setState({
							...state,
							secondLine: event.target.value,
							secondLineError: false
						})
					}
					error={state.secondLineError ? true : false}
					helperText={state.secondLineError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<Autocomplete
					id="city"
					options={cityOptions}
					getOptionSelected={(option, value) => option.name === value.name}
					value={state.cityValue ? state.cityValue : cityValue}
					getOptionLabel={(option) => option ? option.name : ""}
					fullWidth
					onChange={(event, newValue) => {
						setCityValue(newValue);
						setState({
							...state,
							cityValue: newValue,
							city: newValue ? newValue.uuid : "",
							cityError: false
						})
					}}
					inputValue={state.inputCityValue ? state.inputCityValue : inputCityValue}
					onInputChange={(event, newInputValue) => {
						setInputCityValue(newInputValue);
						setState({
							...state,
							inputCityValue: newInputValue,
						})
					}}
					renderInput={
						(params) => <TextField 
							{...params} 
							label="City" 
							variant="outlined" 
							margin="dense"
							error={state.cityError ? true : false}
							helperText={state.cityError ? "This is required field!" : ""}
						/>
					}
				/>
				
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="state"
					label="State"
					name="state"
					value={state.state}
					onInput = { 
						event => setState({
							...state,
							state: event.target.value,
							stateError: false
						})
					}
					error={state.stateError ? true : false}
					helperText={state.stateError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="pincode"
					label="Pin Code"
					name="pincode"
					value={state.pincode}
					onInput = { 
						event => setState({
							...state,
							pincode: event.target.value,
							pincodeError: isNaN(event.target.value) ? true : false
						})
					}
					onBlur={handlePincode}
					error={state.pincodeError ? true : false}
					helperText={state.pincodeError ? "Pincode should be 6 digits!" : ""}
				/>
			</Grid>

		</Grid>
	)
}

function HostelCapacityContainer(props) {

	// const classes = useStyles();
	const { state, setState } = props;

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.singleSharingCapacity}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="singleSharingCapacity"
					label="Single Sharing Occupancy"
					name="singleSharingCapacity"
					onInput = { 
						event => setState({
							...state,
							singleSharingCapacity: event.target.value,
							singleSharingCapacityError: isNaN(event.target.value) ? true : false
						})
					}
					error={state.singleSharingCapacityError ? true : false}
					helperText={state.singleSharingCapacityError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.doubleSharingCapacity}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="doubleSharingCapacity"
					label="Double Sharing Occupancy"
					name="doubleSharingCapacity"
					onInput = { 
						event => setState({
							...state,
							doubleSharingCapacity: event.target.value,
							doubleSharingCapacityError: isNaN(event.target.value) || (!isNaN(event.target.value) && event.target.value%2 !== 0) ? true : false,
							isDoubleSharingCapacityValid: !isNaN(event.target.value) && event.target.value%2 === 0 ? true : false
						})
					}
					error={state.doubleSharingCapacityError ? true : false}
					helperText={state.doubleSharingCapacityError && !state.isDoubleSharingCapacityValid ? "Field value should be multiple of 2" : state.doubleSharingCapacityError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.tripleSharingCapacity}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="tripleSharingCapacity"
					label="Triple Sharing Occupancy"
					name="tripleSharingCapacity"
					onInput = { 
						event => setState({
							...state,
							tripleSharingCapacity: event.target.value,
							tripleSharingCapacityError: isNaN(event.target.value) || (!isNaN(event.target.value) && event.target.value%3 !== 0) ? true : false,
							isTripleSharingCapacityValid: !isNaN(event.target.value) && event.target.value%3 === 0 ? true : false
						})
					}
					error={state.tripleSharingCapacityError ? true : false}
					helperText={state.tripleSharingCapacityError && !state.isTripleSharingCapacityValid ? "Field value should be multiple of 3" : state.tripleSharingCapacityError ? "Field should be number!" : ""}
				/>
			</Grid>
		
			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.singleSharingRent}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="singleSharingRent"
					label="Single Sharing Rent"
					name="singleSharingRent"
					onInput = { 
						event => setState({
							...state,
							singleSharingRent: event.target.value,
							singleSharingRentError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false,
							isSingleSharingRentValid: !isNaN(event.target.value) && (event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false
						})
					}
					error={state.singleSharingRentError ? true : false}
					helperText={state.singleSharingRentError && !state.isSingleSharingRentValid ? "Rent should be between minimum and maximum rent" : state.singleSharingRentError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.doubleSharingRent}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="doubleSharingRent"
					label="Double Sharing Rent"
					name="doubleSharingRent"
					onInput = { 
						event => setState({
							...state,
							doubleSharingRent: event.target.value,
							doubleSharingRentError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false,
							isDoubleSharingRentValid: !isNaN(event.target.value) && (event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false
						})
					}
					error={state.doubleSharingRentError ? true : false}
					helperText={state.doubleSharingRentError && !state.isDoubleSharingRentValid ? "Rent should be between minimum and maximum rent" : state.doubleSharingRentError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.tripleSharingRent}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="tripleSharingRent"
					label="Triple Sharing Rent"
					name="tripleSharingRent"
					onInput = { 
						event => setState({
							...state,
							tripleSharingRent: event.target.value,
							tripleSharingRentError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false,
							isTripleSharingRentValid: !isNaN(event.target.value) && (event.target.value >= state.minimumRent && event.target.value <= state.maximumRent) ? true : false
						})
					}
					error={state.tripleSharingRentError ? true : false}
					helperText={state.tripleSharingRentError && !state.isTripleSharingRentValid ? "Rent should be between minimum and maximum rent" : state.tripleSharingRentError ? "Field should be number!" : ""}
				/>
			</Grid>

		</Grid>
	)
}

function HostelAvailabilityContainer(props) {

	// const classes = useStyles();
	const { state, setState } = props;

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.singleSharingAvailability}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="singleSharingAvailability"
					label="Single Sharing Availability"
					name="singleSharingAvailability"
					onInput = { 
						event => setState({
							...state,
							singleSharingAvailability: event.target.value,
							singleSharingAvailabilityError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value <= state.singleSharingCapacity)? true : false,
							isSingleSharingAvailabilityValid: !isNaN(event.target.value) && event.target.value <= state.singleSharingCapacity ? true : false
						})
					}
					error={state.singleSharingAvailabilityError ? true : false}
					helperText={state.singleSharingAvailabilityError && !state.isSingleSharingAvailabilityValid ? "Single sharing availability should be less than single sharing capacity" : state.singleSharingAvailabilityError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.doubleSharingAvailability}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="doubleSharingAvailability"
					label="Double Sharing Availability"
					name="doubleSharingAvailability"
					onInput = { 
						event => setState({
							...state,
							doubleSharingAvailability: event.target.value,
							doubleSharingAvailabilityError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value <= state.doubleSharingCapacity)? true : false,
							isDoubleSharingAvailabilityValid: !isNaN(event.target.value) && event.target.value <= state.doubleSharingCapacity ? true : false
						})
					}
					error={state.doubleSharingAvailabilityError ? true : false}
					helperText={state.doubleSharingAvailabilityError && !state.isDoubleSharingAvailabilityValid ? "Double sharing availability should be less than double sharing capacity" : state.doubleSharingAvailabilityError ? "Field should be number!" : ""}
				/>
			</Grid>

			<Grid item xs={12} md={4} lg={4}>
				<TextField 
					value={state.tripleSharingAvailability}
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="tripleSharingAvailability"
					label="Triple Sharing Availability"
					name="tripleSharingAvailability"

					onInput = { 
						event => setState({
							...state,
							tripleSharingAvailability: event.target.value,
							tripleSharingAvailabilityError: isNaN(event.target.value) || !(!isNaN(event.target.value) && event.target.value <= state.tripleSharingCapacity)? true : false,
							isTripleSharingAvailabilityValid: !isNaN(event.target.value) && event.target.value <= state.tripleSharingCapacity ? true : false
						})
					}
					error={state.tripleSharingAvailabilityError ? true : false}
					helperText={state.tripleSharingAvailabilityError && !state.isTripleSharingAvailabilityValid ? "Triple sharing availability should be less than triple sharing capacity" : state.tripleSharingAvailabilityError ? "Field should be number!" : ""}
				/>
			</Grid>
		</Grid>
	)

}

function HostelFacilitiesContainer(props) {

	const classes = useStyles();
	const { facilities, state, setState } = props;

	return (
		<Grid container spacing={1}>
			{facilities && facilities.map((facility, index) => (
				<Grid item xs={6} sm={4} md={3} lg={3} key={facility.id} className={classes.facilities}>

						<FacilitySwitch
							currentState={state}
							setCurrentState={setState}
							index={index}
							key={facility.id}
							label={facility.name}
							isSelected={facility.is_selected}
						/>
				</Grid>
			))}
		</Grid>
	)
}

function HostelImageDropZone(props) {

	const { state, setState , previousUploadedFiles } = props;
	const classes = useStyles();

	function handleImageChange(e) {
		setState({
			...state,
			images: e.target.files
		})
	}

	function removeHostelImage(event) {
		console.log("data = ", event);
		const imageId = event.target.id;

		console.log("id = ", imageId);
	}

	return (
		<div>
			
			{ previousUploadedFiles && previousUploadedFiles.length > 0 ? 
				<Grid container spacing={2} className={classes.previousImages}>
					<Grid item xs={12} className={classes.images}>
						Uploaded files
					</Grid>

					{previousUploadedFiles.map((file, index) => (
						<Grid item xs={6} md={4} key={index}>
							{file.name} 
							<DeleteOutlineIcon 
								className={classes.deleteButton}
								color="secondary"
								id={file.id}
								onClick={removeHostelImage}
							/>
						</Grid>
					))}

				</Grid> : '' 
			}
			<input 
				className={classes.fileInput}
				type="file"
				id="image"
				accept="image/png, image/jpeg"  
				onChange={handleImageChange} 
				required
				multiple	
			/>
		</div>
	)

}

function getStepContent(step, state, setState, cityOptions, genderChoices, sharingTypes, facilities, previousUploadedFiles) {
  switch (step) {
    case 0:
		return <HostelBasicDetailContainer 
				state={state} 
				setState={setState} 
				genderChoices={genderChoices}
			/>
    case 1:
		return <HostelAddressContainer 
					state={state} 
					cityOptions={cityOptions}
					setState={setState}
				/>
    case 2:
	  return <HostelCapacityContainer 
				state={state} 
				setState={setState}
			/>
	case 3:
		return <HostelAvailabilityContainer 
				state={state} 
				setState={setState}
			/>
	case 4:
		return <HostelFacilitiesContainer 
				state={state} 
				setState={setState} 
				facilities={facilities}
			/>
	case 5:
		return <HostelImageDropZone 
					state={state} 
					setState={setState}
					previousUploadedFiles={previousUploadedFiles}
				/>
    default:
      return <div> 
		  		Unknown Step
			</div>
  }
}

function VerticalLinearStepper(props) {

	const { history, hostelUUID } = props;
	const classes = useStyles();
	const [ error, setError] = useState(null);
	const [ hostel, setHostel] = useState(null);
	// const [ gotHostelDetail, setGotHostelDetail ] = useState(false);
	const [ isLoading, setIsLoading] = useState(true);
	const [ isSubmittingForm, setIsSubmittingForm] = useState(false);
	const [ previousUploadedFiles, setPreviousUploadedFiles] = useState([]);
	const [ cityOptions, setCityOptions ] = useState([]);
	const [ genderChoices, setGenderChoices ] = useState([]);
	const [ sharingTypes, setSharingTypes ] = useState([]);
	const isMounted = useRef(false);

	const [ message, setMessage] = useState(false);
	const [ isSuccess, setIsSuccess] = useState(false);
	const [ showNotification, setShowNotification] = useState(false);


	const [ state, setState ] = useState({

		// Hostel Detail
		name: "",
		description: "",
		minimumRent: "",
		maximumRent: "",
		availableFor: "",

		// Address
		firstLine: "",
		secondLine: "",
		city: "",
		state: "",
		country: "",
		pincode: "",

		// Capacity
		singleSharingCapacity: 0,
		doubleSharingCapacity: 0,
		tripleSharingCapacity: 0,

		// Rent Information
		singleSharingRent: 0,
		doubleSharingRent: 0,
		tripleSharingRent: 0,

		// Availability
		singleSharingAvailability: 0,
		doubleSharingAvailability: 0,
		tripleSharingAvailability: 0,

		// Facilities
		facilities: [],
		images: [],

		// AutoComplete Options
		cityValue: "",
		inputCityValue: "",

		genderValue: "",
		inputGenderValue: ""

	});

	// Get Required data for adding hostel

	useEffect(() => {

		isMounted.current = true;

		document.title = 'Hostlr - Add Update Hostel'

		let url = "";

		if(hostelUUID) {
			url = `${config.BASE_APP_URL}/hostels/?hostel_db_id=${hostelUUID}`;
		} else {
			url = `${config.BASE_APP_URL}/get_required_form_data/?required_data_for=hostel`;
		}

		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		if(isMounted.current) {

			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(result.response){
						setCityOptions(result.cities);
						setGenderChoices(result.genders);
						setSharingTypes(result.sharing_types);

						if(hostelUUID) {

							const selectedCityOption = result.cities.filter(
								option => option.uuid === result.hostel_detail.address.city.uuid
							);
		
							const selectedGenderOption = result.genders.filter(
								option => option.value === result.hostel_detail.available_for
							);

							setState({
								...state,
								cityValue: selectedCityOption[0],
								inputCityValue: selectedCityOption[0].name,

								genderValue: selectedGenderOption[0],
								inputGenderValue: selectedGenderOption[0].title,
								facilities: result.hostel_detail.facilities
							})
							setHostel(result.hostel_detail);
							// setGotHostelDetail(true);
						} else {
							setState({
								...state,
								facilities: result.facilities
							})
						}
	
					} else{
						setError(result.message);

						if(result.status_code === 401) {
							history.push("/login/");
						}
					}
					setIsLoading(false);
				},
				(error) => {
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			)
		}

		return () => isMounted.current = false;
		// eslint-disable-next-line
	}, []);

	useEffect(() => {

		isMounted.current = true;

		if(isMounted.current) {

			setState({
				...state,
	
				// Hostel Detail
				name: hostel ? hostel.name : "",
				description: hostel ? hostel.description : "",
				minimumRent: hostel ? hostel.minimum_rent : 0,
				maximumRent: hostel ? hostel.maximum_rent : 0,
				availableFor: hostel ? hostel.available_for : "M",
		
				// Address
				firstLine: hostel ? hostel.address.first_line : "",
				secondLine: hostel ? hostel.address.second_line : "",
				city: hostel ? hostel.address.city.name : "",
				state: hostel ? hostel.address.state : "",
				country: "",
				pincode: hostel ? hostel.address.pin_code : "",
		
				// Capacity
				singleSharingCapacity: hostel ? hostel.single_sharing : 0,
				doubleSharingCapacity: hostel ? hostel.double_sharing : 0,
				tripleSharingCapacity: hostel ? hostel.triple_sharing : 0,

				// Rent Information
				singleSharingRent: hostel ? hostel.single_sharing_rent : 0,
				doubleSharingRent: hostel ? hostel.double_sharing_rent : 0,
				tripleSharingRent: hostel ? hostel.triple_sharing_rent : 0,
		
				// Availability
				singleSharingAvailability: hostel ? hostel.single_sharing_availability : 0,
				doubleSharingAvailability: hostel ? hostel.double_sharing_availability : 0,
				tripleSharingAvailability: hostel ? hostel.triple_sharing_availability : 0,
		
				// Facilities
				// facilities: hostel ? hostel.facilities : [],
				images: hostel ? hostel.images : []
			});
	
			setPreviousUploadedFiles(hostel ? hostel.images : []);
		}

		return () => isMounted.current = false;

		// eslint-disable-next-line
	}, [hostel]);


	function addUpdateHostel(event) {
		event.preventDefault();
		setIsSubmittingForm(true);
		setIsLoading(true);

		let form_data = new FormData();

		form_data.append("name", state.name)
		form_data.append("description", state.description )
		form_data.append("minimumRent", state.minimumRent )
		form_data.append("maximumRent", state.maximumRent)
		form_data.append("availableFor", state.availableFor)

		form_data.append("firstLine", state.firstLine)
		form_data.append("secondLine", state.secondLine)
		form_data.append("city", state.cityValue.uuid)
		form_data.append("state", state.state)
		form_data.append("country", state.country)
		form_data.append("pincode", state.pincode)

		form_data.append("singleSharingCapacity", state.singleSharingCapacity)
		form_data.append("doubleSharingCapacity", state.doubleSharingCapacity)
		form_data.append("tripleSharingCapacity", state.tripleSharingCapacity)

		form_data.append("singleSharingRent", state.singleSharingRent)
		form_data.append("doubleSharingRent", state.doubleSharingRent)
		form_data.append("tripleSharingRent", state.tripleSharingRent)

		form_data.append("singleSharingAvailability", state.singleSharingAvailability)
		form_data.append("doubleSharingAvailability", state.doubleSharingAvailability)
		form_data.append("tripleSharingAvailability", state.tripleSharingAvailability)

		for (var i = 0; i < state.images.length; i++) {
			form_data.append(`file${i}`, state.images[i]);
		}

		// Only selected facilities will be used
		const selctedFacilities = state.facilities.filter(item => item.is_selected === true);
		form_data.append("facilities", JSON.stringify(selctedFacilities));

		let url = ""

		if(hostelUUID) {
			form_data.append("hostel_db_id", hostel.id);
			url = `${config.BASE_APP_URL}/hostels/`;
		} else {
			url = `${config.BASE_APP_URL}/hostels/`;
		}

		const options = {
			method:'post',
			body: form_data
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setShowNotification(true);
					setMessage('Hostel Detail added successfully');
					history.push(`/hostel/${result.hostel_detail.uuid}/`)

					return () => isMounted.current = false;

				} else{
					setIsSuccess(false);
					setShowNotification(true);
					setMessage(result.message);
					setError(result.message);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsSuccess(false);
				setIsLoading(false);
				setShowNotification(true);
				setMessage('Something went wrong');
				setError('Something went wrong!');
			}
		)
	}

	// Steps for Adding Hostel
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();

	function validateHostelBasicDetail() {
		if(state.name === "" || state.description === "" || state.availableFor === "" || state.minimumRent === "" || state.maximumRent === "" || state.minimumRentError || state.maximumRentError ) {
			setState({
				...state,
				nameError: state.name === "" ? true : false,
				descriptionError: state.description === "" ? true : false,
				availableForError: state.availableFor === "" ? true : false,
				minimumRentError: state.minimumRent === "" || state.minimumRentError ? true : false,
				maximumRentError: state.maximumRent === "" || state.maximumRentError ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateHostelAddresss() {
		if(state.firstLine === "" || state.secondLine === "" || state.city === "" || state.state === "" || state.pincode === "" || state.pincodeError ) {
			setState({
				...state,
				firstLineError: state.firstLine === "" ? true : false,
				secondLineError: state.secondLine === "" ? true : false,
				cityError: state.city === "" ? true : false,
				stateError: state.state === "" ? true : false,
				pincodeError: state.pincode === "" || state.pincodeError ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateHostelSharingCapacityANDRent() {
		if(state.singleSharingCapacity === "" || state.doubleSharingCapacity === "" || state.tripleSharingCapacity === "" ||
		state.singleSharingCapacityError || state.doubleSharingCapacityError || state.tripleSharingCapacityError ||
		state.singleSharingRent === "" || state.doubleSharingRent === "" || state.tripleSharingRent === "" ||
		state.singleSharingRentError || state.doubleSharingRentError || state.tripleSharingRentError
		) {
			setState({
				...state,
				singleSharingCapacityError: state.singleSharingCapacity === "" || state.singleSharingCapacityError ? true : false,
				doubleSharingCapacityError: state.doubleSharingCapacity === "" || state.doubleSharingCapacityError ? true : false,
				tripleSharingCapacityError: state.tripleSharingCapacity === "" || state.tripleSharingCapacityError ? true : false,
				singleSharingRentError: state.singleSharingRent === "" || state.singleSharingRentError ? true : false,
				doubleSharingRentError: state.doubleSharingRent === "" || state.doubleSharingRentError ? true : false,
				tripleSharingRentError: state.tripleSharingRent === "" || state.tripleSharingRentError ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateHostelSharingAvailability() {
		if(state.singleSharingAvailability === "" || state.doubleSharingAvailability === "" || state.tripleSharingAvailability === "" || state.singleSharingAvailabilityError || state.doubleSharingAvailabilityError || state.tripleSharingAvailabilityError) {
			setState({
				...state,
				singleSharingAvailabilityError: state.singleSharingAvailability === "" || state.singleSharingAvailabilityError ? true : false,
				doubleSharingAvailabilityError: state.doubleSharingAvailability === "" || state.doubleSharingAvailabilityError ? true : false,
				tripleSharingAvailabilityError: state.tripleSharingAvailability === "" || state.tripleSharingAvailabilityError ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	const handleNext = () => {

		if(activeStep === 0 && validateHostelBasicDetail()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 1 && validateHostelAddresss()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 2 && validateHostelSharingCapacityANDRent()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 3 && validateHostelSharingAvailability()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 4) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 5) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	return (
		<div className={classes.root}>
			{ isLoading && !isSubmittingForm ? "" : 
				<form noValidate autoComplete="off" onSubmit={addUpdateHostel}>
					<Stepper activeStep={activeStep} orientation="vertical">

						{steps.map((label, index) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
								<StepContent>
									<div>
										{getStepContent(index, state, setState, cityOptions, genderChoices, sharingTypes, state.facilities, previousUploadedFiles)}
									</div>
									<div className={classes.actionsContainer}>
										<Grid container spacing={1}>
											<Grid item xs={6} sm={3} ms={2}>
												<Button
													fullWidth
													variant="contained"
													disabled={activeStep === 0}
													onClick={handleBack}
													className={classes.button}
													color="secondary"
												>
													Back
												</Button>
											</Grid>

											<Grid item xs={6} sm={3} ms={2}>
												<Button
													fullWidth
													variant="contained"
													color="primary"
													onClick={handleNext}
													className={classes.button}
												>
													{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
												</Button>
											</Grid>
										</Grid>
									</div>
								</StepContent>
							</Step>
						))}

					</Stepper>

					{activeStep === steps.length && (
						<Paper square elevation={0} className={classes.resetContainer}>
						{/* <Typography>All steps completed - you&apos;re finished</Typography> */}

						<Grid container spacing={1}>
							<Grid item xs={6} sm={4} md={2}>
								<Button
									variant="contained"
									disabled={activeStep === 0}
									onClick={handleBack}
									// className={classes.button}
									color="secondary"
									fullWidth
								>
									Back
								</Button>
							</Grid>

							<Grid item xs={6} sm={4} md={2}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									// className={classes.submit}
									disabled={true ? isLoading: false}
								>
									Save
								</Button>
							</Grid>
						</Grid>

						</Paper>
					)}
				</form>
			}

			{ isLoading? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>
			}

			{ error ? 
				<Grid container spacing={1} 
					style={{
						color: "red",
						paddingLeft: "20px",
						display: "none"
					}}
				>
					{error}
				</Grid> : ""
			}
		</div>
	);
}


function AddUpdateHostelPage(props) {

	// const classes = useStyles();
	const { history } = props;
	const hostelUUID = props.match.params.uuid;

	return (
		<div>
			<Header/>
			<VerticalLinearStepper 
				history={history} 
				hostelUUID={hostelUUID}
			/>
			<Footer/>
		</div>
	);
}

export default AddUpdateHostelPage;