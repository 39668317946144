import React, { useEffect } from "react";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
		zIndex: theme.zIndex.drawer + 1
	},

	paper: {
		margin: theme.spacing(12, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		overflow: "hidden"
    },

	ErrorContainerHeading: {
		textAlign: "center",
		opacity: 0.4,
		fontSize: 60,
		position: "relative",
        // fontFamily: "Snell Roundhand, cursive",
		fontFamily: "cursive",
		
	},

}));


function Error403(props) {

	const { history } = props;
	const classes = useStyles();

	useEffect(() => {
		document.title = 'Error - 403'

	}, []);

	function handleClick(event) {
		history.goBack();
	}

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />

			<Grid item xs={12} component={Paper} elevation={6} square>

				<div className={classes.paper}>

					<div className={classes.ErrorContainerHeading}>
						OOPS! <br></br>
						ERROR 403 <br></br>
						You don't have permission to access this page!.
					</div>

					<Grid item xs={12}>
						<Grid item xs={false} md={4}></Grid>
						<Grid item xs={12} md={4}>
							<Button
								style={{ width: "300px" }}
								variant="contained"
								size="large"
								color="secondary"
								onClick={handleClick}
							>
								GO BACK
							</Button>

						</Grid>
						<Grid item xs={false} md={4}></Grid>

					</Grid>
					
				</div>
			</Grid>
		</Grid>
	);
}

export default Error403;