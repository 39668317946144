import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Banner from "../../components/BannerCarousal/BannerCarousal";
import UploadedItems from "../../assets/UploadedItems.json";

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden"
	}
	
}));

function LandingPage(props) {

	const classes = useStyles();
	const { history } = props;

	// useEffect(() => {
	// 	// Update the document title using the browser API
	// 	document.title = `Hostlr - Landing`;
	// });

	return (
		<div className={classes.root}>
			<Header/>
			<Banner
				history={history}
				UploadedItems={UploadedItems}
			/>
			<Footer/>
		</div>
	);
}

export default LandingPage;