import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import HomeIcon from '@material-ui/icons/Home';
import WcIcon from '@material-ui/icons/Wc';
import PersonIcon from '@material-ui/icons/Person';
import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import MapIcon from '@material-ui/icons/Map';

import ImageCarousel from "../../components/BannerCarousal/ImageCarousel";

// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Redux'
import { useSelector } from "react-redux";
// import { useSelector, useDispatch } from "react-redux";
// import { setHostels, setCurrentCity } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';
import ToastNotification from '../../components/CommonFunctions/ToastNotification';

// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
  } from '@material-ui/pickers';

import {
	Typography,
	Grid
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh"
	},

	page: {
		minHeight: "100vh",
		paddingBottom: "10%",
		background: "#f0f8ff",
		overflow: "hidden"
	},

	header: {
		marginLeft: "2% !important"
	},

	hostels: {
		marginTop: "2%"
	},

	cityName: {
		color: "#ff0000"
	},

	hostel: {
		width: "98%",
		marginLeft: "1%",
		flexGrow: 1,
		background: "white",
		marginBottom: "50px"
	},

	hostelCard: {
		color: "black",
		textDecoration: "none"
	},

	hostelInfo : {
		marginLeft: 20
	},

	hostelRelatedInfo: {
		paddingTop: "55px"
	},
	
	// Card Medias
	card: {
		maxWidth: 300,
		marginBottom: 10
	},

	media: {
		marginLeft: "25%",
		top: "15px",
		height: 150,
		width: "50%",
		borderRadius: "50%",
		position: "relative"
	},

	hostelMedia: {
		height: 250,
		position: "relative"
	},

	iconWithText: {
		paddingTop: "2px",
		paddingRight: "10px"
	},

	rent: {
		marginLeft: "15px"
	},

	perMonthText: {
		padding: "12%",
		fontSize: "12px",
		color: "#3f51b5",
		fontWeight: 800
	},

	rentInformation: {
		marginTop: "150px",
	},

	addHostelButton: {
		width: "180px",
		padding: "10px",
		borderRadius: 30,
		margin: theme.spacing(1)
	},

	addButtonGrid: {
		display: "flex",
		justifyContent: "flex-end"
	},

	addNewHostelLink: {
		color: "white",
		textDecoration: "none"
	},

	applyButton: {
		width: "150px",
		padding: "10px",
		borderRadius: 30,
		margin: theme.spacing(1)
	},

	alreadyApplyButton: {
		width: "150px",
		padding: "10px",
		borderRadius: 30,
		background: "black !important",
		margin: theme.spacing(1)
	},

	// Modal
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		minWidth: "300px",
		minHeight: "100px",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

	calender: {
		width: "100%"
	},

	noHostelFound: {
		marginLeft: "1%",
		width: "98%"
	},

	alertMessage: {
		width: "96%",
		marginTop: "30px",
		marginLeft: "1%"
	},
	
}));


function ConditionalHostelType(props) {

	const { available_for } = props;

	if(available_for === "M" ) {
		return <PersonIcon color="primary"/>
	} else if(available_for === "F") {
		return <PersonIcon color="primary"/>
	} else {
		return <WcIcon color="primary"/>
	}
}


function ConditionalHostelAvailabilityFor(props) {

	const { available_for } = props;

	if(available_for === "M" ) {
		return "Available For Males Only";
	} else if(available_for === "F") {
		return "Available For Females Only";
	} else {
		return "Available For Males and Females";
	}
}


function RentInformation(props) {

	const { item, classes } = props;

	if(item.minimum_rent === item.maximum_rent) {
		return <div className={classes.rentInformation}> 
			&#x20B9; {item.minimum_rent}
		</div>
		
	} else {
		return <div className={classes.rentInformation}> 
			&#x20B9; {item.minimum_rent} - &#x20B9; {item.maximum_rent}
		</div>
	}
}


function formatDate(date) {

	return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))  + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +  '/' + date.getFullYear();
}


function MaterialUIPickers(props) {
	// The first commit of Material-UI
	const classes = useStyles();
	const {selectedDate, setSelectedDate, state, setState} = props;

	const handleDateChange = (date) => {
		setSelectedDate(date);
		setState({
			...state,
			joiningDate: selectedDate,
		})
	};
  
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} className={classes.calender}>
			<KeyboardDatePicker
				margin="normal"
				id="date-picker-dialog"
				label="Start Date"
				format="dd/MM/yyyy"
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}


function ApplyToHostelModal(props) {

	const classes = useStyles();
	const { open, setOpen, sharingTypes, hostel, history, setIsRecentApplied } = props;
	const [ showNotification, setShowNotification ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ message, setMessage ] = useState(null);
	const [ isLoading, setIsLoading] = useState(false);
	// const [ error, setError] = useState(null);

	const [selectedDate, setSelectedDate] = useState(new Date());
	const userDetail = useSelector(state => state.user.currentUser);

	const [ state, setState ] = useState({
		sharingTypeValue:"",
		inputSharingTypeValue:"",
		joiningDate: new Date()
	});

	const [ sharingTypeValue, setSharingTypeValue] = useState(state.sharingTypeValue);
	const [ inputSharingTypeValue, setInputSharingTypeValue] = useState(state.inputSharingTypeValue);

	const handleClose = () => {
		setOpen(false);
	};

	function handleLogin() {
		history.push('/login/');
	}

	function joinHostel(event) {
		setIsLoading(true);
		event.preventDefault();
		const url = `${config.BASE_APP_URL}/tenants/joining-request/`;

		const hostelJoiningInfo = {
			"hostel": hostel.uuid,
			"sharingType": state.sharingType,
			"joiningDate": formatDate(state.joiningDate)
		};

		const options = {
			method:'POST',
			body:JSON.stringify(hostelJoiningInfo),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					
					setTimeout(() => {
						setIsLoading(false);
						setOpen(false);
						setShowNotification(false);
						setIsRecentApplied(true);
					}, 1000);

				} else{
					// setError(result.message);
					setIsLoading(false);
					setIsSuccess(false);
					setMessage(result.message);
				}

				setShowNotification(true);
			},
			(error) => {
				setIsLoading(false);
				setIsSuccess(false);
				setMessage('Something went wrong!');
				setShowNotification(true);
				// setError('Something went wrong!');
			}
		)
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				{ userDetail ? 
					<div className={classes.paper}>
						<Typography variant="body1"> 
							Select Your choice for sharing type.
						</Typography>
						<br></br>
						<form noValidate autoComplete="off" onSubmit={joinHostel}> 
							<Grid item xs={12}>
								<Autocomplete
									id="sharingType"
									options={sharingTypes}
									getOptionSelected={(option, value) => option.title === value.title}
									value={state.sharingTypeValue ? state.sharingTypeValue : sharingTypeValue}
									getOptionLabel={(option) => option ? option.title : ""}
									fullWidth
									onChange={(event, newValue) => {
										setSharingTypeValue(newValue);
										setState({
											...state,
											sharingTypeValue: newValue,
											sharingType: newValue ? newValue.value : ""
										})
									}}
									inputValue={state.inputSharingTypeValue ? state.inputSharingTypeValue : inputSharingTypeValue}
									onInputChange={(event, newInputValue) => {
										setInputSharingTypeValue(newInputValue);
										setState({
											...state,
											inputSharingTypeValue: newInputValue,
										})
									}}
									renderInput={
										(params) => <TextField {...params} label="Sharing Type" variant="outlined" margin="dense"/>
									}
								/>
							</Grid>

							<br></br>

							<Grid item xs={12}>
								<MaterialUIPickers
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
									state={state}
									setState={setState}
									classes={classes.calender}
								/>
							</Grid>
							
							<br></br>

							<Grid item xs={12}>
								<Button
										type="submit"
										fullWidth
										variant="contained"
										color="primary"
										className={classes.submit}
									>
										APPLY
								</Button>
							</Grid>
						</form>
						{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false} /> }

						{ showNotification ? 
							<ToastNotification 
								open={true} 
								setOpen={setShowNotification}
								message={message} 
								isSuccess={isSuccess}/> : 

							<ToastNotification 
								open={false} 
								setOpen={setShowNotification}
								message={message} 
								isSuccess={isSuccess}
							/>
						
						}
					</div> : 
					<div className={classes.paper}>
						<Typography variant="body1"> 
							You need to login before applying to join a hostel.
						</Typography>
						<br></br>

						<Grid container justify="center" spacing={0}>
							<Grid item xs={6}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className={classes.applyButton}
									onClick={handleLogin}
								>
									LOGIN
								</Button>

							</Grid>

							<Grid item xs={6}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className={classes.alreadyApplyButton}
									onClick={handleClose}
								>
									CANCEL
								</Button>

							</Grid>
						</Grid>

					</div>	
				}
			</Fade>


      </Modal>
	)
}

// Hostel cards
function HostelMediaCard(props) {

	const { item, index, isHostelOwner, classes, isApplyButtonVisible, history } = props;

	const [ open, setOpen ] = useState(false);
	const [ isRecentApplied, setIsRecentApplied ] = useState(false);
	const userDetail = useSelector(state => state.user.currentUser);

	function requestToJoinHostel() {
		setOpen(true);
	}

	return (
		<Grid container className={classes.hostel} spacing={5}>
			<Grid item xs={12}>
				<Grid container justify="center" spacing={0}>
					<Grid item xs={12} md={4} lg={4}>
						<ImageCarousel
							images={item.images}
						/>
					</Grid>

					<Grid item xs={6} md={4} lg={4} key={index}>
						<Link to={`/hostel/${item.uuid}/`} key={index} className={classes.hostelCard}>
							<div className={classes.hostelInfo}>
								<Typography variant="h4" component="h4">
									{item.name}
								</Typography>

								<Rating name="read-only" value={item.rating} readOnly />

								<div className={classes.hostelRelatedInfo}></div>
								<Grid container direction="row" alignItems="center">
									<Grid item className={classes.iconWithText}>
										<LocationOnIcon color="primary"/>
									</Grid>
									<Grid item>
										{item.address.first_line}, {item.address.second_line} <br></br>
										{item.address.city.name}, {item.address.country.name}
									</Grid>
								</Grid>

								<br></br>
								<Grid container direction="row" alignItems="center">
									<Grid item className={classes.iconWithText}>
										<HomeIcon color="primary"/>
									</Grid>
									<Grid item>
										{item.room_available} Rooms Available
									</Grid>
								</Grid>

								<br></br>

								<Grid container direction="row" alignItems="center">
									<Grid item className={classes.iconWithText}>
										<ConditionalHostelType available_for={item.available_for}/>
									</Grid>
									<Grid item>
										<ConditionalHostelAvailabilityFor available_for={item.available_for}/>
									</Grid>
								</Grid>

							</div>
						</Link>    
					</Grid>

					<Grid item xs={6} md={4} lg={4}>
						<div className={classes.rent}>
							<RentInformation item={item} classes={classes}/>
							<span className={classes.perMonthText} > Per Month </span>
						</div>
						{ isHostelOwner ? "" : 
							(userDetail && !isApplyButtonVisible) || isRecentApplied ?

							<Button 
								size="large" 
								variant="contained" 
								color="primary" 
								className={classes.alreadyApplyButton}
							>
								APPLIED
							</Button> : 
							<Button 
								size="large" 
								variant="contained" 
								color="secondary" 
								className={classes.applyButton}
								onClick={requestToJoinHostel}
							>
								APPLY
							</Button>
						}

					</Grid>
				</Grid>
			</Grid>

		
			<ApplyToHostelModal
				open={open}
				setOpen={setOpen}
				hostel={item}
				history={history}
				setIsRecentApplied={setIsRecentApplied}
				sharingTypes={ item && item.sharing_types ? item.sharing_types : []}
			/>

		</Grid>
	);
}


function Hostelcards(props) {

	const classes = useStyles();
	const { HostelsList , isHostelOwner, tenantRequests, history } = props;

	function showApplyButton(item) {
		const isApplyButtonVisible = tenantRequests.filter(
			tenantRequest => tenantRequest.hostel.uuid === item.uuid
		);

		if(isHostelOwner) {
			return false;
		}else if(isApplyButtonVisible && isApplyButtonVisible.length > 0) {
			return false;
		} else {
			return true;
		}
	}

	return (
		HostelsList.map((item, index) => {

			return (
				<HostelMediaCard 
					item={item} 
					key={index} 
					classes={classes}
					isHostelOwner={isHostelOwner}
					history={history}
					isApplyButtonVisible={showApplyButton(item)}
				/>
			)
		})
	)
}


function HostelListing(props) {

	const classes = useStyles();
	const { cityUUID, hostelOwnerUUid, history } = props;
	const [error, setError ] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [ gotHostelList, setGotHostelList] = useState(false);
	const [HostelsList, setHostelsList ] = useState([]);
	const [ isHostelOwner, setIsHostelOwner ] = useState(false);
	const [city, setCity ] = useState(null);
	const [ tenantRequests, setTenantRequests ] = useState([]);
	const [ userProfile, setUserProfile ] = useState(null);

	const [isMapVisible, setIsMapVisbile ] = useState(false);
	const userDetail = useSelector(state => state.user.currentUser);

	const _isMounted = useRef(true);

	// const hostels = useSelector(state => state.hostel.hostels);
	// const dispatch = useDispatch();

	// setState({
	//     ...state,
	//     hostel: {}
	// })

	// const [state, setState] = useState({
	//     hostel: {},
	//     currentUser: state.currentUser
	// });
	
	useEffect(() => {
		// Update Header Title
		document.title = `Hostlr - Hostels`;

		let url = "";

		if(cityUUID) {
			url = `${config.BASE_APP_URL}/city/hostels/?uuid=${cityUUID}`;
		} else {
			url = `${config.BASE_APP_URL}/hostels/?hostel_owner_uuid=${hostelOwnerUUid}`;
		}
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					if (_isMounted.current) {

						if(result.city) {
							setCity(result.city);
						}
						setHostelsList(result.hostels);
						setIsLoading(false);
						setUserProfile(result.user_profile);
					}

				} else{
					if (_isMounted.current) {
						setError(result.message);
						setIsLoading(false);
					}
				}
			},
			(error) => {
				if (_isMounted.current) {
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)
		
		// ComponentWillUnmount in Class Component
		return () => { _isMounted.current = false }

	}, [cityUUID, hostelOwnerUUid]); // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {

		_isMounted.current = true;
		
		if(userDetail) {

			if(userDetail && userDetail.is_hostel_owner) {
				setIsHostelOwner(true);
			}

			const url = `${config.BASE_APP_URL}/tenants/joining-request/?user_uuid=${userDetail.uuid}`;
			const options = {
				method:'get',
				headers: {
					"Content-Type": "application/json"
				}
			}

			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(result.response){
						if (_isMounted.current) {
							setTenantRequests(result.tenant_requests);
							setGotHostelList(true);
						}

					} else{
						if (_isMounted.current) {
							setError(result.message);
							setGotHostelList(true);
						}
					}
				},
				(error) => {
					if (_isMounted.current) {
						setGotHostelList(true);
						setError('Something went wrong!');
					}
				}
			)
		} else {
			setGotHostelList(true);
		}
		// ComponentWillUnmount in Class Component
		return () => { _isMounted.current = false }

	}, [userDetail]);


	function toggleMap(event) {
		setIsMapVisbile(!isMapVisible)
	}

	return (
		<div className={classes.page}>

			<br></br>

			{ isLoading && !gotHostelList ? 
				<Grid
					container
					alignContent="center"
				> 
					Fetching Hostels ... 
				</Grid> : 
				
				<Grid container direction="row" alignItems="center">
					<Grid item xs={6} md={8}>
						{ hostelOwnerUUid ? 
							<Typography variant="h4" className={classes.header}>
								Added Hostels
							</Typography>
							:
							<Typography variant="h4" className={classes.header}>
								Hostels In <span className={classes.cityName}> { city ? city.name : "City" } </span>
							</Typography>
						}
					</Grid>
					<Grid item xs={6} md={4} className={classes.addButtonGrid}>
						{ hostelOwnerUUid ? 
							<Link to="/add-hostel/" className={classes.addNewHostelLink}>
								<Button 
									size="medium" 
									variant="contained" 
									color="secondary" 
									className={classes.addHostelButton}
									startIcon={<AddIcon />}
								>
									ADD HOSTEL
								</Button>

							</Link> : HostelsList && HostelsList.length > 0 ? 
								<Button 
									size="medium" 
									variant="contained" 
									color="primary" 
									className={classes.addHostelButton}
									startIcon={<MapIcon />}
									onClick={toggleMap}
								>
									{ isMapVisible ? "Hide Map" : "Show Map" }
								</Button>
							
							: "" 
						}
					</Grid>
				</Grid>

			}

			{ userProfile && !userProfile.address && userProfile.is_tenant ? 
				<Alert severity="error" className={classes.alertMessage}>
					You are not allowed to book a hostel, since you don't have address added in your profile. 
					Address is mandatory for booking a hostel.
				</Alert> : ""
			}

			<Grid container spacing={5} className={classes.hostels}>

				{ HostelsList && HostelsList.length > 0 ? 
					<Grid item xs={ isMapVisible ? 12 : 12 } md={ isMapVisible ? 8 : 12 }>
						<Hostelcards 
							history={history}
							HostelsList={HostelsList}
							isMapVisible={isMapVisible}
							isHostelOwner={isHostelOwner}
							tenantRequests={tenantRequests}
						/>
					</Grid> : ""
				}

				{ isHostelOwner && !isLoading && gotHostelList && HostelsList && HostelsList.length <= 0 ? 
					<Grid item xs={12} className={classes.noHostelFound}>
						<Typography variant="h5">
							There are no hostel added by you.
						</Typography>
					</Grid>
					
					: ""

				}

				{ cityUUID && !isLoading && gotHostelList && HostelsList && HostelsList.length <= 0 && !error ? 
					<Grid item xs={12} className={classes.noHostelFound}>
						<Typography variant="h5">
							We are not operating In 
							<span className={classes.cityName}> { city ? city.name : "City" } </span> right now,
							will notify you once we start operating. 
						</Typography>
					</Grid>
					
					: ""

				}


				{ isMapVisible ? 
					<Grid item xs={4}>
						Map is visible
					</Grid> : false
				}

			</Grid>

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false} /> }

			{ error ? 
				<Grid container spacing={0}> {error} </Grid>
			: ""}
		</div>
	)
}

function HostelListingPage(props) {

	const classes = useStyles();
	const { history } = props;

	const cityUUID = props.match.path.includes('city') ? props.match.params.uuid : null;
	const hostelOwnerUUid = !props.match.path.includes('city') ? props.match.params.uuid : null;

	return (
		<div className={classes.root}>
			<Header/>
			<HostelListing 
				history={history} 
				cityUUID={cityUUID} 
				hostelOwnerUUid={hostelOwnerUUid} 
			/>
			<Footer/>
		</div>
	);
}

export default HostelListingPage;