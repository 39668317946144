import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
import { useSelector } from "react-redux";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';
import ToastNotification from "../../components/CommonFunctions/ToastNotification";

import { Link } from "react-router-dom";

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

// Modal
// import Modal from '@material-ui/core/Modal';
// import Backdrop from '@material-ui/core/Backdrop';
// import Fade from '@material-ui/core/Fade';

import {
	Grid,
	Button,
	Typography,
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh"
	},

	page: {
		minHeight: "80vh",
		paddingBottom: "10%",
		background: "#f0f8ff"
	},

	pageHeader: {
		marginTop: "80px",
		marginLeft: "2%"
	},

	alertMessage: {
		width: "96%",
		marginTop: "30px",
		marginLeft: "1%"
	},
	
	pageSection: {
		width: "98%",
		marginTop: "30px",
		marginLeft: "1%",
		background: "white",
		paddingBottom: "10px",
		paddingTop: "10px",
	},

	confirmButtonSection: {
		width: "98%",
		marginTop: "30px",
		marginLeft: "1%",
		paddingBottom: "10px",
		paddingTop: "10px",
	},

	sectionHeading: {
		color: "red",
		fontWeight: 300
	},

	detailType: {
		fontWeight: 600,
		marginBottom: "5px"
	},

	detailValue: {
		fontWeight: 300
	},
	
	// Modal
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	paper: {
		minWidth: "300px",
		minHeight: "100px",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

}));


function HostelBooking(props) {

	const { classes, tenantRequestID, history } = props;
	const [isLoading, setIsLoading] = useState(true);

	const [isSuccess, setIsSuccess] = useState(false);
	const [message, setMessage] = useState(null);
	const [showNotification, setShowNotification] = useState(false);
	const [error, setError] = useState(null);
	const[tenantRequest, setTenantRequest] = useState(null);
	const[confirmWithoutAddingAddressError, setConfirmWithoutAddingAddressError] = useState(null);
	
	const _isMounted = useRef(true);

	const userDetail = useSelector(state => state.user.currentUser);

	useEffect(() => {

		setIsLoading(true);

		// Scroll window to top on landing for the first time
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		document.title = 'Hostlr - Booking'

		const url = `${config.BASE_APP_URL}/tenants/joining-request/?tenant_request_id=${tenantRequestID}`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(_isMounted.current) {
					if(result.response){
						setTenantRequest(result.tenant_requests[0]);
	
					} else{
						setIsSuccess(false);
						setMessage(result.message);
						setError(result.message);

					}
				}
				setIsLoading(false);
			},
			(error) => {
				if(_isMounted.current) {
					setIsSuccess(false);
					setMessage(error);
					setShowNotification(true);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)
		return () => { _isMounted.current = false }

	}, [tenantRequestID]); // eslint-disable-line react-hooks/exhaustive-deps


	function confirmHostelBooking(event) {

		if(!tenantRequest.user_profile.address) {
			setConfirmWithoutAddingAddressError('Add address before confirming this booking')
			return false
		}

		setIsLoading(true);

		const data = {
			action: "accept",
			tenant_request_uuid: tenantRequestID
		}

		const url = `${config.BASE_APP_URL}/tenants/joining-request/`;
		const options = {
			method:'put',
			body:JSON.stringify(data),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsLoading(false);
					setIsSuccess(true);
					setShowNotification(true);
					setMessage('Hostel Booked successfully');

					setTimeout(() => {
						history.push(`/user-profile/${tenantRequest.user_profile.uuid}`);
					}, 150);

				} else{
					setIsLoading(false);
					setMessage(result.message);
					setIsSuccess(false);
					setShowNotification(true);
				}
			},
			(error) => {
				setIsLoading(false);
				setIsSuccess(false);
				setMessage('Something went wrong!');
				setShowNotification(true);
			}
		)
	}


	return (
		<div className={classes.page}>

			<Grid container spacing={5} className={classes.pageHeader}>
				<Typography variant="h6">
					Hostel Request Detail
				</Typography>
			</Grid>

			{ tenantRequest && !tenantRequest.user_profile.address ? 
				<Alert severity="error" className={classes.alertMessage}>
					You won't be able to confirm this request, 
					since you don't have address added in your profile.
					Add address to book this hostel.
				</Alert> : ""
			}

			{ tenantRequest ?
				<Grid container spacing={5} className={classes.pageSection}>

					<Grid item xs={12}>
						<div className={classes.sectionHeading}>
							HOSTEL DETAIL
						</div> 
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}> Hostel </div> 
						<div className={classes.detailValue}>
							{tenantRequest.hostel ? tenantRequest.hostel.name : ""}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}> Hostel Address </div> 
						<div className={classes.detailValue}>
							{tenantRequest.hostel.address.first_line}, {tenantRequest.hostel.address.second_line},
							{tenantRequest.hostel.address.city.name} {tenantRequest.hostel.address.country.name}, {tenantRequest.hostel.address.pin_code}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Sharing Type </div> 
						<div className={classes.detailValue}>
							{tenantRequest.sharing_type}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Rent Information</div>
						{tenantRequest.rent_per_month ?
							<div className={classes.detailValue}>
								&#x20B9; {tenantRequest.rent_per_month}
								<span className="perMonthText">
									per Month
								</span>
							</div> : "Not Provided"
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Joining Date</div> 
						<div className={classes.detailValue}>
							{tenantRequest.joining_date}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Requested On</div> 
						<div className={classes.detailValue}>
							{tenantRequest.created_on}
						</div>
					</Grid>
					
				</Grid> : ""
			}

			{ tenantRequest && tenantRequest.user_profile ?
				<Grid container spacing={5} className={classes.pageSection}>

					<Grid item xs={12}>
						<div className={classes.sectionHeading}>
							YOUR DETAIL
						</div> 
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}> Name </div> 
						<div className={classes.detailValue}>
							{tenantRequest.user_profile.user.name}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Address </div> 
						{tenantRequest.user_profile.address ?
							<div className={classes.detailValue}>
								{tenantRequest.user_profile.address.first_line}, {tenantRequest.user_profile.address.second_line},
								{tenantRequest.user_profile.address.city.name} {tenantRequest.user_profile.address.country.name}, {tenantRequest.user_profile.address.pin_code}
							</div> :
							<Link
								to={`/user-profile/${tenantRequest.user_profile.uuid}`}
								className="noDecoration"
							>
								ADD ADDRESS
							</Link>
						}
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Email Address</div> 
						<div className={classes.detailValue}>
							{tenantRequest.user_profile.user.email}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Mobile Number</div> 
						<div className={classes.detailValue}>
							{tenantRequest.user_profile.mobile_number ? tenantRequest.user_profile.mobile_number : "Not Provided"}
						</div>
					</Grid>

					<Grid item xs={12} sm={6} md={4}>
						<div className={classes.detailType}>Gender</div> 
						<div className={classes.detailValue}>
							{tenantRequest.user_profile.gender_title}
						</div>
					</Grid>
					
				</Grid> : ""
			}

			{confirmWithoutAddingAddressError ?
				<Grid container spacing={5} justify="flex-end">
					<Grid item xs={12} sm={6} md={6}>
						<Alert severity="error" className={classes.alertMessage}>
							{confirmWithoutAddingAddressError}
						</Alert>
					</Grid>
				</Grid> : ""
			}

			{ tenantRequest && userDetail && tenantRequest.user_profile.uuid === userDetail.uuid ?
				<Grid container spacing={5} className={classes.confirmButtonSection} justify="flex-end">
					<Grid item xs={12} sm={4} md={3}>
						{ tenantRequest.has_joined_hostel ?
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								className="cancelButton"
								>
								ALREADY JOINED
							</Button> :
							
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								className="acceptButton"
								disabled={tenantRequest && !tenantRequest.user_profile.address}
								onClick={confirmHostelBooking}
							>
								CONFIRM
							</Button>
						}
					</Grid>
				</Grid> : ""
			}

			{ isLoading? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>
			}
			
		</div>
	)
}

function HostelBookingPage(props) {

	const classes = useStyles();
	const { history } = props;
	
	const tenantRequestID = props.match.params.tenantRequestID;

	return (
		<div className={classes.root}>
			<Header/>
			<HostelBooking 
				history={history} 
				classes={classes}
				tenantRequestID={tenantRequestID}
			/>
			<Footer/>
		</div>
	);
}

export default HostelBookingPage;