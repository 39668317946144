import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
import { useSelector } from "react-redux";
// import { setHostels, setCurrentCity } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

// import { useHistory } from "react-router-dom";
// import { Link } from "react-router-dom";
import Error403 from "../ErrorPages/Error403";

import { Chart } from "react-google-charts";

import {
	Card,
	Grid
} from '@material-ui/core';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh"
	},

	page: {
		minHeight: "50vh",
		paddingBottom: "10%",
		background: "#f0f8ff"
	},

	header: {
		marginLeft: "4% !important"
	},

	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)',
	},

	dashboardGraph: {
		marginTop: "20px",
		padding: "20px",
		background: "white"
	},

}));

function UserDashboard(props) {

	const { history, classes } = props;
	// const bull = <span className={classes.bullet}>•</span>;

	const isMounted = useRef(false);
	const [error, setError] = useState(null);
	const [statistics, setStatistics] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	// const [message, setMessage] = useState(false);
	// const [isSuccess, setIsSuccess] = useState(false);
	// const [showNotification, setShowNotification] = useState(false);

	const userDetail = useSelector(state => state.user.currentUser);


	useEffect(() => {

		isMounted.current = true;

		document.title = 'Hostlr - Dashboard'

		const url = `${config.BASE_APP_URL}/statistics/`;

		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(isMounted.current) {
					if(result.response){
						setStatistics(result.statistics)
	
					} else{
						setError(result.message);
	
						if(result.status_code === 401) {
							history.push("/login/");
							return () => isMounted.current = false;
						}
					}
					setIsLoading(false);
				}
			},
			(error) => {
				if(isMounted.current) {
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)

		return () => isMounted.current = false;
		// eslint-disable-next-line
	}, []);

	return (
		<div className={classes.page}>
			{ !isLoading && statistics && userDetail ?
				<div>
					{ userDetail.is_hostel_owner ?
					
						<Grid container spacing={5} className="mainContainer">

							<Grid item xs={12} sm={6} md={3}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Total Tenants 
											<span className="tag" 
												style={{
													float: "right"
												}}>
												Current
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
											{statistics && statistics.total_tenants ? statistics.total_tenants : ""}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Tenants Joined
											<span className="tag" 
												style={{
													float: "right"
												}}>
												This Month
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
												{statistics && statistics.tenants_joined ? statistics.tenants_joined : ""}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Tenants Left
											<span className="tag" 
												style={{
													float: "right"
												}}>
												This Month
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
												{statistics && statistics.tenants_left ? statistics.tenants_left : ""}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={3}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Payments
											<span className="tag" 
												style={{
													float: "right"
												}}>
												This Month
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
												{statistics && statistics.payments ? statistics.payments : 0}
											</span> Tenants
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								<Chart
									// width={'500px'}
									className={classes.dashboardGraph}
									height={'400px'}
									chartType="PieChart"
									loader={<div>Loading Chart</div>}
									data={statistics.occupancy}
									options={{
										is3D: true,
										title: 'Hostel Occupancy'
									}}
								/>

							</Grid>

							<Grid item xs={12} sm={12} md={6}>
								<Chart
									className={classes.dashboardGraph}
									// width={'500px'}
									height={'400px'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={statistics.availability}
									options={{
										// Material design options
										// is3D: true,
										chart: {
											title: 'Hostel Availability',
										},
										legend: { position: 'bottom' },
									}}
									// For tests
									// rootProps={{ 'data-testid': '2' }}
								/>
							</Grid>
						</Grid> : ""
					}

					{ userDetail.is_tenant ?
						<Grid container spacing={5} className="mainContainer">

							<Grid item xs={12} sm={6} md={4}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Total Payment 
											<span className="tag" 
												style={{
													float: "right"
												}}>
												Current
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
											{statistics && statistics.payments ? statistics.payments : 0}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Payment
											<span className="tag" 
												style={{
													float: "right"
												}}>
												This Month
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
												{statistics && statistics.payments_current_month ? statistics.payments_current_month : 0}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12} sm={6} md={4}>
								<Card variant="outlined">
									<CardContent>
										<div>
											Total Reviews
											<span className="tag" 
												style={{
													float: "right"
												}}>
												Current
											</span>
										</div>

										<div className="statisticsCount">
											<span className="statistics">
												{statistics && statistics.reviews ? statistics.reviews : 0}
											</span>
										</div>
									</CardContent>
								</Card>
							</Grid>

							<Grid item xs={12}>
								<Chart
									className={classes.dashboardGraph}
									// width={'500px'}
									height={'400px'}
									chartType="Bar"
									loader={<div>Loading Chart</div>}
									data={statistics.monthly_data}
									options={{
										// Material design options
										// is3D: true,
										chart: {
											title: 'Payments',
										},
										legend: { position: 'bottom' },
									}}
									// For tests
									// rootProps={{ 'data-testid': '2' }}
								/>
							</Grid>
						</Grid> : ""
					}
				</div> : ""
			}
			
			{ isLoading? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }

			{ error ?
				<Error403
					history={history}
				/> : ""
			}
		
		</div>
	)
}

function DashboardPage(props) {

	const classes = useStyles();
	const { history } = props;

	const userUUID = props.match.params.uuid;

	return (
		<div className={classes.root}>
			<Header/>
			<UserDashboard history={history} userUUID={userUUID} classes={classes}/>
			<Footer/>
		</div>
	);
}

export default DashboardPage;