import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Checkbox from '@material-ui/core/Checkbox';
import MaterialUILink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

import HostlrImage from '../../assets/images/hostlr_2.jpg';
import HostlrLogo from '../../assets/images/hostlr.png';

import config from "../../config.json";
import ApiWrapper from "../../apis/DefaultApiWrapper";
import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<MaterialUILink color="inherit" href="http://hostlr.in/" target="_blank">
				Hostlr
			</MaterialUILink> 2020 - {' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: `url(${HostlrImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
		theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		marginTop: theme.spacing(12, 4),
		margin: "20px",
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundImage: `url(${HostlrLogo})`,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
        width: "98%",
        margin: theme.spacing(3, 0, 2),
    },
    
    cancel: {
        width: "98%",
        margin: theme.spacing(3, 0.5, 2),
    },

	backButtonStyle: {
        top: "50px",
        position: "absolute",
        left: "40px"
	},

	formControl: {
		marginTop: "20px"
	},

	error: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#f50057"
	},

	success: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#317326"
	},

	noUnderScore: {
		textDecoration: "none",
		'&:hover': {
			textDecoration: "underline",
		},
	}

}));

function RegistrationPage() {

	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [message, setMessage] = useState(null);

	// var emailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	var emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;

	function validateEmail(event) {
		if(emailFormat.test(event.target.value)) {
			setState({
				...state,
				emailError: false
			})
		} else {
			setState({
				...state,
				emailError: true
			})
		}
	}

	function validateForm() {
		if(state.firstName === "" || state.lastName === "" 
			|| state.email === "" || state.password === "" 
			|| state.confirmPassword === "" || state.emailError 
			|| state.registerType === "") {
			setState({
				...state,
				firstNameError: state.firstName === "" ? true : false,
				lastNameError: state.lastName === "" ? true : false,
				passwordError: state.passwordError === "" ? true : false,
				confirmPasswordError: state.confirmPasswordError === "" || state.confirmPasswordError ? true : false,
				emailError: state.emailError,
				registerTypeError: state.registerType === "" ? true : false
			})
			return false
		} else {
			setState({
				...state,
				registerTypeError: false
			})
			return true
		}
	}

	const [state, setState] = useState({
		firstName: "",
		lastName: "",
		email: "",
		password: "",
		confirmPassword: "",
		registerType: ""

	})

	const handleRadioChange = (event) => {
		setState({
			...state,
			registerType: event.target.value,
			registerTypeError: false
		})
	};

	function clearFormData() {
		setState({
			...state,
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			confirmPassword: "",
			registerType: ""
		})
	}

	function registerUser(event) {

		event.preventDefault();

		if(!validateForm()) {
			return false
		}

		setIsLoading(true);

		const data = {
			first_name: state.firstName,
			last_name: state.lastName,
			user_email: state.email,
			password: state.password,
			confirm_password: state.confirmPassword,
			register_type: state.registerType
		}

		const url = `${config.BASE_APP_URL}/signup/`;
		const options = {
			method:'post',
			body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					clearFormData();
					setError(null);
					setMessage(result.message);

				} else{
					setError(result.message);
					setMessage(null);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
				setMessage(null);
			}
		)
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />

			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						{/* <LockOutlinedIcon /> */}
						{/* HostlrLogo */}
					</Avatar>
					{/* <Typography component="h1" variant="h5">
						SIGN IN
					</Typography> */}
					<form className={classes.form} noValidate onSubmit={registerUser}>

						<TextField 
							error={state.firstNameError ? true : false}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="firstName"
							label="First Name"
							name="firstName"
							value={state.firstName}
							onInput = { 
								event => setState({
									...state,
									firstName: event.target.value,
									firstNameError: false
								})
							}
							helperText={state.firstNameError ? "This is required field" : ""}
						/>

						<TextField 
							error={state.lastNameError ? true : false}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="lastName"
							label="Last Name"
							name="lastName"
							value={state.lastName}
							onInput = { 
								event => setState({
									...state,
									lastName: event.target.value,
									lastNameError: false
								})
							}
							helperText={state.lastNameError ? "This is required field" : ""}
						/>

						<TextField 
							error={state.emailError ? true : false}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							value={state.email}
							onInput = { 
								event => setState({
									...state,
									email: event.target.value
								})
							}
							helperText={state.emailError ? "Invalid email Address" : ""}
							onKeyUp={validateEmail}
						/>

						<TextField 
							error={state.passwordError ? true : false}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="password"
							label="Password"
							name="password"
							type="password"
							value={state.password}
							onInput = { 
								event => setState({
									...state,
									password: event.target.value,
									passwordError: false
								})
							}
							helperText={state.passwordError ? "This is required field" : ""}
						/>

						<TextField 
							error={state.confirmPasswordError ? true : false }
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="conformPassword"
							label="Confirm Password"
							name="confirmPassword"
							type="password"
							value={state.confirmPassword}
							onInput = { 
								event => setState({
									...state,
									confirmPassword: event.target.value,
									confirmPasswordError: state.password === event.target.value ? false : true
								})
							}
							helperText={state.confirmPasswordError && state.confirmPassword === "" ? "This is required field" : state.password === state.confirmPassword ? "" : "Password and confirm Password did not match"}
						/>

						<FormControl fullWidth error={state.registerTypeError} className={classes.formControl}>
        					<FormLabel component="legend">Registrated As</FormLabel>
							<RadioGroup aria-label="Type" name="Type" value={state.registerType} onChange={handleRadioChange}>
								<Grid container > 
									<Grid item xs={6}>
										<FormControlLabel value="hostel_owner" control={<Radio />} label="Hostel Owner" />
									</Grid>
									<Grid item xs={6}>
										<FormControlLabel value="tenant" control={<Radio />} label="Tenant" />
									</Grid>
								</Grid>
							</RadioGroup>
							<FormHelperText>{state.registerTypeError ? "Please select Tenant or Hostel Owner" : ""}</FormHelperText>
						</FormControl>
						
                        <Grid container>
							<Grid item xs={6}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign Up
                                </Button>
							</Grid>
							<Grid item xs={6}>
								<Link 
									to={`/`}
									className={classes.noUnderScore}
								>
									<Button
										fullWidth
										variant="contained"
										size="medium"
										color="secondary"
										className={classes.cancel}
										disabled={true ? isLoading: false}
									>
										Cancel
									</Button>
								</Link>
							</Grid>
						</Grid>

						{ error ? 
							<div className={classes.error}>
								{error}
							</div> : ""
						}

						{message ?
							<div className={classes.success}>
								{message}
							</div> : ""
						}
						
						<Grid container>
							<Grid item xs>
								<Link to="/login/" className={classes.noUnderScore}>
									Already Have an Account?
								</Link>
							</Grid>
							<Grid item>
								<Link to="/forgot-password/" className={classes.noUnderScore}>
									Forgot Password?
								</Link>
							</Grid>
						</Grid>
						
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
					
				</div>
			</Grid>

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/>}

		</Grid>
	);
}

export default RegistrationPage;