import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

// import HostlrLogo from "../../assets/images/hostlr.png";
import DefaultUserImage from "../../assets/images/male.png";
import Divider from '@material-ui/core/Divider';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';

import Avatar from '@material-ui/core/Avatar';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HomeIcon from '@material-ui/icons/Home';

import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// import MailIcon from '@material-ui/icons/Mail';
// import NotificationsIcon from '@material-ui/icons/Notifications';
import InboxIcon from '@material-ui/icons/Inbox';

import PersonIcon from '@material-ui/icons/Person';
import { Grid } from '@material-ui/core';

import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Drawer,
	Typography
} from "@material-ui/core";

import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../CommonFunctions/SimpleBackdrop";

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

// Redux'
import { useSelector, useDispatch } from "react-redux";
import { setUser, checkAuthToken } from "../../actions";

import  "../../styles/Common.scss";

var drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		marginBottom: "80px"
	},
	navBar: {
		// zIndex: theme.zIndex.drawer + 300,f5f5f5
		// backgroundColor: "#67b4f7 !important",
		backgroundColor: "#ffffff !important"

	},
	menuButton: {
		marginRight: theme.spacing(2),
		color: "black"
	},

	// OpenMenuIcon: {
	// 	top: "5px"
	// },

	title: {
		flexGrow: 1
	},
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto',
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {
		color: 'inherit',
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('md')]: {
			width: '20ch',
		},
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},

  	appLogo: {
		  width: "100px",
		  height: "70px",
		  display: "flex",
		  paddingTop: "5px"
	},

	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		// marginTop: "65px",
		// color: "white",
		width: drawerWidth
		// backgroundColor: "#3c3a3a",
	},
	drawerContainer: {
		height: "96vh"
		// overflow: 'auto',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	// menuLink: {
	// 	// color: "white"
	// },

	appBar: {
		backgroundColor: "#ffffff !important",
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	// appBarShift: {
	// 	backgroundColor: "#ffffff !important",
	// 	marginLeft: drawerWidth,
	// 	width: `calc(100% - ${drawerWidth}px)`,
	// 	transition: theme.transitions.create(['width', 'margin'], {
	// 		easing: theme.transitions.easing.sharp,
	// 	  	duration: theme.transitions.duration.enteringScreen,
	// 	}),
	// },
	
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		marginRight: "20px",
		...theme.mixins.toolbar,
	},

	// menuButton: {
	// 	marginRight: 36,
	// },
	menuButtonHidden: {
		display: 'none',
	},

	menuItem: {
		color: "black",
		textDecoration: "none"
	},

	UserProfilePicture: {
		top: "2%",
		left: "25%",
		width: "50%",
		height: "20%"
	},

	UserInfo: {
		textAlign: "center",
		marginTop: "25px",
		marginBottom: "20px"
	},

	Username: {
		marginBottom: "5px"
	},

	UserIcon: {
		background: "red"
	},

	UserAccount: {
		width: "50px"
	},

	UserAccountInfo: {
		top: "10px !important",
		width: 250
	},

	UserProfileImage: {
		left: "35%"
	},

	UserOnlineBadge: {
		top: "70%",
		left: "70%",
		right: 5,
		background: "#20ce20"
	},

	LoginButtonLink: {
		textDecoration: "none"
	},

	LoginButton: {
		padding: "10px",
		borderRadius: 30,
		width: "150px"
	},

	headerLink: {
		width: "100%",
		color: "#222222",
		textDecoration: "none"
	}

}));

const HostlrLogo = "https://hostlrbucket.s3.ap-south-1.amazonaws.com/media/default_images/app_logo.png"

export default function Header(props) {

	const classes = useStyles();

	const history = useHistory();

	const dispatch = useDispatch();
	const userDetail = useSelector(state => state.user.currentUser);

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const [open, setOpen] = useState(false);
	// const [invisible, setInvisible] = useState(false);

	const invisible = false;

	const toggleMenuDrawer = () => {
		setOpen(!open);
	}

	const [anchorEl, setAnchorEl] = useState(null);
	const userIcon = Boolean(anchorEl);
  
	const handleMenu = (event) => {
	  setAnchorEl(event.currentTarget);
	};
  
	const handleClose = () => {
		setAnchorEl(null);
	};

	function handleLogout() {

		setAnchorEl(false);

		const url = `${config.BASE_APP_URL}/logout/`;
		const options = {
			method:'get',
            headers: {
                "Content-Type": "application/json"
            }
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				localStorage.removeItem('hostlr_jwt');
				localStorage.removeItem('hostlr_user');

				if(!result.response){
					history.push("/logout/");

				} else{
					setError(result.message);
				}
				setIsLoading(false);
				history.push("/logout/");
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	// Since header component is mounted everytime, here we can update title for the Application
	useEffect(() => {

		// Scroll window to top on landing for the first time
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		const isAuthTokenAvailable = checkAuthToken();

		if(isAuthTokenAvailable && !userDetail ) {

			const url = `${config.BASE_APP_URL}/logged/user/`;
			const options = {
				method:'get',
				headers: {
					"Content-Type": "application/json"
				}
			}
	
			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(result.response){
						dispatch(setUser(result.user_details));
	
					} else{
						localStorage.removeItem('hostlr_jwt');
						localStorage.removeItem('hostlr_user');
						history.push("/");
						setError(result.message);
					}
					setIsLoading(false);
				},
				(error) => {
					history.push("/");
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			)

		}

	}, [dispatch, userDetail, history]);

	let menuItems = [];

	if(userDetail && userDetail.is_hostel_owner) {
		menuItems = [
			{
				link: `/${userDetail.uuid}/hostels/`,
				name: "Hostels",
				icon: <HomeIcon className="text-white" />
			}, {
				link: "/dashboard/",
				name: "Dashboard",
				icon: <DashboardIcon className="text-white" />
			}, {
				link: "/add-hostel/",
				name: "Add Hostel",
				icon: <DashboardIcon className="text-white" />
			}, {
				link: "/add-tenant/",
				name: "Add Tenant",
				icon: <DashboardIcon className="text-white" />
			}, {
				link: `/tenant-requests/${userDetail.uuid}/`,
				name: "Tenant Requests",
				icon: <InboxIcon className="text-white" />
			},

		];
	} else if (userDetail && userDetail.is_tenant) {
		menuItems = [
			{
				link: "/",
				name: "Landing Page",
				icon: <HomeIcon className="text-white" />
			}, {
				link: "/dashboard/",
				name: "Dashboard",
				icon: <DashboardIcon className="text-white" />
			}, {
				link: "/my-account/",
				name: "Bookings",
				activeTab: 0,
				icon: <HomeIcon className="text-white" />
			}, {
				link: "/my-account/",
				name: "Reviews",
				activeTab: 1,
				icon: <HomeIcon className="text-white" />
			}, {
				link: "/my-account/",
				name: "Payments",
				activeTab: 2,
				icon: <HomeIcon className="text-white" />
			},
			{
				link: `/tenant-requests/${userDetail.uuid}/`,
				name: "Applications",
				icon: <InboxIcon className="text-white" />
			}
		];
	} else {
		menuItems = [
			{
				link: "/",
				name: "Landing Page",
				icon: <HomeIcon className="text-white" />
			}
		];
	}

	return (
		<div className={classes.grow}>

			{/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}

			<AppBar position="fixed" 
				// className={clsx(classes.appBar, open && classes.appBarShift)}
				className={classes.appBar}
			>
				<Toolbar className={classes.toolbar}>
					{ userDetail && userDetail.user ?
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={toggleMenuDrawer}
							className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						>
							{open ? "" : <MenuIcon />}
						</IconButton> : ""
					}
					{/* {open ? "" :  */}
						<Typography className={classes.title} noWrap>
							<Link
								key="homepagelinked"
								to={ userDetail && userDetail.is_hostel_owner ? `/${userDetail.uuid}/hostels/` : "/" }
							>
								<img src={HostlrLogo} className={classes.appLogo} alt="Hostlr Logo"/>
							</Link>
						</Typography>
					{/*  } */}

					{ userDetail ? 
						<div className={classes.UserAccount}>
							<IconButton
								aria-label="User Account"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
							>
								<Badge
									classes={{ badge:classes.UserOnlineBadge }}
									color="secondary"
									variant="dot"
									invisible={invisible}
								>
									{userDetail.user_image ? 
										<Avatar sizes="44" alt="User Profile" src={userDetail.user_image} />
										: 
										<Avatar sizes="44" alt="User Profile" src={DefaultUserImage} />
									}
								</Badge>

							</IconButton>
							<Menu
								classes={{ paper:classes.UserAccountInfo }}
								id="menu-appbar"
								anchorEl={anchorEl}
									anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={userIcon}
								onClose={handleClose}
							>
								<MenuItem>
									<Link
										className={classes.headerLink}
										key="user-profile-link"
										to={`/user-profile/${userDetail.uuid}/`}
									>
										<Grid container justify="center">
											<Grid item xs={3}>
												<Badge
													classes={{ badge:classes.UserOnlineBadge }}
													color="secondary"
													variant="dot"
													invisible={invisible}
												>
													{userDetail.user_image ? 
														<Avatar sizes="44" alt="User Profile" src={userDetail.user_image} />
														: 
														<Avatar sizes="44" alt="User Profile" src={DefaultUserImage} />
													}
												</Badge>
											</Grid>
											<Grid item xs={9} style={{
												marginTop: "10px"
											}}>
												{userDetail.user && userDetail.user.name ? userDetail.user.name : ""}
											</Grid>
										</Grid>
									</Link>
								</MenuItem>
								<Divider/>
								<MenuItem onClick={handleClose}>
									<Link
										className={classes.headerLink}
										key="user-profile"
										to={`/user-profile/${userDetail.uuid}/`}
									>
										Profile
									</Link>
								</MenuItem>
								<MenuItem onClick={handleClose}>
									<Link
										className={classes.headerLink}
										key="my-account"
										to="/my-account/"
									>
										My account
									</Link>
								</MenuItem>
								<MenuItem onClick={handleLogout}>Logout</MenuItem>
							</Menu>
						</div> :

						<Link 
							to="/login/"
							className={classes.LoginButtonLink}
						>
							<Button
								className={classes.LoginButton}
								variant="contained"
								color="secondary"
								startIcon={<PersonIcon />}
							>
								Sign In
							</Button>
						</Link>
				
					}

				</Toolbar>
			</AppBar>
      
			{ userDetail && userDetail.user ?
				<Drawer
					className={classes.drawer}
					variant="temporary"
					open={open}
					classes={{
						paper: classes.drawerPaper,
					}}
					onClose={toggleMenuDrawer}
				>
					<div className={classes.toolbarIcon}>
						<Typography noWrap>
							<Link
								key="homepagelinked"
								to={ userDetail && userDetail.is_hostel_owner ? `/${userDetail.uuid}/hostels/` : "/" }
							>
								<img src={HostlrLogo} className={classes.appLogo} alt="Hostlr Logo"/>
							</Link>
						</Typography>
						<IconButton onClick={toggleMenuDrawer} className={classes.OpenMenuIcon}>
							<MenuOpenIcon />
						</IconButton>
					</div>

					<div className={classes.drawerContainer}>
						<Divider/>

						{ userDetail &&  userDetail.user_image ?
							<Avatar alt="User Profile" src={userDetail.user_image} className={classes.UserProfilePicture}/>
							: <Avatar alt="User Profile" src={DefaultUserImage} className={classes.UserProfilePicture}/>
						}
						{/* <Avatar alt="User Profile" src={DefaultUserImage} className={classes.UserProfilePicture}/> */}
						<div className={classes.UserInfo}>
							<div className={classes.Username}>
								{userDetail ? userDetail.user.name : "Welcome Guest" }
							</div>

							<div>
								{userDetail ?  userDetail.user.email : "" }
							</div>
						</div>

						<Divider/>
						<List className={classes.blackList}>
							{menuItems.map(element => {
								return (
									<Link
										key={element.name}
										to={{
											pathname:element.link,
											activeTab: element.activeTab
										}}
										onClick={toggleMenuDrawer}
										className={classes.menuItem}
									>
										<ListItem button>
											<ListItemIcon>
												{element.icon}
											</ListItemIcon>
											<ListItemText
												primary={element.name}
											/>
										</ListItem>
									</Link>
								);
							})}
						</List>
					</div>
				</Drawer> : ""
			}
			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }

			{ error ? 
				<div>{error}</div> : "" 
			}
		</div>
	);
}