import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';

// import { Link, useHistory } from "react-router-dom";

import ToastNotification from '../../components/CommonFunctions/ToastNotification';
import HorizontalDividerWithText from "../../components/CommonFunctions/HorizontalDividerWithText";

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// DropDown
import Autocomplete from '@material-ui/lab/Autocomplete';

// import Error404 from "../ErrorPages/Error404";


import {
	Card,
	CardMedia,
	Typography,
	Grid,
	Avatar,
    Button
} from '@material-ui/core';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({

    root: {
        width: "100%",
        overflow: "hidden",
        minHeight: "100vh"
    },

    page: {
        minHeight: "70vh",
        paddingBottom: "10%",
        background: "#f0f8ff"
    },

    header: {
        marginLeft: "4% !important"
    },

    profileContainer: {
        margin: "1%",
        width: "98%"
    },

    card: {
        height: "300px",
        // maxWidth: "300px"
    },

    media: {
        top: "15px",
        marginBottom: "15px",
		height: 170,
		width: 170,
		borderRadius: "50%",
		position: "relative",
		// backgroundSize: 'cover',
	},

	userProfileForm: {
		marginTop: "10px",
		background: "white"
	},

	hostelDetailForm: {
		marginTop: "10px",
		paddingBottom: "30px !important",
		background: "white"
	},

	uploadButton: {
		marginTop: "30px"
	},

	profilePictureUpload: {
		display: 'none',
	},

	formButtons: {
		marginTop: "20px"
	},

	tenantAvatar: {
		top: "15px",
		marginBottom: "15px",
		height: 170,
		width: 170,
		borderRadius: "50%",
		position: "relative",
		fontSize: 50
	},

	overlay: {
		height: 170,
		width: 170,
		// top: "95px",
		// left: "104px",
		opacity: 0.3,
		position: "absolute",
		transition: ".3s ease",
		backgroundColor: "#c1bdbd",
		borderRadius: "50%",
		cursor: "pointer",

		// [theme.breakpoints.up('xs')]: {
		// 	left: "62px",
		// },
	},

	ProfilePictureDeleteIcon: {
		left: "78px",
		top: "70px",
		position: "absolute",
		color: "red",
		cursor: "pointer"
	},

	// Modal
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	paper: {
		minWidth: "300px",
		minHeight: "100px",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

}));

function getUserCharacters(userProfile) {

	if(userProfile.user_image) {
		return userProfile.user_image;
	} else {
		let matches = userProfile.user.name.match(/\b(\w)/g);
		let displayName = matches.join('');
		return displayName
	}

}

function ConfirmationModal(props) {

	const classes = useStyles();
	const { open, setOpen, title, setRemoveProfilePicture } = props;

	const handleConfirmation = () => {
		setRemoveProfilePicture(true);
	}

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
						<Typography variant="body1">
							{title}
						</Typography>
						<br></br>

						<Grid container justify="center" spacing={0}>
							<Grid item xs={5}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className="acceptButton"
									onClick={handleConfirmation}
								>
									Yes
								</Button>

							</Grid>

							<Grid item xs={1}></Grid>

							<Grid item xs={5}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className="cancelButton"
									onClick={handleClose}
								>
									CANCEL
								</Button>

							</Grid>
						</Grid>

					</div>
			</Fade>
      </Modal>
	)
}


function UserProfileComponent(props) {

	const { classes, history, userUUID } = props;

	const [ error, setError ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ openModal, setOpenModal ] = useState(false);
	const [ removeProfilePicture, setRemoveProfilePicture] = useState(false);
	const [ canEditUserProfile, setCanEditUserProfile ] = useState(false);
	const [ canEditHostelInfo, setCanEditHostelInfo ] = useState(false);
	const [ showProfilePictureDeleteIcon, setShowProfilePictureDeleteIcon] = useState(false);
	const [ userProfile, setUserProfile ] = useState(null);
	const [ tenant, setTenant ] = useState(null);

	const [ showNotification, setShowNotification ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ message, setMessage ] = useState(null);

	const [ genderChoices, setGenderChoices ] = useState(null);
	const [ userGender, setUserGender ] = useState({});
	const [inputGenderValue, setInputGenderValue] = useState(null);

	const [ hostels, setHostels ] = useState(null);
	const [ userHostel, setUserHostel] = useState({});
	const [inputHostelValue, setInputHostelValue] = useState(null);

	const [ sharingTypes, setSharingTypes ] = useState(null);
	const [ userSelectedSharingType, setUserSharingType ] = useState({});
	const [inputSharingTypeValue, setInputSharingTypeValue] = useState(null);

	const _isMounted = useRef(true);

	const [ state, setState ] = useState({

		// User Detail
		firstName: "",
		lastName: "",
		gender: "",
		emailAddress: "",
		mobileNumber: "",
		userImage: "",

		// Address
		firstLine: "",
		secondLine: "",
		city: "",
		state: "",
		pincode: "",

		// Hostel Detail
		roomNumber: "",
		hostel: "",
		sharingType: ""

	});
	
	// Redux state update
	const dispatch = useDispatch();
	const userDetail = useSelector(state => state.user.currentUser);

	var emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;

	function validateEmail(event) {
		if(emailFormat.test(event.target.value)) {
			setState({
				...state,
				emailAddressError: false
			})
		} else {
			setState({
				...state,
				emailAddressError: true
			})
		}
	}

	function handleMobileNumber(event) {

		if(isNaN(state.mobileNumber) || state.mobileNumber.length > 10 || state.mobileNumber.length < 10) {
			setState({
				...state,
				mobileNumberError: true
			})
		} else {
			setState({
				...state,
				mobileNumberError: false
			})
		}
	}

	function handlePincode(event) {

		if(isNaN(state.pincode) || state.pincode.length > 6 || state.pincode.length < 6) {
			setState({
				...state,
				pincodeError: true
			})
		} else {
			setState({
				...state,
				pincodeError: false
			})
		}
	}

	function validateUserBasicDetail() {
		if(state.firstName === "" || state.lastName === "" || state.gender === "" || 
		state.emailAddress === "" || state.emailAddressError || state.mobileNumber === "" || 
		state.mobileNumberError || state.mobileNumber.length !== 10) {
			setState({
				...state,
				firstNameError: state.firstName === "" ? true : false,
				lastNameError: state.lastName === "" ? true : false,
				genderError: state.gender === "" ? true : false,
				emailAddressError: state.emailAddress === "" || state.emailAddressError ? true : false,
				mobileNumberError: state.mobileNumber === "" || state.mobileNumberError || state.mobileNumber.length !== 10 ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateUserAddresss() {
		if(state.firstLine === "" || state.secondLine === "" || state.city === "" || 
		state.state === "" || state.pincode === "" || state.pincodeError || state.pincode.length !== 6) {
			setState({
				...state,
				firstLineError: state.firstLine === "" ? true : false,
				secondLineError: state.secondLine === "" ? true : false,
				cityError: state.city === "" ? true : false,
				stateError: state.state === "" ? true : false,
				pincodeError: state.pincode === "" || state.pincodeError || state.pincode.length !== 6 ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateHostelDetail() {
		if(state.hostel === "" || state.sharingType === "" || state.roomNumber === "") {
			setState({
				...state,
				hostelError: state.hostel === "" ? true : false,
				sharingTypeError: state.sharingType === "" ? true : false,
				roomNumberError: state.roomNumber === "" ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	useEffect(() => {
		setState({
			...state,
			firstName: userProfile  && userProfile.user ? userProfile.user.first_name : "",
			lastName: userProfile && userProfile.user ? userProfile.user.last_name : "",
			gender: userProfile && userProfile.gender ? userProfile.gender : "",
			emailAddress: userProfile && userProfile.user ? userProfile.user.email : "",
			userImage: userProfile && userProfile.user_image ? userProfile.user_image : "",
			mobileNumber: userProfile && userProfile.mobile_number ? userProfile.mobile_number : "",
			roomNumber: tenant ? tenant.room_no : "",
			sharingType: userSelectedSharingType ? userSelectedSharingType.value : "",
			hostel: userHostel ? userHostel.uuid : "",

			firstLine: userProfile && userProfile.address ? userProfile.address.first_line : "",
			secondLine: userProfile && userProfile.address ? userProfile.address.second_line : "",
			city: userProfile && userProfile.address ? userProfile.address.city.name : "",
			state: userProfile && userProfile.address ? userProfile.address.state : "",
			pincode: userProfile && userProfile.address ? userProfile.address.pin_code : ""
	
		})

	}, [userProfile, tenant]); // eslint-disable-line react-hooks/exhaustive-deps


	async function updateUserProfileDetails(result) {

		setGenderChoices(result.genders);
		setHostels(result.hostels);
		setSharingTypes(result.sharing_types);
		setTenant(result.tenant);
		
		const selectedOption = result.genders.filter(
			option => option.value === result.user.gender
		);
		setUserGender(selectedOption[0]);
		setInputGenderValue(selectedOption[0].title);

		if(result.user.is_tenant) {
			setUserHostel(result.hostel);
			setInputHostelValue(result.hostel.name);

			const selectedSharingOption = result.sharing_types.filter(
				option => option.value === result.tenant.sharing_type
			);
			setUserSharingType(selectedSharingOption && selectedSharingOption.length > 0 ? selectedSharingOption[0] : "");
			setInputSharingTypeValue(selectedSharingOption && selectedSharingOption.length > 0 ? selectedSharingOption[0].title : "");
		}

		setUserProfile(result.user);

		if(userDetail && userDetail.uuid === result.user.uuid){
			dispatch(setUser(result.user));
		}

		setCanEditUserProfile(result.can_edit_user_profile);
		setCanEditHostelInfo(result.can_edit_hostel_detail);

	}
	
	useEffect(() => {

		setIsLoading(true);

		// Scroll window to top on landing for the first time
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		document.title = 'Hostlr - Profile'

		const url = `${config.BASE_APP_URL}/users/?user_uuid=${userUUID}`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(_isMounted.current) {
					if(result.response){
	
						updateUserProfileDetails(result);
	
					} else{
						setIsSuccess(false);
						setMessage(result.message);
						setError(result.message);
	
						if(result.status_code === 401) {
							history.push("/login/");
							return () => { _isMounted.current = false }
						} else {
							setShowNotification(true);
						}
					}
				}
				setIsLoading(false);
			},
			(error) => {
				if(_isMounted.current) {
					setIsSuccess(false);
					setMessage(error);
					setShowNotification(true);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)
		return () => { _isMounted.current = false }

	}, [userUUID]); // eslint-disable-line react-hooks/exhaustive-deps


    function onMouseEnterHandler(event) {
        setShowProfilePictureDeleteIcon(true);
    }

    function onMouseLeaveHandler(event) {
        setShowProfilePictureDeleteIcon(false);
	}

	function openConfirmationModal(event) {
		setOpenModal(true);
	}

	function removeProfileImage() {

		setIsLoading(true);
		setState({
			...state,
			image: "",
		});

		const data = {
			user_uuid: userUUID
		}

		const url = `${config.BASE_APP_URL}/users/`;
		const options = {
			method:'delete',
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					updateUserProfileDetails(result);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setError(result.message);

					if(result.status_code === 401) {
						history.push("/login/");
					} else {
						setShowNotification(true);
					}
				}
				setIsLoading(false);
				setOpenModal(false);
			},
			(error) => {
				setOpenModal(false);
				setIsSuccess(false);
				setMessage(error);
				setShowNotification(true);
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	useEffect(() => {
		if(removeProfilePicture && userProfile && userProfile.user_image) {
			removeProfileImage();
		} else if(removeProfilePicture && userProfile && userProfile.user_image === ""){
			setState({
				...state,
				image: "",
			});
			return true
		}

	}, [removeProfilePicture]); // eslint-disable-line react-hooks/exhaustive-deps

	function saveUserbasicDetail(event){

		event.preventDefault();

		if(!validateUserBasicDetail()) {
			return false
		}

		setIsLoading(true);

		let formData = new FormData();

		formData.append("first_name", state.firstName);
		formData.append("last_name", state.lastName );
		formData.append("email", state.emailAddress );
		formData.append("gender", state.gender);
		formData.append("mobile_number", state.mobileNumber);
		formData.append("user_image", state.uploadableImage);
		formData.append("user_uuid", userProfile.uuid);
		formData.append("user_basic_detail", true);

		const url = `${config.BASE_APP_URL}/users/`;
		const options = {
			method:'POST',
			body: formData
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					setShowNotification(true);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setError(result.message);
					setShowNotification(true);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	function saveUserAddressDetail(event) {
		
		event.preventDefault();

		if(!validateUserAddresss()){
			console.log("Not Valid")
			return false
		}

		setIsLoading(true);

		let formData = new FormData();

		formData.append("city", state.city);
		formData.append("state", state.state);
		formData.append("pincode", state.pincode);
		formData.append("user_uuid", userProfile.uuid);
		formData.append("first_line", state.firstLine);
		formData.append("second_line", state.secondLine);
		formData.append("user_address", true);

		const url = `${config.BASE_APP_URL}/users/`;
		const options = {
			method:'POST',
			body: formData
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					setShowNotification(true);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setError(result.message);
					setShowNotification(true);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	function saveHostelDetail(event) {

		event.preventDefault();

		if(!validateHostelDetail()) {
			return false
		}
		
		setIsLoading(true);
		let formData = new FormData();

		formData.append("room_number", state.roomNumber)
		formData.append("hostel", state.hostel )
		formData.append("sharing_type", state.sharingType )
		formData.append("user_uuid", userProfile.uuid);
		formData.append("tenant_detail", true);

		const url = `${config.BASE_APP_URL}/users/`;
		const options = {
			method:'POST',
			body: formData
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					setShowNotification(true);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setError(result.message);
					setShowNotification(true);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	function handleImageChange(event) {
		if (event.target.files && event.target.files[0]) {

			setState({
				...state,
				uploadableImage: event.target.files[0],
				image: URL.createObjectURL(event.target.files[0]),
			});

			// let reader = new FileReader();
			// reader.onload = (e) => {
			// 	setState({
			// 		...state,
			// 		image: e.target.result
			// 	});
			// };
			// reader.readAsDataURL(event.target.files[0]);
		}
	};

    return (
        <div className={classes.page}>

			{ userProfile ? 
				<Grid container spacing={2} className={classes.profileContainer}>
					<Grid item xs={12} sm={4} md={3}>

						<Card className={classes.card} align="center">
							{ state.image || state.userImage ? 
								<Grid item xs={12}>
									<CardMedia
										alt="User Profile"
										key = {userProfile.id}
										className={classes.media}
										image={ state.image ? state.image : state.userImage}
										title={userProfile.name}
										onMouseEnter={onMouseEnterHandler}
										// onMouseLeave={onMouseLeaveHandler}
									>

										{ showProfilePictureDeleteIcon ? 
											<div className={classes.overlay}
												onClick={openConfirmationModal}
												onMouseLeave={onMouseLeaveHandler}
											>
												<DeleteForeverIcon
													title="Delete Profile Picture"
													className={classes.ProfilePictureDeleteIcon}
												/>
											</div>
										
										: "" }
									</CardMedia>
								</Grid>

							: 
								<Avatar className={classes.tenantAvatar} alt={userProfile.user.name} >
									{getUserCharacters(userProfile)}
								</Avatar>
							}

							<div className={classes.uploadButton}>
								<input
									accept="image/*"
									className={classes.profilePictureUpload}
									id="profileImage"
									type="file"
									onChange={handleImageChange}
								/>
								<label htmlFor="profileImage">
									<Button 
										variant="contained" 
										color="primary" 
										component="span"
										startIcon={<CloudUploadIcon />}
									>
										Upload
									</Button>
								</label>
							</div>

							<CardContent>
								<Typography variant="body2" color="textSecondary" component="p" align="center">
									Allowed *.jpeg, *.jpg, *.png <br></br>
									Max size of 3 MB
								</Typography>
							</CardContent>
						</Card>

					</Grid>

					<Grid item xs={12} sm={8} md={9} className={classes.userProfileForm}>
						<form onSubmit={saveUserbasicDetail} >
							<Grid container spacing={3}>
								<Grid item xs={12} sm={6}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										disabled={ canEditUserProfile ? false : true }
										id="firstName"
										label="First Name"
										name="firstName"
										value={state.firstName}
										onInput = { 
											event => setState({
												...state,
												firstName: event.target.value,
												firstNameError: false
											})
										}
										error={state.firstNameError ? true : false}
										helperText={state.firstNameError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="lastName"
										label="Last Name"
										name="lastName"
										disabled={ canEditUserProfile ? false : true}
										value={state.lastName}
										onInput = { 
											event => setState({
												...state,
												lastName: event.target.value,
												lastNameError: false
											})
										}
										error={state.lastNameError ? true : false}
										helperText={state.lastNameError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="email"
										label="Email Address"
										name="email"
										disabled={true}
										value={state.emailAddress}
										onInput = { 
											event => setState({
												...state,
												emailAddress: event.target.value
											})
										}
										error={state.emailAddressError ? true : false}
										helperText={state.emailAddressError ? "Invalid email Address" : ""}
										onKeyUp={validateEmail}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="mobileNumber"
										label="Mobile Number"
										name="mobileNumber"
										disabled={ canEditUserProfile ? false : true}
										value={state.mobileNumber}
										onInput = { 
											event => setState({
												...state,
												mobileNumber: event.target.value,
												mobileNumberError: isNaN(event.target.value) ? true : false
											})
										}
										onBlur={handleMobileNumber}
										error={state.mobileNumberError ? true : false}
										helperText={state.mobileNumberError ? "Mobile number be should be 10 digits" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<Autocomplete
										disabled={ canEditUserProfile ? false : true}
										value={userGender}
										getOptionSelected={(option, value) => option.title === value.title}
										getOptionLabel={(option) => option.title}
										onChange={(event, newUserGenderValue) => {
											setUserGender(newUserGenderValue);
											setState({
												...state,
												gender: newUserGenderValue ? newUserGenderValue.value : "",
												genderError: false
											})
										}}
										options={genderChoices}
										inputValue={inputGenderValue}
										onInputChange={(event, newInputGenderValue) => {
											setInputGenderValue(newInputGenderValue);
										}}
										id="gender"
										fullWidth
										renderInput={
											(params) => <TextField 
												{...params} 
												label="Gender" 
												variant="outlined" 
												margin="dense" 
												error={state.genderError ? true : false}
												helperText={state.genderError ? "This is required field!" : ""}
											/>
										}
									/>

								</Grid>
								
							</Grid>

							{ canEditUserProfile ? 
								<Grid container spacing={1} justify="flex-end" className={classes.formButtons}>
									{/* <Grid item xs={6} sm={4}>
										<Button
											variant="contained"
											color="secondary"
											fullWidth
											onClick={EnableFormEdit}
										>
											Enable Edit
										</Button>
									</Grid> */}

									<Grid item xs={6} sm={5}>
										<Button
											type="submit"
											fullWidth
											variant="contained"
											color="primary"
											disabled={true ? isLoading: false}
										>
											Save Changes
										</Button>
									</Grid>
								</Grid> : ""
							}

						</form>
					</Grid>

					<HorizontalDividerWithText 
						title="User Address"
					/>
					<Grid item xs={12} className={classes.hostelDetailForm}>
						<form onSubmit={saveUserAddressDetail}>

							<Grid container spacing={2}>

								<Grid item xs={12} sm={6} md={4}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="firstLine"
										label="First Line"
										name="firstLine"
										value={state.firstLine}
										onInput = { 
											event => setState({
												...state,
												firstLine: event.target.value,
												firstLineError: false
											})
										}
										error={state.firstLineError ? true : false}
										helperText={state.firstLineError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="secondLine"
										label="Second Line"
										name="secondLine"
										value={state.secondLine}
										onInput = { 
											event => setState({
												...state,
												secondLine: event.target.value,
												secondLineError: false
											})
										}
										error={state.secondLineError ? true : false}
										helperText={state.secondLineError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="city"
										label="City"
										name="city"
										value={state.city}
										onInput = { 
											event => setState({
												...state,
												city: event.target.value,
												cityError: false
											})
										}
										error={state.cityError ? true : false}
										helperText={state.cityError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="state"
										label="State"
										name="state"
										value={state.state}
										onInput = { 
											event => setState({
												...state,
												state: event.target.value,
												stateError: false
											})
										}
										error={state.stateError ? true : false}
										helperText={state.stateError ? "This is required field!" : ""}
									/>
								</Grid>

								<Grid item xs={12} sm={6} md={4}>
									<TextField 
										variant="outlined"
										margin="dense"
										// required
										fullWidth
										id="pincode"
										label="Pin Code"
										name="pincode"
										value={state.pincode}
										onBlur={handlePincode}
										onInput = { 
											event => setState({
												...state,
												pincode: event.target.value,
												pincodeError:  isNaN(event.target.value) ? true : false
											})
										}
										error={state.pincodeError ? true : false}
										helperText={state.pincodeError ? "Pincode should be 6 digits!" : ""}
									/>
								</Grid>

								{ canEditUserProfile ? 
									<Grid container spacing={1} justify="flex-end" className={classes.formButtons}>

										<Grid item xs={6} sm={4}>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												color="primary"
												disabled={true ? isLoading: false}
											>
												Save Changes
											</Button>
										</Grid>
									</Grid> : ""
								}
							</Grid>
						</form>
					</Grid>


					{ userProfile && userProfile.is_tenant && tenant && tenant.is_active > 0 ?
						<HorizontalDividerWithText 
							title="Hostel Information"
						/> : "" 
					}

					{  userProfile && userProfile.is_tenant && tenant && tenant.is_active > 0 ?
						
						<Grid item xs={12} className={classes.hostelDetailForm}>
							<form onSubmit={saveHostelDetail}>
								<Grid container spacing={2}>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<Autocomplete
											value={userHostel}
											id="hostel"
											options={hostels}
											disabled={ canEditHostelInfo ? false : true}
											getOptionSelected={(option, value) => option.name === value.name}
											getOptionLabel={(option) => option.name}
											inputValue={inputHostelValue}
											onInputChange={(event, newInputHostelValue) => {
												setInputHostelValue(newInputHostelValue);
											}}
											onChange={(event, newHostelValue) => {
												setUserHostel(newHostelValue);
												setState({
													...state,
													hostel: newHostelValue ? newHostelValue.uuid : "",
													hostelError: false
												})
											}}
											fullWidth
											renderInput={
												(params) => <TextField 
													{...params} 
													label="Hostel" 
													variant="outlined" 
													margin="dense"
													error={state.hostelError ? true : false}
													helperText={state.hostelError ? "This is required field!" : ""}
												/>
											}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<Autocomplete
											value={userSelectedSharingType}
											id="sharingType"
											options={sharingTypes}
											disabled={ canEditHostelInfo ? false : true}
											getOptionLabel={(option) => option.title}
											getOptionSelected={(option, value) => option.title === value.title}
											fullWidth
											inputValue={inputSharingTypeValue}
											onInputChange={(event, newInputSharingTypeValue) => {
												setInputSharingTypeValue(newInputSharingTypeValue);
											}}
											onChange={(event, newSharingTypeValue) => {
												setUserSharingType(newSharingTypeValue);
												setState({
													...state,
													sharingType: newSharingTypeValue ? newSharingTypeValue.value : "",
													sharingTypeError: false
												})
											}}
											renderInput={
												(params) => <TextField 
													{...params} 
													label="Sharing Type" 
													variant="outlined" 
													margin="dense"
													error={state.sharingTypeError ? true : false}
													helperText={state.sharingTypeError ? "This is required field!" : ""}
												/>
											}
										/>
									</Grid>

									<Grid item xs={12} sm={6} md={4} lg={4}>
										<TextField 
											variant="outlined"
											margin="dense"
											required
											fullWidth
											id="roomNumber"
											label="Room Number"
											name="roomNumber"
											value={state.roomNumber}
											disabled={ canEditHostelInfo ? false : true}
											onInput = { 
												event => setState({
													...state,
													roomNumber: event.target.value,
													roomNumberError: event.target.value === "" ? true : false
												})
											}
											error={state.roomNumberError ? true : false}
											helperText={state.roomNumberError ? "This is required field!" : ""}
										/>
									</Grid>
									
								</Grid>

								{ canEditHostelInfo ? 
									<Grid container spacing={1} justify="flex-end" className={classes.formButtons}>

										<Grid item xs={6} sm={4}>
											<Button
												type="submit"
												fullWidth
												variant="contained"
												color="primary"
												disabled={true ? isLoading: false}
											>
												Save Changes
											</Button>
										</Grid>
									</Grid> : ""
								}

							</form>
						
						</Grid> : ""
					}

				</Grid> : "" 
			}

			{ error ?
				<Grid container spacing={2}>
					{error}
				</Grid> : ""
			}

			{ isLoading ? <SimpleBackdrop open={true} /> : <SimpleBackdrop open={false} /> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>
			
			}

			<ConfirmationModal
				open={openModal}
				setOpen={setOpenModal}
				history={history}
				setRemoveProfilePicture={setRemoveProfilePicture}
				title="Are you sure you want to remove profile picture."
			/>
        </div>
    )
}

function UserProfilePage(props) {

	const classes = useStyles();
	const { history } = props;

	const userUUID = props.match.params.uuid;

	return (
		<div className={classes.root}>
			<Header/>
			<UserProfileComponent 
				history={history} 
				userUUID={userUUID} 
				classes={classes}
			/>
			<Footer/>
		</div>
	);
}

export default UserProfilePage;