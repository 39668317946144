import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
// import { useSelector, useDispatch } from "react-redux";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

import Rating from '@material-ui/lab/Rating';
import { Link } from "react-router-dom";

// import { Link, useHistory } from "react-router-dom";

import ToastNotification from '../../components/CommonFunctions/ToastNotification';
// import HorizontalDividerWithText from "../../components/CommonFunctions/HorizontalDividerWithText";

// import Error404 from "../ErrorPages/Error404";


import {
	Box,
	AppBar,
	Tabs,
	Tab,
	// Typography,
	Grid,
    Button
} from '@material-ui/core';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({

	root: {
        width: "100%",
        overflow: "hidden",
        minHeight: "100vh"
    },

    page: {
        minHeight: "70vh",
        paddingBottom: "10%",
        background: "#f0f8ff"
	},
	
	tabAppBar: {
		// margin: "2%",
		boxShadow: "none",
		background: "#f0f8ff",
		marginTop: "90px !important",
		// marginLeft: "1% !important",
	},

	tabLink: {
		color: "black"
	},

	table: {
		minWidth: 500,
	},

    header: {
        marginLeft: "4% !important"
    },

    profileContainer: {
        margin: "1%",
        width: "98%"
	},
	
	hostelReview: {
		marginLeft: 25,
		padding: "5px"
	}
}));

function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`nav-tabpanel-${index}`}
		aria-labelledby={`nav-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  	<Box p={3}>
				<div> {children} </div>
				{/* <Typography>{children}</Typography> */}
		  	</Box>
		)}
	  </div>
	);
}


TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `nav-tab-${index}`,
	  'aria-controls': `nav-tabpanel-${index}`,
	};
}


function LinkTab(props) {
	return (
		<Tab
			component="a"
			onClick={(event) => {
				event.preventDefault();
			}}
			{...props}
		/>
	);
}


function BookingHistory(props) {

	const { bookings, classes } = props;

	return (
		<TableContainer>
			{ bookings && bookings.length > 0 ? 
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Hostel</TableCell>
							<TableCell>Sharing Type</TableCell>
							<TableCell align="right">Room No</TableCell>
							<TableCell align="right">Joining Date</TableCell>
							<TableCell align="right">Status</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{bookings.map((booking) => (
							<TableRow key={booking.id}>
								<TableCell component="th" scope="row">
									{booking.hostel.name}
								</TableCell>
								<TableCell>{booking.sharing_type}</TableCell>
								<TableCell align="right">{booking.room_no}</TableCell>
								<TableCell align="right">{booking.joining_date}</TableCell>
								<TableCell align="right">{booking.is_active ? "Active" : "In Active"}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table> :
				"There is no bookings available to show" 
			}
		</TableContainer>
	);
}

function Reviews(props) {
	const { classes, reviews } = props;

	return (
		<Grid container spacing={5} className={classes.hostelReviewContainer}>

			{ reviews && reviews.length > 10 ?
				<Grid item xs={6} 
					className={classes.viewAllReviews}
				> 
					<Link 
						// to={`/hostel/${hostelUUID}/tenants/`} 
						// className={classes.hostelTenantList}
					>
						<Button 
							size="medium" 
							color="secondary"
							variant="contained"
							className={classes.viewAllReviewButton}
						>
							VIEW ALL
						</Button>
					</Link> 
				</Grid> : ""
			}

		{ reviews && reviews.length > 0 ? 

			<Grid container >
				{reviews.map((review) => (
					<Grid item xs={12} className={classes.hostelReview} key={review.id}>
						<Grid item xs={12} >
							{review.hostel.name} <br></br>
							{/* <Typography variant="body2"> {review.created_on} </Typography> */}

							<Rating name="read-only" value={review.rating ? review.rating : 0 } readOnly />
						</Grid>
	
						<Grid item xs={12} key={review.id} 
							style={{
								paddingTop: "15px"
							}}> 
							{review.review} <br></br>
							({review.created_on})
						</Grid>
	
						<hr></hr>
					</Grid>
				))}
			</Grid> : 
			<Grid container justify="center" key="no-reviews" 
				style={{
					paddingTop: "15px"
				}}
			> 
				There are no reviews added by you.
			</Grid>
		
		}


		</Grid>
	)
}

function PaymentHistory(props) {

	const { payments, classes } = props;

	return (
		<TableContainer>
			{ payments && payments.length > 0 ? 
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Hostel</TableCell>
							<TableCell align="right">Amount</TableCell>
							<TableCell align="right">Status</TableCell>
							<TableCell align="right">Payed On</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{payments.map((payment) => (
							<TableRow key={payment.id}>
								<TableCell component="th" scope="row">
									{payment.hostel.name}
								</TableCell>
								<TableCell align="right">{payment.amount}</TableCell>
								<TableCell align="right">{payment.status}</TableCell>
								<TableCell align="right">{payment.created_on}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table> :
				"There is no payments available to show." 
			}
		</TableContainer>
	);
}

function MyAccountComponent(props) {

	const { history, userUUID, classes, activeTab } = props;

	const [ tabValue, setTabValue ] = useState(activeTab);

	const [ error, setError ] = useState(null);
	const [ reviews, setReviews ] = useState([]);
	const [ payments, setPayments ] = useState([]);
	const [ bookings, setBookings ] = useState([]);

	const [ message, setMessage ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ showNotification, setShowNotification ] = useState(false);

	useEffect(() => {

		setIsLoading(true);

		// Scroll window to top on landing for the first time
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});

		document.title = 'Hostlr - Profile'

		const url = `${config.BASE_APP_URL}/user/account/`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setReviews(result.reviews);
					setPayments(result.payments);
					setBookings(result.bookings);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setError(result.message);

					if(result.status_code === 401) {
						history.push("/login/");
					} else {
						setShowNotification(true);
					}
				}
				setIsLoading(false);
			},
			(error) => {
				setIsSuccess(false);
				setMessage(error);
				setShowNotification(true);
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)

	}, [userUUID]); // eslint-disable-line react-hooks/exhaustive-deps


	const handleChange = (event, newValue) => {
		setTabValue(newValue);
	};


	return (
		<div className={classes.page}>

			<AppBar 
				position="static"
				className={classes.tabAppBar}
			>
				<Tabs
					variant="fullWidth"
					value={tabValue}
					onChange={handleChange}
					aria-label="nav tabs example"
				>
					<LinkTab 
						className={classes.tabLink}
						label="Bookings" 
						href="/my-account/bookings/" {...a11yProps(0)} 
					/>
					<LinkTab 
						className={classes.tabLink}
						label="Reviews" 
						href="/my-account/reviews/" {...a11yProps(1)} 
					/>
					<LinkTab 
						className={classes.tabLink}
						label="payments" 
						href="/my-account/payments/" {...a11yProps(2)} 
					/>
				</Tabs>
			</AppBar>
			<TabPanel 
				value={tabValue} 
				index={0}
			>
				<BookingHistory
					bookings={bookings}
					classes={classes}
				/>
			</TabPanel>

			<TabPanel 
				value={tabValue} 
				index={1}
			>
				<Reviews
					reviews={reviews}
					classes={classes}
				/>
			</TabPanel>

			<TabPanel 
				value={tabValue} 
				index={2}
			>
				<PaymentHistory
					payments={payments}
					classes={classes}
				/>
			</TabPanel>

			{ isLoading ? <SimpleBackdrop open={true} /> : <SimpleBackdrop open={false} /> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>

			}

		{ error ? 
			<div> {error} </div> : ""
		}

		</div>
	)
}

function MyAccountPage(props) {

	const classes = useStyles();
	const { history } = props;

	const userUUID = props.match.params.uuid;
	const activeTab = props.location.activeTab;

	return (
		<div className={classes.root}>
			<Header/>
			<MyAccountComponent 
				history={history} 
				userUUID={userUUID} 
				classes={classes}
				activeTab={ activeTab ? activeTab : 0 }
			/>
			<Footer/>
		</div>
	);
}

export default MyAccountPage;