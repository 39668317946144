import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
// import { useSelector, useDispatch } from "react-redux";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

// import { Link } from "react-router-dom";

import { Grid } from '@material-ui/core';

// API Calls
// import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
// import config from "../../config.json";

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh"
	},

	page: {
		minHeight: "80vh",
		paddingBottom: "10%",
		background: "#f0f8ff"
	},

	header: {
		marginLeft: "4% !important"
	},

}));

function PrivacyPolicy(props) {

	const { classes } = props;
	const [isLoading, setIsloading] = useState(true);

	useEffect(() => {
		document.title = 'Hostlr - Privacy Policy'
		setIsloading(false);
	});

	return (
		<div className={classes.page}>
			<Grid container>
				Comming Soon... <br></br>
			</Grid>

			{ isLoading? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }
			
		</div>
	)
}

function PrivacyPolicyPage(props) {

	const classes = useStyles();
	const { history } = props;

	return (
		<div className={classes.root}>
			<Header/>
			<PrivacyPolicy 
				history={history} 
				classes={classes}
			/>
			<Footer/>
		</div>
	);
}

export default PrivacyPolicyPage;