import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const images = [
	{
		url: '/static/images/grid-list/breakfast.jpg',
		title: 'Breakfast',
		width: '40%',
	},
	{
		url: '/static/images/grid-list/burgers.jpg',
		title: 'Burgers',
		width: '30%',
	},
	{
		url: '/static/images/grid-list/camera.jpg',
		title: 'Camera',
		width: '30%',
	},
	];

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		// minWidth: 300,
		width: '100%',
		height: "100%"
	},
	image: {
		height: "100% !important",
		width: "100% !important",
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			width: '100% !important', // Overrides inline-style
			height: 100,
		},
		'&:hover, &$focusVisible': {
			zIndex: 1,
			'& $imageBackdrop': {
				opacity: 0.15,
			},
			'& $imageMarked': {
				opacity: 0,
			},
			'& $imageTitle': {
				border: '4px solid currentColor',
			},
		},
	},
	focusVisible: {},
	imageButton: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: theme.palette.common.white,
	},
	imageSrc: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundSize: 'cover',
		backgroundPosition: 'center 40%',
	},
	imageBackdrop: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: theme.palette.common.black,
		opacity: 0.4,
		transition: theme.transitions.create('opacity'),
	},
	imageTitle: {
		position: 'relative',
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
	},
	imageMarked: {
		height: 3,
		width: 18,
		backgroundColor: theme.palette.common.white,
		position: 'absolute',
		bottom: -2,
		left: 'calc(50% - 9px)',
		transition: theme.transitions.create('opacity'),
	},
}));

function ComplexButton(props) {
	const classes = useStyles();
	const { image, setOpen } = props;

	function handleClick(event) {
		setOpen(true);
	}

	return (
		<div className={classes.root}>
			{ image ? 
				<ButtonBase
					focusRipple
					key={image.id}
					className={classes.image}
					focusVisibleClassName={classes.focusVisible}
					onClick={handleClick}
					// style={{
					// 	width: image.width,
					// }}
				>
					<span
						className={classes.imageSrc}
						style={{
							backgroundImage: `url(${image.image})`,
						}}
					/>
					<span className={classes.imageBackdrop} />
					<span className={classes.imageButton}>
						<Typography
							component="span"
							variant="subtitle1"
							color="inherit"
							className={classes.imageTitle}
						>
							SEE ALL
							<span className={classes.imageMarked} />
						</Typography>
					</span>
				</ButtonBase> : ""  
			}
		</div>
	);
}

export default ComplexButton;
