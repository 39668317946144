import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},

	alert: {
		minWidth: "250px"
	},

	notificationAlert: {
		marginTop: "55px"
	}
}));

function ToastNotification(props) {

	const classes = useStyles();
	const { message, isSuccess, open, setOpen } = props;
	const vertical = 'top';
	const horizontal = 'right';

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<div className={classes.root}>
			<Snackbar 
				className={classes.notificationAlert}
				open={open} 
				onClose={handleClose}
				autoHideDuration={6000} 
				key={vertical + horizontal}
				anchorOrigin={{ vertical, horizontal }}
			>
				<Alert 
					className={classes.alert}
					onClose={handleClose} 
					severity={ isSuccess ? "success" :  "error" }
				>
					{message}
				</Alert>
			</Snackbar>
		</div>
	);
}


export default ToastNotification;