import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
// import Button from '@material-ui/core/Button';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// Redux'
import { useSelector } from "react-redux";
// import { setHostels, setCurrentCity } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';
import ToastNotification from '../../components/CommonFunctions/ToastNotification';

// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";
import TextField from '@material-ui/core/TextField';

import {
	Grid,
	Button,
	Typography
} from '@material-ui/core';

// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// SCSS
import  "../../styles/Common.scss";

const useStyles = makeStyles((theme) => ({

	root: {
		margin: "2px",
		width: "100%",
		overflow: "hidden",
		minHeight: "80vh"
	},

	page: {
		minHeight: "80vh",
		paddingBottom: "10%",
		background: "#f0f8ff"
	},

	header: {
		marginLeft: "4% !important"
	},
	
	container: {
		// maxHeight: "",
		background: "white",
		marginTop: "80px"
	},

	actionButton: {
		borderRadius: 20,
		marginRight: 10
	},

	// Modal
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},

	paper: {
		minWidth: "300px",
		minHeight: "100px",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

	formField: {
		marginTop: "10px",
		marginBottom: "10px"
	}

}));

const columns = [
	{ 
		id: 'name', 
		label: 'Name', 
		minWidth: 75
	}, { 
		id: 'hostel', 
		label: 'Hostel', 
		minWidth: 75
	}, {
		id: 'sharingType',
		label: 'Sharing Type',
		minWidth: 75,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	}, {
		id: 'gender',
		label: 'Gender',
		minWidth: 75,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'joiningDate',
		label: 'Joining Date',
		minWidth: 75,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'requestedOn',
		label: 'Requested Date',
		minWidth: 75,
		align: 'right',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'modifiedOn',
		label: 'Request Accepted/Rejected On',
		minWidth: 75,
		align: 'center',
		format: (value) => value.toLocaleString('en-US'),
	},
	{
		id: 'action',
		label: 'Action',
		minWidth: 100,
		align: 'center',
		format: (value) => value.toLocaleString('en-US'),
	}
];


function AcceptOrRejectTenantRequest(props) {

	const classes = useStyles();
	const { open, setOpen, action, tenantRequestID, title, setTenantRequests, setIsSuccess, setShowNotification, setToastMessage, isRoomNumberRequired } = props;

	const [ state, setState ] = useState({
		roomNumber: "",
		isRoomNumberSet: false
	});

	const handleClose = () => {
		setOpen(false);
	};

	function handleTenantRequest() {

		if(action === "accept" && isRoomNumberRequired && state.roomNumber === "" ){
			setState({
				...state,
				isRoomNumberSet: false
			})
			return false
		}

		const data = {
			action: action,
			room_number: state.roomNumber,
			tenant_request_uuid: tenantRequestID
		}

		const url = `${config.BASE_APP_URL}/tenants/joining-request/`;
		const options = {
			method:'put',
			body:JSON.stringify(data),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setTenantRequests(result.tenant_requests)
					setIsSuccess(true);
					setShowNotification(true);
					setOpen(false);
					setToastMessage(result.message);

				} else{
					setToastMessage(result.message);
					setIsSuccess(false);
					setShowNotification(true);
					setOpen(false);
				}
			},
			(error) => {
				setIsSuccess(false);
				setToastMessage('Something went wrong!');
				setShowNotification(true);
				setOpen(false);
			}
		)
	}

	return (
		<Modal
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<Typography variant="body1"> 
						{title}
					</Typography>
					<br></br>

					<Grid container justify="center" spacing={0}>

						{ isRoomNumberRequired && action === "accept" ?  
							<Grid item xs={12}>
								<TextField 
									className={classes.formField}
									error={state.isRoomNumberSet ? false : true}
									variant="outlined"
									margin="dense"
									required
									fullWidth
									id="rommNumber"
									label="Room Number"
									name="roomNumber"
									autoFocus
									value={state.roomNumber}
									onInput = { 
										event => setState({
											...state,
											roomNumber: event.target.value,
											isRoomNumberSet: true
										})
									}
									helperText={state.isRoomNumberSet ? "" : "This is required field!"}
								/>
							</Grid> : "" 
						}

						<Grid item xs={5}>
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								className="acceptButton"
								onClick={handleTenantRequest}
							>
								{action.toUpperCase()}
							</Button>

						</Grid>

						<Grid item xs={2}></Grid>

						<Grid item xs={5}>
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								className="cancelButton"
								onClick={handleClose}
							>
								CANCEL
							</Button>

						</Grid>
					</Grid>

				</div>	
			</Fade>

      </Modal>
	)
}



function TenantRequests(props) {

	const { classes } = props;
	const [page, setPage] = useState(0);
	const [error, setError ] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [tenantRequests, setTenantRequests] = useState([]);
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState(false);
	const [tenantRequestID, setTenantRequestID] = useState(false);
	const [action, setAction] = useState(false);
	const [showNotification, setShowNotification] = useState(false);
	const [toastMessage, setToastMessage] = useState(null);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isRoomNumberRequired, setIsRoomNumberRequired] = useState(false);

	const userDetail = useSelector(state => state.user.currentUser);

	const _isMounted = useRef(true);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		// Update Header Title
		document.title = 'Hostlr - Tenant Requests'

		const url = `${config.BASE_APP_URL}/tenants/joining-request/`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					if (_isMounted.current) {
						setIsLoading(false);
						setTenantRequests(result.tenant_requests)
					}

				} else{
					if (_isMounted.current) {
						setError(result.message);
						setIsLoading(false);
					}
				}
			},
			(error) => {
				if (_isMounted.current) {
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)
		
		return () => { _isMounted.current = false }

	}, []);


	function acceptTenantRequest(tenantRequestID, action, title) {
		setTenantRequestID(tenantRequestID);
		setAction(action);
		setMessage(title)
		setOpen(true);
		setIsRoomNumberRequired(true);
	}


	return (
		<div className={classes.page}>

			{ !isLoading ? 
				<TableContainer className={classes.container}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
						{tenantRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
							return (
							<TableRow hover tabIndex={-1} key={row.id}>
								{columns.map((column) => {
									let value =  ""

									if(column.id === "name") {
										value =  row.user_profile.user.name;
									} else if(column.id === "hostel") {
										value =  row.hostel.name;
									} else if(column.id === "sharingType") {
										value =  row.sharing_type;
									} else if(column.id === "gender") {
										value =  row.user_profile.gender_title;
									} else if(column.id === "joiningDate") {
										value =  row.joining_date;
									} else if(column.id === "requestedOn") {
										value = row.created_on;
									} else if(column.id === "modifiedOn") {
										value = row.is_request_accepted || row.is_request_rejected ? row.modified_on : "";
									}

									return (
										<TableCell key={column.id} align={column.align}>
											{column.id === "action" ?
												<div>
													{ userDetail && userDetail.is_tenant ? 
													
														<div> 
															{ row.is_request_accepted ? 

																<div>
																	{ row.has_joined_hostel ? 
																		<Button 
																			className="button"
																			variant="contained" 
																			size="small" 
																			color="primary"
																		>
																			Joined Hostel
																		</Button>  : 
																		<Link
																			to={`/hostel-booking/${row.uuid}/`}
																			className="noDecoration"
																		>
																			<Button 
																				className="button"
																				variant="contained" 
																				size="small" 
																				color="primary"
																				disabled={row.has_joined_hostel}
																			>
																				BOOK NOW
																			</Button>
																		</Link>
																	}
																</div> : "" 
															}

															{ row.is_request_rejected ? 
																<Button 
																	className="button"
																	variant="contained" 
																	size="small" 
																	color="secondary"
																>
																	{ row.request_rejected_by ? "REJECTED" : "AUTO REJECTED" }
																</Button> : "" 
															}

															{ !row.is_request_accepted && !row.is_request_rejected ? 
															
																<Button 
																	className="button"
																	variant="contained" 
																	size="small" 
																	color="primary"
																>
																	PENDING
																</Button> : "" 
															}
														</div> : 

														<div>
															{ row.is_request_accepted ? 
																<Button 
																	className="button"
																	variant="contained" 
																	size="small" 
																	color="primary"
																>
																	ACCEPTED
																</Button> : "" 
															}
		
															{ row.is_request_rejected ? 
																<Button 
																	className="button"
																	variant="contained" 
																	size="small" 
																	color="secondary"
																>
																	{ row.request_rejected_by ? "REJECTED" : "AUTO REJECTED" }
																</Button> : "" 
															}
		
															{ !row.is_request_accepted && !row.is_request_rejected ? 
																<div>
																	<Tooltip title="Accept">
																		<IconButton aria-label="accept"
																			onClick={() => {
																				acceptTenantRequest(row.uuid, "accept", "Are you sure you want to accept this request");
																			
																			}}
																		>
																			<CheckIcon 
																				color="primary"
																			/>
																		</IconButton>
																	</Tooltip>
		
																	<Tooltip title="Reject">
																		<IconButton aria-label="reject"
																			onClick={() => {
																				acceptTenantRequest(row.uuid, "reject", "Are you sure you want to reject this request");
																			
																			}}
																		>
																			<HighlightOffIcon 
																				color="secondary"
																			/>
																		</IconButton>
																	</Tooltip> 
																</div> : "" 
															}

														</div>

													}

												</div> : 
												column.id === 'name' ?
												<Link
													to={`/user-profile/${row.user_profile.uuid}/`}
												>
													{value}
												</Link> : value
											}
										</TableCell>
									);
								})}
							</TableRow>
							);
						})}
						</TableBody>
					</Table>
					{ tenantRequests && tenantRequests.length > 15 ? 
						<TablePagination
							rowsPerPageOptions={[15, 25, 100]}
							component="div"
							count={tenantRequests.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/> : ""
					}
				</TableContainer> : 
				<Grid container alignItems="center">
					There is no tenant requests to show.
				</Grid> 
			}

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false} /> }

			{ error ? error : "" }

			{ open ? 
				<AcceptOrRejectTenantRequest
					open={true}
					setOpen={setOpen}
					title={message}
					tenantRequestID={tenantRequestID}
					action={action}
					setTenantRequests={setTenantRequests}
					setShowNotification={setShowNotification}
					setToastMessage={setToastMessage}
					setIsSuccess={setIsSuccess}
					isRoomNumberRequired={isRoomNumberRequired}
				/> : ""
			}

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={toastMessage} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={toastMessage} 
					isSuccess={isSuccess}
				/>
			
			}

		</div>
	)
}


function TenantRequestPage(props) {

	const classes = useStyles();
	const { history } = props;

	return (
		<div className={classes.root}>
			<Header/>
			<TenantRequests 
				history={history} 
				classes={classes}
			/>
			<Footer/>
		</div>
	);
}

export default TenantRequestPage;