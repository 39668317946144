import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
// import LocationOnIcon from '@material-ui/icons/LocationOn';
// import HomeIcon from '@material-ui/icons/Home';
// import WcIcon from '@material-ui/icons/Wc';
// import PersonIcon from '@material-ui/icons/Person';
// import Rating from '@material-ui/lab/Rating';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

// import ImageCarousel from "../../components/BannerCarousal/ImageCarousel";

// import hostlr_1 from "../../assets/images/hostlr_1.jpg";

// Redux'
// import { useSelector, useDispatch } from "react-redux";
// import { setHostels, setCurrentCity } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

// import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
	Card,
	Typography,
	Grid,
	Box,
	Avatar,
	LinearProgress,
	Divider
} from '@material-ui/core';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh"
	},

	page: {
		minHeight: "100vh",
		paddingBottom: "10%",
		background: "#f0f8ff"
	},

	header: {
		marginLeft: "4% !important"
	},

	tenants: {
		width: "98%",
		marginLeft: "1%",
		marginTop: "2%"
	},

	hostelName: {
		color: "#ff0000"
	},

	tenantAvatar: {
		width: theme.spacing(12),
		height: theme.spacing(12),
	},

	tenantName: {
		marginTop: "5%"
	},

	viewProfileButton: {
		borderRadius: 10,
	},

	tableContainer: {
		background: "#f0f8ff",
		padding: "10px"
	},

	tableRightColumn: {
		display: "flex",
		justifyContent: "flex-end"
	},

	hostel: {
		flexGrow: 1,
		background: "white",
		marginBottom: "50px"
	},

	hostelCard: {
		color: "black",
		textDecoration: "none"
	},

	hostelInfo : {
		marginLeft: 20
	},

	hostelRelatedInfo: {
		paddingTop: "55px"
	},
	
	// Card Medias
	card: {
		maxWidth: 300,
		marginBottom: 10
	},

	media: {
		marginLeft: "25%",
		top: "15px",
		height: 150,
		width: "50%",
		borderRadius: "50%",
		position: "relative"
	},

	hostelMedia: {
		height: 250,
		position: "relative"
	},

	iconWithText: {
		paddingTop: "2px",
		paddingRight: "10px"
	},

	rent: {
		marginLeft: "15px"
	},

	perMonthText: {
		padding: "12%",
		fontSize: "12px",
		color: "#3f51b5",
		fontWeight: 800
	},

	rentInformation: {
		marginTop: "150px",
	},

	addTenantButton: {
		width: "180px",
		padding: "10px",
		borderRadius: 30,
		margin: theme.spacing(1)
	},

	addNewTenant: {
		color: "white",
		textDecoration: "none"
	},

	userProfileLink: {
		color: "white",
		width: "100%",
		textDecoration: "none"
	},

	addButtonGrid: {
		display: "flex",
		justifyContent: "flex-end"
	}
	
}));


function getUserCharacters(userName) {

	let matches = userName.match(/\b(\w)/g);
	let displayName = matches.join('');

	return displayName

}

function TenantCard(props) {

	const { tenantList, hostel } = props;
	const classes = useStyles();


	return (
		tenantList.map((tenant, index) => {
			return (
				<Grid item xs={12} sm={6} md={4} key={index}>
					
					<Card raised key={index}>
						<CardContent key={index}>
							<Grid container spacing={1}>
								<Grid item xs={4}>
									<Avatar className={classes.tenantAvatar} alt={tenant.user_profile.user.name} src={tenant.user_profile.user_image} >
										{ tenant.user_profile.user_image ? "" : getUserCharacters(tenant.user_profile.user.name)}
									</Avatar>
								</Grid>
								<Grid item xs={8}>
									<Typography variant="h5" component="h2" className={classes.tenantName}>
										{tenant.user_profile.user.name} <br></br>
										<Box display="flex" alignItems="center">
											<Box width="100%" mr={1}>
												<LinearProgress variant="determinate" value={20} />
											</Box>
											<Box minWidth={35}>
												<Typography variant="body2" color="textSecondary">20%</Typography>
											</Box>
										</Box>
									</Typography>
								</Grid>
							</Grid>

							<br></br>
							<Divider/>
							<br></br>

							<Grid container spacing={1} className={classes.tableContainer}>
								
								<Grid item xs={6}>
									<Typography variant="body2">
										Hostel
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{ hostel ? hostel.name : "Fetching Detail..." }
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography variant="body2">
										Room No
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{ tenant.room_no }
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography variant="body2">
										Contact Number
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{ tenant.user_profile.mobile_number ? tenant.user_profile.mobile_number : "Not Available" }
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography variant="body2">
										Joined On
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{tenant.joining_date}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography variant="body2">
										Last Rent Paid
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{ tenant.last_rent_paid ? tenant.last_rent_paid : "Info Not Available"}
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<Typography variant="body2">
										Current Resident
									</Typography>
								</Grid>
								<Grid item xs={6} className={classes.tableRightColumn}>
									<Typography variant="body2">
										{tenant.is_active ? "Active" : "Not Active" }
									</Typography>
								</Grid>

							</Grid>

						</CardContent>
						<CardActions>
							<Link 
								className={classes.userProfileLink}
								to={`/user-profile/${tenant.user_profile.uuid}`} 
							>
								<Button 
									fullWidth
									size="small" 
									color="primary"
									className={classes.viewProfileButton}
									variant="contained" 
								>
									SEE FULL PROFILE
								</Button>
							</Link>
						</CardActions>
					</Card>

				</Grid>
			)
		})
	)
}

function TenantListing(props) {

	const classes = useStyles();
	const { history, hostelUUid } = props;
	const [error, setError ] = useState(null);
	const [ tenants, setTenants ] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [ hostel, setHostel ]= useState(null);

	const _isMounted = useRef(true);

	// Redux state update
	// const [ state, setState ] = useSelector(state => state);

	// const dispatch = useDispatch();
	// const hostels = useSelector(state => state.hostel.hostels);
	
	useEffect(() => {
		// Update Header Title
		document.title = `Hostlr - Hostels`;

		const url = `${config.BASE_APP_URL}/tenants/?uuid=${hostelUUid}`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					if (_isMounted.current) {
						setIsLoading(false);
						setTenants(result.tenants);
						setHostel(result.hostel);
					}

				} else{
					if (_isMounted.current) {
						setError(result.message);
						setIsLoading(false);
						if(result.status_code === 403){
							history.push('/error403/');
						}

					}
				}
			},
			(error) => {
				if (_isMounted.current) {
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			}
		)
		
		return () => { _isMounted.current = false }
		// eslint-disable-next-line
	}, []);

	return (
		<div className={classes.page}>

			{ isLoading ? 
				<Grid container direction="row" alignItems="center">
					<Grid item xs={12}>
						Fetching Tenants ...
					</Grid>
				</Grid> : 
				<Grid container direction="row" alignItems="center">
					<Grid item xs={7} md={8}>
						<Typography variant="h4" className={classes.header}>
							Tenants in <span className={classes.hostelName}> { hostel ? hostel.name : "" }</span>
						</Typography>
					</Grid>
					<Grid item xs={5} md={4} className={classes.addButtonGrid}>
						<Link to="/add-tenant/" className={classes.addNewTenant}>
							<Button 
								size="medium" 
								variant="contained" 
								color="secondary" 
								className={classes.addTenantButton}
								startIcon={<AddIcon />}
							>
								ADD TENANT
							</Button>
						</Link>
					</Grid>
				</Grid>
			}

			{ isLoading ? 
				"" : 
				<Grid container spacing={2} className={classes.tenants}>

					{ tenants && tenants.length > 0 ? 
						<TenantCard tenantList={tenants} hostel={hostel}/> :
						// <div>
						// 	<Grid item xs={12} key="filterContainer">
						// 		<Grid item xs={6} sm={4} md={3} key="activeTenants">
						// 			Active
						// 		</Grid>
						// 	</Grid>
						// 	<TenantCard tenantList={tenants} hostel={hostel}/> 
						// </div> : 

						<Grid item xs={12}>
							<Typography variant="h5">
								There is no tenant added in the hostel, Add tenant to check here.
							</Typography>
						</Grid>

					}

				</Grid>
			}

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false} /> }

			{ error ? 
				<Grid container spacing={5}>
					{error}
				</Grid> : ""
			}
		</div>
	)
}

function HostelTenantPage(props) {

	const classes = useStyles();
	const { history } = props;

	const hostelUUid = props.match.params.uuid;

	return (
		<div className={classes.root}>
			<Header/>
			<TenantListing history={history} hostelUUid={hostelUUid}/>
			<Footer/>
		</div>
	);
}

export default HostelTenantPage;