const hostelDefaultState = {
    hostels: [],
    currentCity: {},
    currentHostel: {}
}

function hostelReducer(state = hostelDefaultState, action) {

    switch(action.type) {
        case "SET_HOSTELS":
            return {
                ...state,
                hostels: action.payload
            }

        case "SET_CURRENT_CITY":
            return {
                ...state,
                currentCity: action.payload
            }

        case "CURRENT_HOSTEL":
            return {
                ...state,
                currentHostel: action.payload
            }

        default:
            return {
                ...state,
                hostels: [],
                currentCity: {},
                currentHostel: {}
            }
    }
}

export default hostelReducer;