import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

// import defaultImage from "../../assets/images/default.png"

// import WcIcon from '@material-ui/icons/Wc';
import PersonIcon from '@material-ui/icons/Person';
import Button from '@material-ui/core/Button';
import Rating from '@material-ui/lab/Rating';
import Avatar from '@material-ui/core/Avatar';

import ImageViewer from "../../components/Dialogs/ImageViewer";
import ComplexButton from "../../components/CommonFunctions/ComplexButton";

// Floating Edi Button
import Fab from '@material-ui/core/Fab';
import EditIcon from '@material-ui/icons/Edit';
import { WifiIcon, BroomIcon } from 'react-line-awesome';

// Facilities Icons
// import WifiIcon from '@material-ui/icons/Wifi';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import KitchenIcon from '@material-ui/icons/Kitchen';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import SmokeFreeIcon from '@material-ui/icons/SmokeFree';
// import LayersClearIcon from '@material-ui/icons/LayersClear';
import TvIcon from '@material-ui/icons/Tv';
import PowerIcon from '@material-ui/icons/Power';
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService';
// import BathtubIcon from '@material-ui/icons/Bathtub';
import InfoIcon from '@material-ui/icons/Info';
import ToastNotification from "../../components/CommonFunctions/ToastNotification";

// Redux'
import { useSelector } from "react-redux";
// import { setHostels, setCurrentCity } from "../../actions";
import SimpleBackdrop from '../../components/CommonFunctions/SimpleBackdrop';

// import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import {
	Typography,
	Grid
} from '@material-ui/core';

// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

import { Bathtub } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';

import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
  } from '@material-ui/pickers';


// Modal
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

// DropDown
import Autocomplete from '@material-ui/lab/Autocomplete';

// SCSS
import  "../../styles/Common.scss";

import Alert from '@material-ui/lab/Alert';


const useStyles = makeStyles((theme) => ({

	root: {
		width: "100%",
		overflow: "hidden",
		minHeight: "100vh",
		background: "#f0f8ff"
	},

	page: {
		minHeight: "90vh",
		paddingTop: "2%",
		marginLeft: "2%",
		width: "96%",
		paddingBottom: "10%",
		// background: "white"
	},

	detailContainer: {
		margin: "10px",
		background: "white"
	},

	tenantListingPageButton: {
		justifyContent: "flex-end",
		display: "flex"
	},

	hostelAddress: {
		fontWeight: "300 !important",
		marginLeft: "15px",
		marginTop: "10px"
	},

	hostelDetail: {
		background: "white"
	},

	floatingEditButton: {
		top: "50%",
		left: "80%",
		position: "fixed",
		zIndex: 20,

		[theme.breakpoints.up("sm")]: {
			left: "90%"
        },
	},

	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: '#fff',
		opacity: 1
	},

	header: {
		marginLeft: "2% !important"
	},

	hostelName: {
		color: "#ff0000"
	},

	addHostelButton: {
		width: "180px",
		height: "45px",
		borderRadius: 30,
		margin: theme.spacing(1)
	},

	hostelTenantList: {
		color: "white",
		textDecoration: "none"
	},

	imageViewerButton: {
		height: "100px",
		width:"100%",
		background: "red"
	},

	hostelRoomAvailability: {
		marginTop: "50px",
		background: "white",
		paddingBottom: "20px"
	},

	hostelImages: {
		marginTop: "50px",
		// paddingTop: "50px",
		// paddingBottom: "50px",
		// background: "white"
	},

	image0: {
		margin: "10px",
		height: "500px",
		cursor: "pointer",
		backgroundSize: 'cover',
	},

	image1: {
		// margin: "10px",
		marginTop: "10px !important",
		// marginLeft: "20px !important",
		height: "245px",
		cursor: "pointer",
		backgroundSize: 'cover',
	},

	image2: {
		marginTop: "10px",
		// margin: "10px",
		height: "245px",
		backgroundSize: 'cover',

		// [theme.breakpoints.up("md")]: {
		// 	marginLeft: "10px",
        // },

	},

	image3: {
		margin: "10px",
		height: "70px",
		backgroundSize: 'cover',

	},

	hostelFacilities: {
		background: "white",
		marginTop: "50px"
	},

	hostelDescription: {
		background: "white",
		marginTop: "50px"
	},

	hostelReviewContainer: {
		background: "white",
		marginTop: "50px",
		paddingBottom: "50px"
	},

	hostelReviews: {
		marginTop: "30px",
		margin: "10px"
	},

	hostelReview: {
		marginBottom: "20px"
	},

	whiteBackGround: {
		background: "white"
	},

	icon: {
		color: "#69b0e4",
		marginRight: "5px",
		fontSize: "30px"
	},

	viewAllReviews: {
		justifyContent: "flex-end",
		display: "flex",
		paddingTop: "0 !important",
	},

	viewAllReviewButton: {
		background: "black !important",
		color: "white",
		textDecoration: "none",
		borderRadius: 20
	},

	reviewerName: {
		marginLeft: 0,
		// marginTop: 10
	},

	reviewForm: {
		width: "96%",
		marginLeft: "2%"	
	},

	formField: {
		padding: 15
	},

	hostelPriceTag: {
		marginTop: "20px",
		border: "2px solid red",
		// height: "350px",

		// [theme.breakpoints.up("sm")]: {
		// 	marginTop: "100px"
        // },
	},

	bookingForm: {
		width: "100%",
		padding: "20px"
	},

	calender: {
		width: "100%"
	},

	zeroPadding: {
		padding: "0px !important",
		paddingBottom: "5px !important",
		paddingLeft: "20px !important"
	},

	topPadding: {
		paddingTop: "0px !important"
	},

	applyButton: {
		padding: "10px",
		borderRadius: 30
	},

	alreadyApplyButton: {
		color: "white",
		padding: "10px",
		borderRadius: 30,
		background: "black !important",
	},

	// Modal
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		minWidth: "300px",
		minHeight: "100px",
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},

	subHeading: {
		paddingTop: "0px",
		paddingLeft: "25px",
		fontSize: "20px",
		color: "#2e49e0",
		// color: "#ed143d"
	},

	subHeading2: {
		paddingTop: "5px",
		paddingLeft: "25px",
		fontWeight: 300,
		fontSize: "15px"
	}
	
}));


function getUserCharacters(user) {

	if(user && user.user_image) {
		return user.user_image;
	} else {
		let matches = user.name.match(/\b(\w)/g);
		let displayName = matches.join('');
		return displayName
	}

}


function MaterialUIPickers(props) {

	const classes = useStyles();
	const { selectedDate, setSelectedDate } = props;

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	var today = new Date();
	var maxDate = new Date()
	maxDate.setDate(today.getDate() + 60);

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				className={classes.calender}
				margin="normal"
				id="date-picker-dialog"
				minDate={new Date()}
				maxDate={maxDate}
				label="Start Date"
				format="dd/MM/yyyy"
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	);
}

function formatDate(date) {
	return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))  + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +  '/' + date.getFullYear();
}

function RedirectToLogin(props) {

	const classes = useStyles();
	const { open, setOpen, history, title } = props;

	const handleClose = () => {
		setOpen(false);
	};

	function handleLogin() {
		history.push('/login/');
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
						<Typography variant="body1"> 
							{title}
						</Typography>
						<br></br>

						<Grid container justify="center" spacing={0}>
							<Grid item xs={5}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className={classes.applyButton}
									onClick={handleLogin}
								>
									LOGIN
								</Button>

							</Grid>

							<Grid item xs={1}></Grid>

							<Grid item xs={5}>
								<Button
									fullWidth
									variant="contained"
									color="secondary"
									className={classes.alreadyApplyButton}
									onClick={handleClose}
								>
									CANCEL
								</Button>

							</Grid>
						</Grid>

					</div>	
				
			</Fade>


      </Modal>
	)
}

function HostelPriceTag(props) {

	const { hostel, classes, history, userDetail, hostelAlreadyBooked, setHostelAlreadyBooked, userProfile } = props;
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [ message, setMessage ] = useState(null);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ showNotification, setShowNotification ] = useState(false);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ openModal, setOpenModal ] = useState(false);

	const [ sharingTypeValue, setSharingTypeValue] = useState("");
	const [ inputSharingTypeValue, setInputSharingTypeValue] = useState("");
	const [sharingTypeRent, setSharingTypeRent] = useState(null);

	const [state, setState] = useState({
		sharingType: "",
		sharingTypeError: false,
		sharingTypeValue: sharingTypeValue,
		inputSharingTypeValue: inputSharingTypeValue
	})

	function getSharingTypeRent(selectedValue) {
		if(selectedValue && selectedValue.value === "SS") {
			setSharingTypeRent(hostel.single_sharing_rent)
		} else if(selectedValue && selectedValue.value === "DS") {
			setSharingTypeRent(hostel.double_sharing_rent)
		} else if(selectedValue && selectedValue.value === "TS") {
			setSharingTypeRent(hostel.triple_sharing_rent)
		} else {
			setSharingTypeRent(null);
		}
	}

	function joinHostel(event) {

		event.preventDefault();
		const url = `${config.BASE_APP_URL}/tenants/joining-request/`;

		if(state.sharingTypeError || state.sharingType === ""){
			setState({
				sharingTypeError: true
			})
			return false
		}

		setIsLoading(true);

		// By Default Sharing Type will be Single
		const hostelJoiningInfo = {
			"hostel": hostel.uuid,
			"sharingType": state.sharingType,
			"joiningDate": formatDate(selectedDate)
		};

		const options = {
			method:'POST',
			body:JSON.stringify(hostelJoiningInfo),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					setHostelAlreadyBooked(true);

					setTimeout(() => {
						setIsLoading(false);
						setShowNotification(false);
					}, 1000);

				} else{
					// setError(result.message);
					setIsLoading(false);
					setIsSuccess(false);
					setMessage(result.message);
				}

				setShowNotification(true);
			},
			(error) => {
				setIsLoading(false);
				setIsSuccess(false);
				setMessage('Something went wrong!');
				setShowNotification(true);
			}
		)
	}

	function handleModalOpen(event) {
		setOpenModal(true);
	}


	return (
		<Grid container spacing={0} justify="center" className={classes.hostelPriceTag}>

			{userProfile && !userProfile.address ?
				<Grid container spacing={5}>
					<Grid item xs={12}>
						<Alert severity="error">
							Not allowed to book hostel, since you don't have address added. <br></br>
							Please add your address for booking.
						</Alert>
					</Grid>
				</Grid> : ""
			}

			<form noValidate autoComplete="off" onSubmit={joinHostel} className={classes.bookingForm}>

				{ sharingTypeRent ?
					<Grid item xs={12}>
						&#x20B9; {sharingTypeRent}
						<span className="perMonthText">
							per Month
						</span> <br></br>
						<Rating name="read-only" value={hostel ? hostel.rating : 0 } readOnly />
					</Grid> : 
						<Grid item xs={12}>
						&#x20B9; {hostel.minimum_rent} - &#x20B9; {hostel.maximum_rent}
						<span
							style={{
								color: "#1135fb",
								marginLeft: "5px",
								fontSize: "12px"
							}}
						>
							per Month
						</span> <br></br>
						<Rating name="read-only" value={hostel ? hostel.rating : 0 } readOnly />
					</Grid>
				}

				<Grid item xs={12}>
					<Autocomplete
						id="sharingType"
						options={hostel.sharing_types}
						getOptionSelected={(option, value) => option.title === value.title}
						value={state.sharingTypeValue ? state.sharingTypeValue : sharingTypeValue}
						getOptionLabel={(option) => option ? option.title : ""}
						fullWidth
						onChange={(event, newValue) => {
							setSharingTypeValue(newValue);
							setState({
								...state,
								sharingTypeValue: newValue,
								sharingType: newValue ? newValue.value : "",
								sharingTypeError: false
							})
							getSharingTypeRent(newValue);
						}}
						inputValue={state.inputSharingTypeValue ? state.inputSharingTypeValue : inputSharingTypeValue}
						onInputChange={(event, newInputValue) => {
							setInputSharingTypeValue(newInputValue);
							setState({
								...state,
								inputSharingTypeValue: newInputValue,
							})
						}}
						renderInput={
							(params) => <TextField 
								{...params} 
								label="Sharing Type" 
								variant="outlined" 
								margin="dense"
								error={state.sharingTypeError ? true : false}
								helperText={state.sharingTypeError ? "This is required field" : ""}
							/>
						}
					/>
				</Grid>

				<Grid item xs={12}>
					<MaterialUIPickers
						selectedDate={selectedDate}
						setSelectedDate={setSelectedDate}
					/>
				</Grid>

				<br></br>

				{/* If your is logged in but does not have the address added 
				then he/she won't be able to book the hostel */}
				{ userDetail ? 
					<Grid item xs={12}>
						{ hostelAlreadyBooked ? 
							<Button
								fullWidth
								variant="contained"
								className={classes.alreadyApplyButton}
							>
								ALREADY APPLIED
							</Button> : 

							userProfile && !userProfile.address ?
							<Button
								fullWidth
								variant="contained"
								color="secondary"
								className={classes.applyButton}
							>
								NOT ALLOWED
							</Button> : 
							<Button
									type="submit"
									fullWidth
									variant="contained"
									color="secondary"
									className={classes.applyButton}
								>
									BOOK NOW
							</Button>
						}
					</Grid> : 
					
					<Grid item xs={12}>
						<Button
								fullWidth
								variant="contained"
								color="secondary"
								className={classes.applyButton}
								onClick={handleModalOpen}
							>
								BOOK NOW
						</Button>
					</Grid>
				}

				
			</form>
			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false} /> }

			{ showNotification ?
				<ToastNotification
					open={true}
					setOpen={setShowNotification}
					message={message}
					isSuccess={isSuccess}/> :

				<ToastNotification
					open={false}
					setOpen={setShowNotification}
					message={message}
					isSuccess={isSuccess}
				/>
			}

			<RedirectToLogin
				open={openModal}
				setOpen={setOpenModal}
				history={history}
				title="You need to login before booking"
			/>

		</Grid>
	)
}


function DisplayHostelImages(props) {

	const { images, classes, setIsImageViewerVisibile, hostel, userDetail, history, hostelAlreadyBooked, setHostelAlreadyBooked, userProfile } = props;

	function handleClick(event) {
		setIsImageViewerVisibile(true);
	}

	let isHostelOwner = false;

	if(userDetail) {
		isHostelOwner = userDetail.user.email === hostel.owner.email;
	} else {
		isHostelOwner = false;
	}

	if(images.length > 2) {
		return (
			<Grid container spacing={5} className={classes.hostelDetail}>
				<Grid item xs={12} sm={isHostelOwner ? 6 : 4}
					key={1}
					className={classes.image0}
					onClick={handleClick}
					style={{
						backgroundImage: `url(${images[0].image})`,
					}}
				/>

				<Grid item xs={12} sm={isHostelOwner ? 5 : 4}
					key={2}
					className={classes.zeroPadding}
				>
					<Grid
						item
						xs={12}
						className={classes.image1}
						onClick={handleClick}
						style={{
							backgroundImage: `url(${images[1].image})`,
						}}
					/>

					<Grid container spacing={0}>

						<Grid
							item
							xs={12}
							className={classes.image2}
						>
							<ComplexButton
								className={classes.imageViewerButton}
								image={images[2]}
								setOpen={setIsImageViewerVisibile}
							/>
						</Grid>

					</Grid>

				</Grid>

				{ isHostelOwner ? "" : 
					<Grid item xs={12} sm={3} md={3}>
						<HostelPriceTag
							hostel={hostel}
							classes={classes}
							history={history}
							userDetail={userDetail}
							hostelAlreadyBooked={hostelAlreadyBooked}
							setHostelAlreadyBooked={setHostelAlreadyBooked}
							userProfile={userProfile}
						/>
					</Grid> 
				}

			</Grid>
		)
	} else if(images.length > 1) {
		return (
			<Grid container spacing={5} className={classes.hostelDetail}>
				<Grid item xs={12} sm={isHostelOwner ? 6 : 4}
					key={1}
					className={classes.image0}
					onClick={handleClick}
					style={{
						backgroundImage: `url(${images[0].image})`,
					}}
				/>

				<Grid item xs={12} sm={isHostelOwner ? 5 : 4}
					key={2}
					className={classes.zeroPadding}
				>
					<Grid
						item
						xs={12}
						className={classes.image0}
						onClick={handleClick}
						style={{
							backgroundImage: `url(${images[1].image})`,
						}}
					>
						<ComplexButton
							className={classes.imageViewerButton}
							image={images[1]}
							setOpen={setIsImageViewerVisibile}
						/>
					</Grid>


				</Grid>

				{ isHostelOwner ? "" : 
					<Grid item xs={12} sm={3} md={3}>
						<HostelPriceTag
							hostel={hostel}
							classes={classes}
							history={history}
							userDetail={userDetail}
							hostelAlreadyBooked={hostelAlreadyBooked}
							setHostelAlreadyBooked={setHostelAlreadyBooked}
							userProfile={userProfile}
						/>
					</Grid> 
				}

			</Grid>
		)
	} else if(images.length === 1) {
		return (
			<Grid container spacing={5} className={classes.hostelDetail}>
				<Grid item xs={12} sm={isHostelOwner ? 12 : 8}
					key={1}
					className={classes.image0}
					onClick={handleClick}
					style={{
						backgroundImage: `url(${images[0].image})`,
					}}
				/>

				{ isHostelOwner ? "" : 
					<Grid item xs={12} sm={3} md={3}>
						<HostelPriceTag
							hostel={hostel}
							classes={classes}
							history={history}
							userDetail={userDetail}
							hostelAlreadyBooked={hostelAlreadyBooked}
							setHostelAlreadyBooked={setHostelAlreadyBooked}
							userProfile={userProfile}
						/>
					</Grid> 
				}

			</Grid>
		)
	} else {
		return (
			<Grid container spacing={5} className={classes.hostelDetail}>
				<Grid item xs={12} sm={isHostelOwner ? 12 : 8}
					key={1}
				>
					No Images added for the hostel
				</Grid>

				{ isHostelOwner ? "" : 
					<Grid item xs={12} sm={3} md={3}>
						<HostelPriceTag
							hostel={hostel}
							classes={classes}
							history={history}
							userDetail={userDetail}
							hostelAlreadyBooked={hostelAlreadyBooked}
							setHostelAlreadyBooked={setHostelAlreadyBooked}
							userProfile={userProfile}
						/>
					</Grid> 
				}

			</Grid>
		)
	}
}

function FacilityIcon(props) {

	const classes = useStyles();
	const { iconName } = props;

	if(iconName === "PersonIcon") {
		return <PersonIcon className={classes.icon}/>
	} else if(iconName === "AcUnitIcon") {
		return <AcUnitIcon className={classes.icon}/>
	} else if(iconName === "LocalLaundryServiceIcon") {
		return <LocalLaundryServiceIcon className={classes.icon}/>
	} else if(iconName === "KitchenIcon") {
		return <KitchenIcon className={classes.icon}/>
	} else if(iconName === "Bathtub") {
		return <Bathtub className={classes.icon}/>
	} else if(iconName === "WifiIcon") {
		return <WifiIcon className={classes.icon}/>
	} else if(iconName === "LocalParkingIcon") {
		return <LocalParkingIcon className={classes.icon}/>
	} else if(iconName === "LocalBarIcon") {
		return <LocalBarIcon className={classes.icon}/>
	} else if(iconName === "TvIcon") {
		return <TvIcon className={classes.icon}/>
	} else if(iconName === "SmokeFreeIcon") {
		return <SmokeFreeIcon className={classes.icon}/>
	}else if(iconName === "PowerIcon") {
		return <PowerIcon className={classes.icon}/>
	} else if(iconName === "RoomCleaning") {
		return <BroomIcon className={classes.icon}/>
	}  else {
		return <InfoIcon className={classes.icon}/>
	}
}


function HostelFacilities(props) {
	const { classes, facilities } = props;

	const hostelFacilities = facilities.filter(
		facility => facility.is_selected === true
	);

	return (
		<Grid container spacing={5} className={classes.hostelFacilities}>
			<Grid item xs={12}> 
				<span style={{
					color: "red"
				}}> AVAILABLE FACILITIES </span>
			</Grid>
			{hostelFacilities.map((facility) => (
				<Grid item xs={4} key={facility.id}> 
					<div style={{
						display: 'flex',
						alignItems: 'center',
						flexWrap: 'wrap',
					}}>
						<FacilityIcon
							iconName={facility.icon}
						/>
						<span>{facility.name}</span>
					</div> 
					{/* {facility.icon} */}
				</Grid>
			))}
		</Grid>
	)
}


function HostelReviews(props) {
	const { classes, reviews, setHostelReviews, rating, hostelUUID, userDetail, hostel, history } = props;

	const [ error, setError ] = useState(null);
	const [ message, setMessage ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ showNotification, setShowNotification ] = useState(false);
	const [ canAddReview, setCanAddReview ] = useState(true);
	const [ open, setOpen ] = useState(false);

	const [ review, setReview ] = useState({
		review: "",
		rating: 0
	});

	useEffect(() => {

		if(userDetail) {
			const isReviewAdded = reviews.filter(
				review => review.user_profile.uuid === userDetail.uuid
			);
		
			if(isReviewAdded && isReviewAdded.length > 0){
				setCanAddReview(false)

			} else if(hostel && hostel.owner && userDetail.user.email === hostel.owner.email) {
				setCanAddReview(false);

			} else {
				setCanAddReview(true)	
			}
		}

	}, [reviews, hostel, userDetail]);

	function handleReviewSubmission(event) {
		event.preventDefault();
		setIsLoading(true);

		const url = `${config.BASE_APP_URL}/hostels/reviews/`;

		const data = {
			review: review.review,
			rating: review.rating,
			hostel_uuid: hostelUUID
		}

		const options = {
			method:'post',
			body: JSON.stringify(data)
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setHostelReviews(result.reviews);
					setIsSuccess(true);
					setCanAddReview(result.can_add_review)

					setReview({
						...review,
						review: "",
						rating: 0
					});

				} else{
					setIsSuccess(false);
					setError(result.message);
				}
				setMessage(result.message);
				setIsLoading(false);
				setShowNotification(true);
			},
			(error) => {
				setIsSuccess(false);
				setIsLoading(false);
				setShowNotification(true);
				setMessage(error);
				setError('Something went wrong!');
			}
		)
	}

	function handleModalOpen(event) {
		setOpen(true);
	}

	return (
		<Grid container spacing={5} className={classes.hostelReviewContainer}>
			<Grid item xs={12}
				style={{
					paddingBottom: 0
				}}
			> 
				<span style={{
					color: "red"
				}}> REVIEWS AND RATINGS </span>
			</Grid>

			{ reviews && reviews.length > 0 ?
				<Grid item xs={6} 
					style={{
						padding: 0,
						paddingLeft: "20px",
						paddingTop: "5px"
					}}> 
					{rating ? <span>{rating}</span> : "" } <Rating name="read-only" value={rating ? rating : 0 } readOnly />
				</Grid> : ""
			}

			{ reviews && reviews.length > 10 ?
				<Grid item xs={6} 
					className={classes.viewAllReviews}
				> 
					<Link 
						// to={`/hostel/${hostelUUID}/tenants/`} 
						// className={classes.hostelTenantList}
					>
						<Button 
							size="medium" 
							color="secondary"
							variant="contained"
							className={classes.viewAllReviewButton}
						>
							VIEW ALL
						</Button>
					</Link> 
				</Grid> : ""
			}

			{ reviews && reviews.length > 0 ? 

				<Grid container className={classes.hostelReviews}>
					{reviews.map((review) => (
						<Grid item xs={12} className={classes.hostelReview} key={review.id}>
							<Grid container direction="row">
								<Grid item xs={2} sm={1} md={1} key={review.id}> 
									<Avatar alt={review.user_profile.user.name} src={review.user_profile.user_image}
										style={{
											marginLeft: 10,
											float: "left"
										}}
									>
										{ review.user_profile.user_image ? "" : getUserCharacters(review.user_profile.user)} 
									</Avatar>
									<div
										style={{
											float: "right",
											position: "absolute",
											marginLeft: "60px"
										}}
									>
										{review.user_profile ? review.user_profile.user.name : ""} <br></br>
										<Typography variant="body2">{review.created_on}</Typography>
									</div>
								</Grid>

								{/* <Grid item xs={10} sm={11} md={11} className={classes.reviewerName}> 
									{review.user_profile ? review.user_profile.user.name : ""} <br></br>
									<Typography variant="body2">{review.created_on}</Typography>
								</Grid> */}
							</Grid> 
		
							<Grid item xs={12} key={review.id} 
								style={{
									paddingTop: "15px",
									paddingLeft: "15px",
									paddingBottom: "20px",
									fontWeight: 300
								}}> 
								{review.review}
							</Grid>
		
						</Grid>
					))}
				</Grid> : 
				<Grid container direction="row" justify="center"> 
					There are no reviews added for this hostel. 
				</Grid> 
			}

			{ canAddReview ?  
				<Grid container onSubmit={handleReviewSubmission}>
					<form noValidate autoComplete="off" className={classes.reviewForm}>

						<Grid item xs={12} className={classes.formField}> 
							<span style={{
								color: "red"
							}}> ADD NEW REVIEW </span>
						</Grid>

						<Grid item xs={12} sm={6} className={classes.formField}>
							<Typography component="legend">Overall Rating</Typography>
							<Rating
								name="rating"
								value={review.rating}
								onChange={(event, newValue) => {
									setReview({
										...review,
										rating: newValue
									});
								}}
							/>
						</Grid>

						<Grid item xs={12} sm={6} className={classes.formField}>
							<TextField 
								variant="outlined"
								margin="dense"
								required
								fullWidth
								multiline
								rows={5}
								id="state"
								label="Review"
								name="review"
								value={review.review}
								onInput = { 
									event => setReview({
										...review,
										review: event.target.value})
								}
							/>
						</Grid>

						{ userDetail ? 
							<Grid item xs={6} sm={4} className={classes.formField}>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									// className={classes.submit}
									// disabled={true ? isLoading: false}
								>
									ADD REVIEW
								</Button>
							</Grid> : 
							
							<Grid item xs={6} sm={4} className={classes.formField}>
								<Button
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleModalOpen}
								>
									ADD REVIEW
								</Button>
							</Grid>
						}

					</form>
				</Grid> : "" 
			}

			{ isLoading ? <SimpleBackdrop open={true} /> : <SimpleBackdrop open={false}/> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>
			
			}

			<RedirectToLogin
				open={open}
				setOpen={setOpen}
				history={history}
				title="You need to login before adding review"
			/>

		{ error ? 
			<Grid container justify="center" spacing={5}> 
				{error}
			</Grid> : "" 
		}

		</Grid>
	)
}

function HostelDetail(props) {

	const { classes, hostelUUID, history } = props;

	const _isMounted = useRef(true);
	const [ error, setError ] = useState(null);
	const [ hostel, setHostel ] = useState({});
	const [ isLoading, setIsLoading ] = useState(true);
	const [ canEdit, setCanEdit ] = useState(false);
	const [ isImageViewerVisibile, setIsImageViewerVisibile ] = useState(false);
	const [ reviews, setHostelReviews ] = useState([]);
	const [ hostelAlreadyBooked, setHostelAlreadyBooked] = useState(false);
	const [ userProfile, setUserProfile] = useState(null);

	const userDetail = useSelector(state => state.user.currentUser);

	useEffect(() => {

		// Update Header Title
		document.title = `Hostlr - Hostel`;

		// Scroll window to top on landing for the first time
		window.scrollTo({
			top: 0,
			behavior: "smooth"
		});
		
		_isMounted.current = true;

		let url = "";

		const loggedInUser = localStorage.getItem("hostlr_user");

		if(loggedInUser && loggedInUser === "host") {
			url = `${config.BASE_APP_URL}/hostels/?hostel_db_id=${hostelUUID}`;
		} else {
			url = `${config.BASE_APP_URL}/city/hostels/?hostel_uuid=${hostelUUID}`;
		}

        const options = {
			method:'get',
            headers: {
                "Content-Type": "application/json"
            }
        }

        ApiWrapper(url, options)
        .then(res => res.json())
        .then(
            (result) => {
                if(result.response){
                    if (_isMounted.current) {
                        setHostel(result.hostel_detail);
						setIsLoading(false);
						setCanEdit(result.can_edit_hostel);
						setHostelReviews(result.reviews);
						setHostelAlreadyBooked(result.already_booked);
						setUserProfile(result.user_profile);
                    }

                } else{
                    if (_isMounted.current) {
                        setError(result.message);
                        setIsLoading(false);
                    }
                }
            },
            (error) => {
                if (_isMounted.current) {
                    setIsLoading(false);
                    setError('Something went wrong!');
                }
            }
        )
        
        // ComponentWillUnmount in Class Component
        return () => { _isMounted.current = false }

	}, [hostelUUID]);


	return (
		<div className={classes.page}>

			<Grid container spacing={5} className={classes.hostelDetail}>

				<Grid item xs={12} sm={6}>
					<Typography variant="h4" className={classes.header}>
						<span className={classes.hostelName}> { hostel ? hostel.name : "" } </span>
					</Typography>

					<Typography variant="subtitle1" className={classes.hostelAddress}>
						{hostel && hostel.address ? 
							<div>
								{hostel.address.first_line}, {hostel.address.second_line}
								{hostel.address.city.name}, {hostel.address.state}, {hostel.address.pin_code}
							</div> : ""
						}
					</Typography>
				</Grid>
				<Grid item xs={12} sm={6} className={classes.tenantListingPageButton}>

					{ canEdit ? 
						<Link 
							to={`/hostel/${hostelUUID}/tenants/`} 
							className={classes.hostelTenantList}
						>
							<Button 
								fullWidth
								size="medium" 
								variant="contained" 
								color="secondary" 
								className={classes.addHostelButton}
							>
								Tenants
							</Button>
						</Link>  : "" 
					}
				</Grid>
			</Grid>

			<Grid container spacing={5} className={classes.hostelRoomAvailability}>

				<Grid item xs={12} key="about-hostel"> 
					<span style={{
						color: "red"
					}}> AVAILABILITY </span>
				</Grid>

				{/* <Grid item xs={12} className={classes.zeroPadding}>
					<div className={classes.subHeading}>
						Total Rooms
					</div>
				</Grid>

				<Grid item xs={12} sm={4} md={3} className={classes.topPadding}>
					Single Sharing : {hostel.single_sharing}
				</Grid>

				<Grid item xs={12} sm={4} md={3} className={classes.topPadding}>
					Double Sharing : {hostel.double_sharing}
				</Grid>

				<Grid item xs={12} sm={4} md={3} className={classes.topPadding}>
					Triple Sharing : {hostel.triple_sharing}
				</Grid> */}

				{/* <Grid item xs={12} className={classes.zeroPadding}>
					<div className={classes.subHeading}>
						Room Vacancy
					</div>
				</Grid> */}

				{ hostel.single_sharing_availability > 0 ?
					<Grid item xs={12} sm={3} md={3} className={classes.topPadding}>
						{/* Single Sharing: {hostel.rooms_detail.single_sharing_vacancy}/{hostel.single_sharing_availability} */}
						Single Sharing: {hostel.rooms_detail.single_sharing_vacancy}/{hostel.single_sharing}
					</Grid> : "Not Available"
				}

				{ hostel.double_sharing_availability > 0 ?
					<Grid item xs={12} sm={3} md={3} className={classes.topPadding}>
						Double Sharing: {hostel.rooms_detail.double_sharing_vacancy}/{hostel.double_sharing}
					</Grid> : "Not Available"
				}

				{ hostel.triple_sharing_availability > 0 ?
					<Grid item xs={12} sm={3} md={3} className={classes.topPadding}>
						Triple Sharing: {hostel.rooms_detail.triple_sharing_vacancy}/{hostel.triple_sharing}
					</Grid> : "Not Available"
				}

			</Grid>

			{ canEdit ? 
				<Link
					to={`/update-hostel/${hostelUUID}/`}
					title="Update Hostel"
				>
					<Fab 
						color="secondary" 
						aria-label="edit" 
						className={classes.floatingEditButton}
					>
						<EditIcon />
					</Fab>
				</Link> : "" 
			}

			{ hostel && hostel.images && hostel.images.length > 0 ? 
				<div className={classes.hostelImages}>
					<DisplayHostelImages
						images={hostel.images}
						classes={classes}
						hostel={hostel}
						setIsImageViewerVisibile={setIsImageViewerVisibile}
						userDetail={userDetail}
						history={history}
						hostelAlreadyBooked={hostelAlreadyBooked}
						setHostelAlreadyBooked={setHostelAlreadyBooked}
						userProfile={userProfile}
					/>
				</div> : ""
			}

			<div className={classes.hostelDetail}>

				{ isImageViewerVisibile ?
					<ImageViewer 
						images={hostel.images}
						open={isImageViewerVisibile} 
						setOpen={setIsImageViewerVisibile}
					/> : ""
				}
			</div>

			{ hostel && hostel.facilities ? 
				<HostelFacilities
					classes={classes}
					facilities={hostel.facilities}
				/>
			: ""}

			{ hostel ? 
				<Grid container spacing={5} className={classes.hostelDescription}>
					<Grid item xs={12} key="about-hostel"> 
						<span style={{
							color: "red"
						}}> About Hostel </span>
					</Grid>
					<Grid item xs={12} key="hostel-description"> 
						{hostel.description}
					</Grid>
				</Grid>
			: ""}

			{ hostel ? 
				<HostelReviews 
					classes={classes}
					rating={hostel.rating}
					reviews={reviews}
					hostelUUID={hostelUUID}
					setHostelReviews={setHostelReviews}
					userDetail={userDetail}
					hostel={hostel}
					history={history}
				/> : ""
			}

			{ isLoading ? <SimpleBackdrop open={true} /> : <SimpleBackdrop open={false}/> }

			{ error ? 
				<Grid container spacing={5} justify="center">
					{error}
				</Grid> : ""
			}
		</div>
	)
}

function HostelDetailPage(props) {

	const classes = useStyles();
	const hostelUUID = props.match.params.uuid;
	const { history } = props;

	return (
		<div className={classes.root}>
			<Header/>
			<HostelDetail 
				classes={classes} 
				hostelUUID={hostelUUID}
				history={history}
			/>
			<Footer/>
		</div>
	);
}

export default HostelDetailPage;