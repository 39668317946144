// import CounterReducer from "./Counter";
import UserDetailReducer from "./UserDetail";
import HostelReducer from "./HostelReducer";

import { combineReducers } from "redux";

const reducers = combineReducers({
    hostel: HostelReducer,
    user: UserDetailReducer
});

export default reducers;