import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';

import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import HomeIcon from '@material-ui/icons/Home';

// DropDown
import Autocomplete from '@material-ui/lab/Autocomplete';
// import GoogleLocationAutoComplete from "../../components/CommonFunctions/GoogleMapsSearch";

import ApiWrapper from "../../apis/DefaultApiWrapper";
import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";
import ToastNotification from '../../components/CommonFunctions/ToastNotification';

import config from "../../config.json";

import {
    Grid
} from '@material-ui/core';

// Form Fields
import TextField from '@material-ui/core/TextField';

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22,
	},
	active: {
		'& $line': {
		backgroundImage:
			'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	completed: {
		'& $line': {
		backgroundImage:
			'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
		},
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1,
	},
})(StepConnector);


const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
	},
	active: {
		backgroundImage:
		'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
	},
	completed: {
		backgroundImage:
		'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
	},
});


function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { active, completed } = props;

	const icons = {
		1: <SettingsIcon />,
		2: <HomeIcon />,
		3: <GroupAddIcon />
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed,
			})}
			>
			{icons[String(props.icon)]}
		</div>
	);
}


ColorlibStepIcon.propTypes = {
	/**
	 * Whether this step is active.
	 */
	active: PropTypes.bool,
	/**
	 * Mark the step as completed. Is passed to child components.
	 */
	completed: PropTypes.bool,
	/**
	 * The label displayed in the step icon.
	 */
	icon: PropTypes.node,
};


const useStyles = makeStyles((theme) => ({
	page: {
		width: '100%',
		minHeight: "80vh",
		background: "#f0f8ff",
		overflow: "hidden"
	},

	button: {
		marginRight: theme.spacing(1),
	},

	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},

	form: {
		padding: "10px",
		background: "white"
	},

	formButton: {
		paddingTop: "20px",
		paddingBottom: "20px"
	}

}));


// function IsNumeric(e) {
// 	var keyCode = e.which ? e.which : e.keyCode
// 	var ret = ((keyCode >= 48 && keyCode <= 57) || specialKeys.indexOf(keyCode) != -1);
// 	document.getElementById("error").style.display = ret ? "none" : "inline";
// 	return ret;
// }

function TenantBasicDetailContainer(props) {

	// const classes = useStyles();
	const { state, setState, genderChoices } = props;

	const [value, setValue] = useState(state.genderValue);
	const [inputValue, setInputValue] = useState(state.genderInputValue);

	var emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;

	function validateEmail(event) {
		if(emailFormat.test(event.target.value)) {
			setState({
				...state,
				emailAddressError: false
			})
		} else {
			setState({
				...state,
				emailAddressError: true
			})
		}
	}

	function handleMobileNumber(event) {

		if(isNaN(state.mobileNumber) || state.mobileNumber.length > 10 || state.mobileNumber.length < 10) {
			setState({
				...state,
				mobileNumberError: true
			})
		} else {
			setState({
				...state,
				mobileNumberError: false
			})
		}
	}


	return (
		<Grid container spacing={3}>

			<Grid item xs={12} sm={6} md={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="firstName"
					label="First Name"
					name="firstName"
					value={state.firstName}
					onInput = { 
						event => setState({
							...state,
							firstName: event.target.value,
							firstNameError: false
						})
					}
					error={state.firstNameError ? true : false}
					helperText={state.firstNameError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="lastName"
					label="Last Name"
					name="lastName"
					value={state.lastName}
					onInput = { 
						event => setState({
							...state,
							lastName: event.target.value,
							lastNameError: false
						})
					}
					error={state.lastNameError ? true : false}
					helperText={state.lastNameError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="emailAddress"
					label="Email Address"
					name="emailAddress"
					value={state.emailAddress}
					onInput = { 
						event => setState({
							...state,
							emailAddress: event.target.value,
						})
					}
					error={state.emailAddressError ? true : false}
					helperText={state.emailAddressError ? "Invalid email Address" : ""}
					onKeyUp={validateEmail}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="mobileNumber"
					label="Mobile Number"
					name="mobileNumber"
					value={state.mobileNumber}
					onBlur={handleMobileNumber}
					onInput = { 
						event => setState({
							...state,
							mobileNumber: event.target.value,
							mobileNumberError: false
						})
					}
					error={state.mobileNumberError ? true : false}
					helperText={state.mobileNumberError ? "Mobile number be should be 10 digits" : ""}
				/>
				{/* <GoogleLocationAutoComplete /> */}
			</Grid>

			<Grid item xs={12} sm={6} md={4}>	
				<Autocomplete
					value={state.genderValue ? state.genderValue : value}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option ? option.title : ""}
					onChange={(event, newValue) => {
						setValue(newValue);
						setState({
							...state,
							genderValue: newValue,
							gender: newValue ? newValue.value : "",
							genderError: false
						})
					}}
					options={genderChoices}
					inputValue={state.genderInputValue ? state.genderInputValue : inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
						setState({
							...state,
							genderInputValue: newInputValue,
						})
					}}
					id="gender"
					fullWidth
					renderInput={
						(params) => <TextField 
							{...params} 
							label="Gender" 
							variant="outlined" 
							margin="dense" 
							error={state.genderError ? true : false}
							helperText={state.genderError ? "This is required field!" : ""}
						/>
					}
				/>

			</Grid>

		</Grid>
	)
}


function TenantAddressContainer(props) {

	// const classes = useStyles();
	const { state, setState } = props;

	function handlePincode(event) {

		if(isNaN(state.pincode) || state.pincode.length > 6 || state.pincode.length < 6) {
			setState({
				...state,
				pincodeError: true
			})
		} else {
			setState({
				...state,
				pincodeError: false
			})
		}
	}

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} sm={6} md={6} lg={6}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="firstLine"
					label="First Line"
					name="firstLine"
					value={state.firstLine}
					onInput = { 
						event => setState({
							...state,
							firstLine: event.target.value,
							firstLineError: false
						})
					}
					error={state.firstLineError ? true : false}
					helperText={state.firstLineError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={6} lg={6}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="secondLine"
					label="Second Line"
					name="secondLine"
					value={state.secondLine}
					onInput = { 
						event => setState({
							...state,
							secondLine: event.target.value,
							secondLineError: false
						})
					}
					error={state.secondLineError ? true : false}
					helperText={state.secondLineError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="city"
					label="City"
					name="city"
					value={state.city}
					onInput = { 
						event => setState({
							...state,
							city: event.target.value,
							cityError: false
						})
					}
					error={state.cityError ? true : false}
					helperText={state.cityError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="state"
					label="State"
					name="state"
					value={state.state}
					onInput = { 
						event => setState({
							...state,
							state: event.target.value,
							stateError: false
						})
					}
					error={state.stateError ? true : false}
					helperText={state.stateError ? "This is required field!" : ""}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="pincode"
					label="Pin Code"
					name="pincode"
					value={state.pincode}
					onInput = { 
						event => setState({
							...state,
							pincode: event.target.value,
							pincodeError: false
						})
					}
					onBlur={handlePincode}
					error={state.pincodeError ? true : false}
					helperText={state.pincodeError ? "Pincode should be 6 digits!" : ""}
				/>
			</Grid>

		</Grid>
	)
}


function TenantHostelDetail(props) {

	// const classes = useStyles();
	const { state, setState, hostels , sharingTypes} = props;

	const [hostelValue, setHostelValue] = useState(state.hostelValue);
	const [inputHostelValue, setInputHostelValue] = useState(state.inputHostelValue);

	const [ sharingTypeValue, setSharingTypeValue] = useState(state.sharingTypeValue);
	const [ inputSharingTypeValue, setInputSharingTypeValue] = useState(state.inputSharingTypeValue);

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<Autocomplete
					id="hostel"
					options={hostels}
					value={state.hostelValue ? state.hostelValue : hostelValue}
					getOptionSelected={(option, value) => option.name === value.name}
					getOptionLabel={(option) => option ? option.name : ""}
					onChange={(event, newValue) => {
						setHostelValue(newValue);
						setState({
							...state,
							hostelValue: newValue,
							hostel: newValue ? newValue.uuid : "",
							hostelError: false
						})
					}}
					inputValue={state.inputHostelValue ? state.inputHostelValue : inputHostelValue}
					onInputChange={(event, newInputValue) => {
						setInputHostelValue(newInputValue);
						setState({
							...state,
							inputHostelValue: newInputValue,
						})
					}}
					fullWidth
					renderInput={
						(params) => <TextField 
							{...params} 
							label="Hostel" 
							variant="outlined" 
							margin="dense"
							error={state.hostelError ? true : false}
							helperText={state.hostelError ? "This is required field!" : ""}
						/>
					}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<Autocomplete
					id="sharingType"
					options={sharingTypes}
					getOptionSelected={(option, value) => option.title === value.title}
					value={state.sharingTypeValue ? state.sharingTypeValue : sharingTypeValue}
					getOptionLabel={(option) => option ? option.title : ""}
					fullWidth
					onChange={(event, newValue) => {
						setSharingTypeValue(newValue);
						setState({
							...state,
							sharingTypeValue: newValue,
							sharingType: newValue ? newValue.value : "",
							sharingTypeError: false
						})
					}}
					inputValue={state.inputSharingTypeValue ? state.inputSharingTypeValue : inputSharingTypeValue}
					onInputChange={(event, newInputValue) => {
						setInputSharingTypeValue(newInputValue);
						setState({
							...state,
							inputSharingTypeValue: newInputValue,
						})
					}}
					renderInput={
						(params) => <TextField 
							{...params} 
							label="Sharing Type" 
							variant="outlined" 
							margin="dense"
							error={state.sharingTypeError ? true : false}
							helperText={state.sharingTypeError ? "This is required field!" : ""}
						/>
					}
				/>
			</Grid>

			<Grid item xs={12} sm={6} md={4} lg={4}>
				<TextField 
					variant="outlined"
					margin="dense"
					required
					fullWidth
					id="roomNumber"
					label="Room Number"
					name="roomNumber"
					value={state.roomNumber}
					autoFocus
					onInput = { 
						event => setState({
							...state,
							roomNumber: event.target.value,
							roomNumberError: false
						})
					}
					error={state.roomNumberError ? true : false}
					helperText={state.roomNumberError ? "This is required field!" : ""}
				/>
			</Grid>

		</Grid>
	)

}


function getSteps() {
	return ['Basic Detail', 'Address', 'Hostel Information'];
}


function getStepContent(step, state, setState, hostels, genderChoices, sharingTypes) {
	switch (step) {
		case 0:
			return <TenantBasicDetailContainer 
					state={state} 
					setState={setState} 
					genderChoices={genderChoices} 
				/>
		case 1:
			return 	<TenantAddressContainer 
					state={state}
					setState={setState}
				/>
		case 2:
			return 	<TenantHostelDetail 
					state={state}
					hostels={hostels}
					setState={setState}
					sharingTypes={sharingTypes}
				/>
		default:
			return 'Unknown step';
	}
}


function TenantAddUpdateStepper(props) {

	const { history, hostels, genderChoices, sharingTypes } = props;
	const classes = useStyles();
	const [activeStep, setActiveStep] = useState(0);
	const [ showNotification, setShowNotification ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ message, setMessage ] = useState(null);
	
	const [isLoading, setIsLoading] = useState(false);
	
	// const [ error, setError] = useState(null);
	
	const steps = getSteps();

	const [ state, setState ] = useState({

		// Tenant Detail
		firstName: "",
		lastName: "",
		gender: "",
		emailAddress: "",
		mobileNumber: "",

		// Address
		firstLine: "",
		secondLine: "",
		city: "",
		state: "",
		country: "",
		pincode: "",

		// Hostel Detail
		hostel: "",
		sharingType: "",
		roomNumber: "",

		// AutoComplete Options
		genderValue: "",
		genderInputValue: "",

		hostelValue: "",
		inputHostelValue: "",

		sharingTypeValue: "",
		inputSharingTypeValue: ""

	});

	function validateUserBasicDetail() {
		if(state.firstName === "" || state.lastName === "" || state.gender === "" || 
		state.emailAddress === "" || state.emailAddressError || state.mobileNumber === "" || 
		state.mobileNumberError) {
			setState({
				...state,
				firstNameError: state.firstName === "" ? true : false,
				lastNameError: state.lastName === "" ? true : false,
				genderError: state.gender === "" ? true : false,
				emailAddressError: state.emailAddress === "" || state.emailAddressError ? true : false,
				mobileNumberError: state.mobileNumber === "" || state.mobileNumberError ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateUserAddresss() {
		if(state.firstLine === "" || state.secondLine === "" || state.city === "" || 
		state.state === "" || state.pincode === "" || state.pincodeError || state.pincode.length !== 6) {
			setState({
				...state,
				firstLineError: state.firstLine === "" ? true : false,
				secondLineError: state.secondLine === "" ? true : false,
				cityError: state.city === "" ? true : false,
				stateError: state.state === "" ? true : false,
				pincodeError: state.pincode === "" || state.pincodeError || state.pincode.length !== 6 ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	function validateHostelDetail() {
		if(state.hostel === "" || state.sharingType === "" || state.roomNumber === "") {
			setState({
				...state,
				hostelError: state.hostel === "" ? true : false,
				sharingTypeError: state.sharingType === "" ? true : false,
				roomNumberError: state.roomNumber === "" ? true : false
			});

			return false;
		} else {
			return true;
		}
	}

	const handleNext = () => {

		if(activeStep === 0 && validateUserBasicDetail()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 1 && validateUserAddresss()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 2 && validateHostelDetail()) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		} else if(activeStep === 3) {
			setActiveStep((prevActiveStep) => prevActiveStep + 1);

		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// const handleReset = () => {
	// 	setActiveStep(0);
	// };

	function addUpdateTenant(event) {
		event.preventDefault();
		setIsLoading(true);

		const url = `${config.BASE_APP_URL}/tenants/`;

		const options = {
			method:'post',
			body: JSON.stringify(state)
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setIsSuccess(true);
					setMessage(result.message);
					setShowNotification(true);

					setTimeout(() => {
						history.push(`/user-profile/${result.tenant.user_profile.uuid}/`);
					}, 300);

				} else{
					setIsSuccess(false);
					setMessage(result.message);
					setShowNotification(true);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setIsSuccess(false);
				setMessage('Something went Wrong!');
				setShowNotification(true);
			}
		)
	}

	return (
		<div className={classes.page}>

			<form noValidate autoComplete="off" onSubmit={addUpdateTenant} className={classes.form}>

				<Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
						</Step>
					))}
				</Stepper>

				<div>
					{activeStep === steps.length ? (
						<Grid container spacing={1} className={classes.formButton}>
							<Grid item xs={6} sm={4} md={4}>
								<Button 
									fullWidth
									variant="contained"
									disabled={activeStep === 0} 
									onClick={handleBack} 
									className={classes.button}
									color="secondary"
								>
									Back
								</Button>
							</Grid>

							<Grid item xs={6} sm={4} md={4}>
								<Button 
										fullWidth
										variant="contained"
										type="submit"
										color="primary"
										className={classes.button}
									>
										Save
									</Button>
							</Grid>

						</Grid>
						) : (
						<div>
							<div className={classes.instructions}>
								{getStepContent(activeStep, state, setState, hostels, genderChoices, sharingTypes)}
							</div>
							<Grid container spacing={1} className={classes.formButton}>
								<Grid item xs={6} sm={4} md={4}>
									<Button 
										fullWidth
										variant="contained"
										disabled={activeStep === 0} 
										onClick={handleBack} 
										className={classes.button}
										color="secondary"
									>
										Back
									</Button>
								</Grid>

								<Grid item xs={6} sm={4} md={4}>
									{activeStep === steps.length - 1 ?
										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
										>
											{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
										</Button> :

										<Button
											fullWidth
											variant="contained"
											color="primary"
											onClick={handleNext}
											className={classes.button}
											>
											{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
										</Button>
								
									}
								</Grid>

							</Grid>
							
						</div>
				
					)}
				</div>
			</form>

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }

			{ showNotification ? 
				<ToastNotification 
					open={true} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}/> : 

				<ToastNotification 
					open={false} 
					setOpen={setShowNotification}
					message={message} 
					isSuccess={isSuccess}
				/>
			
			}

		</div>
	);
}

function AddUpdateTenantPage(props) {

	// const classes = useStyles();
	const { history } = props;
	const tenantUUID = props.match.params.uuid;

	// const [ error, setError ] = useState(null);
	const [ hostels, setHostels ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ genderChoices, setGenderChoices ] = useState([]);
	const [ sharingTypes, setSharingTypes ] = useState([]);

	// const [ hostelAvailableForOptions, setHostelAvailableForOptions ] = useState({});
	// const [ hostelAvailableForValue, setHostelAvailableForValue ] = useState({});
	// const [ inputHostelAvailableForValue, setInputHostelAvailableForValue ] = useState(null);

	const isMounted = useRef(false);

	useEffect(() => {

		isMounted.current = true;

		document.title = 'Hostlr - Add Tenant'

		const url = `${config.BASE_APP_URL}/get_required_form_data/?required_data_for=tenant`;
		const options = {
			method:'get',
			headers: {
				"Content-Type": "application/json"
			}
		}

		if(isMounted.current) {

			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(result.response){
						setHostels(result.hostels);
						setGenderChoices(result.genders);
						setSharingTypes(result.sharing_types);
	
					} else{
						// setError(result.message);

						if(result.status_code === 401) {
							history.push("/login/");
						}
					}
					setIsLoading(false);
				},
				(error) => {
					console.log("error = ", error);
					setIsLoading(false);
					// setError('Something went wrong!');
				}
			)
		}

		return () => isMounted.current = false;

	}, [history]);

	return (
		<div>
			{ isLoading ? "Loading ..." : 
				<div>
					<Header/>
					<TenantAddUpdateStepper 
						history={history} 
						hostels={hostels}
						tenantUUID={tenantUUID} 
						genderChoices={genderChoices}
						sharingTypes={sharingTypes}
					/>
					<Footer/>
				</div> 
			}

			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/> }
		</div>
	);
}

export default AddUpdateTenantPage;