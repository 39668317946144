import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import hostlr_1 from "../../assets/images/hostlr_1.jpg";
import hostlr_2 from "../../assets/images/hostlr_2.jpg";
import hostlr_3 from "../../assets/images/hostlr_3.jpg";
import hostlr_4 from "../../assets/images/hostlr_4.jpg";

import "../../styles/ImageCarousel.scss";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import {
	Card,
	CardMedia,
	Typography,
	Grid
} from '@material-ui/core';

import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme) => ({

	media: {
		height: "250px"
	}

}));

const BannerSettings = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
	  	items: 1,
	  	slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};


const CarouselSettings = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
	  	items: 4,
	  	slidesToSlide: 4 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

const defaultItems = [
	{
		id: 1,
		name: "Affordable Price",
		image: hostlr_1
	},
	{
		id: 2,
		name: "As Cheap As You Desire",
		image: hostlr_2
	},
	{
		id: 3,
		name: "Contact Us For More Detail",
		image: hostlr_3
	},
	{
		id: 4,
		name: "Affordable Price",
		image: hostlr_4
	}
]

function CardCarousel(props) {
	
	const { item } = props;
	const classes = useStyles();

	return (
		<Card raised className="ImageCrousel"
		>
			<Grid container spacing={0} className="CarouselGrid">
				<Grid item xs={12} key={item.name}>
					<CardMedia
						className="Media"
						image={item.image}
						// title={item.name}
					>
						<Typography className="MediaCaption">
							{item.name}
						</Typography>
					</CardMedia>

				</Grid>
			</Grid>
		</Card>
	)
}

function ImageCarousel(props) {

	const { images, carouselType } = props;

	const [medias, setMedias] = useState([])

	useEffect(() => {
		if(images && images.length > 0) {
			setMedias(images)
		} else {
			setMedias(defaultItems)
		}
	});

	return (
		<Carousel
			additionalTransfrom={0}
			arrows
			autoPlaySpeed={3000}
			centerMode={false}
			className=""
			containerClass="container"
			responsive={ carouselType && carouselType == "carousel" ? CarouselSettings : BannerSettings}
			dotListClass=""
			draggable
			focusOnSelect={false}
			infinite={true}
			itemClass=""
			keyBoardControl
			minimumTouchDrag={80}
			renderButtonGroupOutside={false}
			renderDotsOutside={false}
			showDots={true}
		>
			{
				medias.map((item, index) => {
					return <CardCarousel item={item} key={index} />
				})
			}
		</Carousel>

	)
}

export default ImageCarousel;
