import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

import { CircleToBlockLoading } from 'react-loadingg';

const useStyles = makeStyles((theme) => ({
	backdrop: {
		opacity: 1,
		color: '#fff',
		// background: "#f0f8ff",
		zIndex: theme.zIndex.drawer + 1
	},
}));

function SimpleBackdrop(props) {

	const { open } = props;
	const classes = useStyles();

	return (
		<div>
			<Backdrop className={classes.backdrop} open={open}>
				{/* <CircularProgress color="inherit" /> */}
				<CircleToBlockLoading />
			</Backdrop>
		</div>
	);
}

export default SimpleBackdrop;