export function checkAuthToken() {
    if(localStorage.getItem('hostlr_jwt')) {
        return true
    } else {
        return false
    }
};

// Update in redux
export function setUser(payload) {
    return ({ type: "SET_USER", payload });
};

export function logOutUser() {
    return ({type: "USER_LOGOUT"});
};

export function setHostels(payload) {
    return ({ type: "SET_HOSTELS", payload });
};

export function setCurrentCity(payload) {
    return ({ type: "SET_CURRENT_CITY", payload });
};

export function setCurrentHostel(payload) {
    return ({ type: "SET_CURRENT_HOSTEL", payload });
};