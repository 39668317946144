import React, { useState, useEffect } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialUILink from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import HostlrImage from '../../assets/images/hostlr_1.jpg';
import HostlrLogo from '../../assets/images/hostlr.png';

import ApiWrapper from "../../apis/DefaultApiWrapper";

import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";

// Redux'
import { useDispatch } from "react-redux";
import { setUser, logOutUser, checkAuthToken } from "../../actions";

import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';

// Config File
import config from "../../config.json";

import { Link } from "react-router-dom";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<MaterialUILink color="inherit" href="http://hostlr.in/" target="_blank">
				Hostlr
			</MaterialUILink> 2020 - {' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: `url(${HostlrImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
		theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(12, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundImage: `url(${HostlrLogo})`,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
        width: "98%",
		margin: theme.spacing(3, 0, 2),
    },

    cancel: {
        width: "98%",
		margin: theme.spacing(3, 0.5, 2),
    },

	backButtonStyle: {
        top: "50px",
        position: "absolute",
        left: "40px"
	},

	passwordField: {
		width: "100%",
		marginTop: "10px",
		marginRight: "10px"
	},

	rememberMe: {
		paddingTop: "10px"
	},

	error: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#f50057"
	},

	success: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#317326"
	},

	noUnderScore: {
		textDecoration: "none",
		'&:hover': {
			textDecoration: "underline",
		},
	}

}));


function LoginPage(props) {

	const { history } = props;

	// Redux state update
	// const userDetail = useSelector(state => state.user.currentUser);
	const dispatch = useDispatch();

	const currentUrl = history.location.pathname

	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [showPassword, setShowPassword] = useState(false);
	const [message, setMessage] = useState("");

	const [state, setState] = useState({
		username: "",
		password: "",
		currentUser: "",
		loginType: ""
	});

	
	var emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;

	function validateEmail(event) {
		if(emailFormat.test(event.target.value)) {
			setState({
				...state,
				emailError: false
			})
		} else {
			setState({
				...state,
				emailError: true
			})
		}
	}
    
    function userLogin(event) {

		event.preventDefault();

		if(state.emailError || state.username === "") {
			return false
		}
		setIsLoading(true);

		let data = {};
		let method = "post";

		if(state.loginType === "usingOTP") {
			data = {
				username: state.username,
				token: state.otp
			}
			method = "put";
		} else {
			data = {
				username: state.username,
				password: state.password
			}
			method = "post";
		}

		const url = `${config.BASE_APP_URL}/login/`;
		const options = {
			method: method,
			body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					localStorage.setItem('hostlr_jwt', result.token);

					dispatch(setUser(result.user_details));

					if(result.user_details.is_hostel_owner) {
						setTimeout(() => {
							history.push(`/${result.user_details.uuid}/hostels/`);
							localStorage.setItem("hostlr_user", "host");
						}, 150);

					} else {
						setTimeout(() => {
							history.push("/");
							localStorage.setItem("hostlr_user", "tenant");
						}, 150);
					}

				} else{
					setMessage("");
					setError(result.message);
				}
				setIsLoading(false);
			},
			(error) => {
				setMessage("");
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	function sendOTPToEmail() {

		setIsLoading(true);

		const data = {
			username: state.username
		}

		const url = `${config.BASE_APP_URL}/login/`;
		const options = {
			method:'post',
			body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json"
            }
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setError("");
					setMessage(result.message)

				} else{
					setMessage("");
					setError(result.message);
				}
				setIsLoading(false);
			},
			(error) => {
				setMessage("");
				setIsLoading(false);
				setError('Something went wrong!');
			}
		)
	}

	const handleRadioChange = (event) => {

		setState({
			...state,
			loginType: event.target.value,
			loginTypeError: false
		})

		if(state.emailError || state.username === "") {
			setState({
				...state,
				emailError: true
			});
			return false
		}

		if(event.target.value === "usingPassword") {
			return true
		} else if(event.target.value === "usingOTP"){
			sendOTPToEmail()
		} else {
			return false
		}
	};

    useEffect(() => {

		document.title = `Hostlr - Login`;

		const isAuthTokenAvailable = checkAuthToken();

		if(isAuthTokenAvailable && currentUrl.includes('login')) {

			setIsLoading(true);

			console.log("User Already Logged in");

			const url = `${config.BASE_APP_URL}/logged/user/`;
			const options = {
				method:'get',
				headers: {
					"Content-Type": "application/json"
				}
			}
	
			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(result.response){
						dispatch(setUser(result.user_details));
						history.push("/");

						if(result.user_details.is_hostel_owner) {
							setTimeout(() => {
								history.push(`/${result.user_details.uuid}/hostels/`);
								localStorage.setItem("hostlr_user", "host");
							}, 150);
	
						} else {
							setTimeout(() => {
								history.push("/");
								localStorage.setItem("hostlr_user", "tenant");
							}, 150);
						}
	
					} else{
						setError(result.message);
					}
					setIsLoading(false);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log("error = ", error);
					setIsLoading(false);
					setError('Something went wrong!');
				}
			)

		} else if(isAuthTokenAvailable && currentUrl.includes('logout')) {
			console.log("User Requested for logout");

			localStorage.removeItem('hostlr_jwt');
			localStorage.removeItem('hostlr_user');
			dispatch(logOutUser());
			history.push("/login/");

		} else {
			localStorage.removeItem('hostlr_jwt');
			localStorage.removeItem('hostlr_user');
			history.push("/login/");
			dispatch(logOutUser());
			console.log('User not Authenticated');
		}

	}, [history, currentUrl, dispatch]);

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />

			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

				<div className={classes.paper}>
					<Avatar className={classes.avatar}></Avatar>
					<form 
						className={classes.form} 
						noValidate
						onSubmit={userLogin}
					>
						<TextField
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="username"
							label="Username"
							name="username"
							autoComplete="username"
							onInput = { 
								event => setState({
									...state,
									username: event.target.value
								})
							}
							error={state.emailError ? true : false}
							helperText={state.emailError ? "Invalid email Address" : ""}
							onKeyUp={validateEmail}
						/>

						<FormControl fullWidth error={state.loginTypeError} className={classes.formControl}>
							<RadioGroup aria-label="Type" name="loginType" value={state.loginType} onChange={handleRadioChange}>
								<Grid container > 
									<Grid item xs={6}>
										<FormControlLabel value="usingOTP" control={<Radio />} label="Login Using OTP" />
									</Grid>
									<Grid item xs={6}>
										<FormControlLabel value="usingPassword" control={<Radio />} label="Use Password" />
									</Grid>
								</Grid>
							</RadioGroup>
							<FormHelperText>{state.loginTypeError ? "Please select login type" : ""}</FormHelperText>
						</FormControl>

						{ state.loginType === "usingPassword" ?
							<FormControl variant="outlined" className={classes.passwordField}>
								<InputLabel htmlFor="password">Password</InputLabel>
								<OutlinedInput 
									fullWidth
									id="password"
									type={showPassword ? 'text' : 'password'}
									value={state.password}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
												edge="end"
											>
											{showPassword ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									}
									onInput = { 
										event => setState({
											...state,
											password: event.target.value
										})
									}
									labelWidth={70}
								/>
							</FormControl> : ""
						
						}

						{ state.loginType === "usingOTP" ?
						
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="otp"
								label="OTP"
								name="otp"
								onInput = { 
									event => setState({
										...state,
										otp: event.target.value
									})
								}
							/> : ""
						}

						{ state.loginType === "usingPassword" ? 
							<FormControlLabel
								className={classes.rememberMe}
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/> : ""
						}
						
						
						<Grid container>
							<Grid item xs>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
									className={classes.submit}
									disabled={true ? isLoading: false}
                                >
                                    Sign In
                                </Button>
							</Grid>
							<Grid item xs>
								<Link 
									to={`/`}
									className={classes.noUnderScore}
								>
									<Button
										fullWidth
										variant="contained"
										size="medium"
										color="secondary"
										className={classes.cancel}
										disabled={true ? isLoading: false}
									>
										Cancel
									</Button>
								</Link>
                                
							</Grid>
						</Grid>

						{ error ? 
							<div className={classes.error}>
								{error}
							</div> : ""
						}

						{message ?
							<div className={classes.success}>
								{message}
							</div> : ""
						}
						
						<Grid container>
							<Grid item xs>
								<Link to="/forgot-password/"
									className={classes.noUnderScore}
								>
									Forgot password?
								</Link>
							</Grid>
							<Grid item>
								<Link to="/create-account/"
									className={classes.noUnderScore}
								>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
						
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>

					{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/>}
					
				</div>
			</Grid>
		</Grid>
	);
}

export default LoginPage;