import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		paddingBottom: 15,
		paddingTop: 15
	},
	
	border: {
		width: "100%",
		borderBottom: "1px solid black"
	},
	
	content: {
        width: "100%",
        textAlign: "center"
		// padding: "0 2% 0 2%"
	}
}));

function HorizontalDividerWithText(props) {

	const classes = useStyles();
	const { title} = props

	return (
		<div className={classes.container}>
			<div className={classes.border}/>
			<span className={classes.content}>
				<b>{title}</b>
			</span>
			<div className={classes.border}/>
		</div>
	)
}

export default HorizontalDividerWithText;