import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import MaterialUILink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import HostlrImage from '../../assets/images/hostlr_3.jpg';
import HostlrLogo from '../../assets/images/hostlr.png';

import config from "../../config.json";
import ApiWrapper from "../../apis/DefaultApiWrapper";
import SimpleBackdrop from "../../components/CommonFunctions/SimpleBackdrop";

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<MaterialUILink color="inherit" href="http://hostlr.in/" target="_blank">
				Hostlr
			</MaterialUILink> 2020 - {' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100vh',
	},
	image: {
		backgroundImage: `url(${HostlrImage})`,
		backgroundRepeat: 'no-repeat',
		backgroundColor:
		theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	},
	paper: {
		margin: theme.spacing(12, 4),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundImage: `url(${HostlrLogo})`,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		width: "98%",
		margin: theme.spacing(3, 0, 2),
	},

	cancel: {
		width: "98%",
		margin: theme.spacing(3, 0.5, 2),
	},

	backButtonStyle: {
		top: "50px",
		position: "absolute",
		left: "40px"
	},

	error: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#f50057"
	},

	success: {
		color: "white",
		padding: "10px",
		borderRadius: 8,
		textAlign: "center",
		marginBottom: "10px",
		background: "#317326"
	},

	noUnderScore: {
		textDecoration: "none",
		'&:hover': {
			textDecoration: "underline",
		},
	}

}));

function ForgotPasswordPage() {

	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState(null);
	const [error, setError] = useState(null);

	const [state, setState] = useState({
		email: "",
		emailError: false
	});

	var emailFormat = /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,})+$/;

	function validateEmail(event) {
		if(emailFormat.test(event.target.value)) {
			setState({
				...state,
				emailError: false
			})
		} else {
			setState({
				...state,
				emailError: true
			})
		}
	}

	function sendResetPasswordLink(event) {

		event.preventDefault();

		if(state.emailError) {
			return false
		}

		setIsLoading(true);

		const data = {
			user_email: state.email
		}

		const url = `${config.BASE_APP_URL}/forgot-password/`;
		const options = {
			method:'post',
			body: JSON.stringify(data),
			headers: {
				"Content-Type": "application/json"
			}
		}

		ApiWrapper(url, options)
		.then(res => res.json())
		.then(
			(result) => {
				if(result.response){
					setError(null);
					setState({
						...state,
						email: "",
						emailError: false
					})
					setMessage(result.message);

				} else{
					setError(result.message);
					setMessage(null);
				}
				setIsLoading(false);
			},
			(error) => {
				setIsLoading(false);
				setError('Something went wrong!');
				setMessage(null);
			}
		)
	};

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />

			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>

				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
					</Avatar>
					<form className={classes.form} noValidate onSubmit={sendResetPasswordLink}>
						<TextField 
							error={state.emailError ? true : false}
							variant="outlined"
							margin="normal"
							required
							fullWidth
							id="email"
							label="Email Address"
							name="email"
							autoFocus
							value={state.email}
							onInput = { 
								event => setState({
									...state,
									email: event.target.value
								})
							}
							helperText={state.emailError ? "Invalid email Address" : ""}
							onKeyUp={validateEmail}
						/>
						
						<Grid container>
							<Grid item xs>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									className={classes.submit}
								>
									Send Reset Password Link
								</Button>
							</Grid>
							<Grid item xs>
								<Link 
									to={`/`}
									className={classes.noUnderScore}
								>
									<Button
										fullWidth
										variant="contained"
										size="medium"
										color="secondary"
										className={classes.cancel}
										disabled={true ? isLoading: false}
									>
										Cancel
									</Button>
								</Link>
							</Grid>
						</Grid>

						{ error ? 
							<div className={classes.error}>
								{error}
							</div> : ""
						}

						{message ?
							<div className={classes.success}>
								{message}
							</div> : ""
						}
						
						<Grid container>
							<Grid item xs>
								<Link to="/login" className={classes.noUnderScore}>
									Remember Password?
								</Link>
							</Grid>
							<Grid item>
								<Link to="/create-account/" className={classes.noUnderScore}>
									{"Don't have an account? Sign Up"}
								</Link>
							</Grid>
						</Grid>
						
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
					
				</div>
			</Grid>
		
			{ isLoading ? <SimpleBackdrop open={true}/> : <SimpleBackdrop open={false}/>}
		</Grid>
	);
}

export default ForgotPasswordPage;