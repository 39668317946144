const defaultState = {
    currentUser: {}
}

function userReducer(state = defaultState, action) {

    switch(action.type) {
        case "SET_USER":
            return {
                ...state,
                currentUser: action.payload
            }
        case "USER_LOGOUT":
            return {
                ...state,
                currentUser: undefined
            }
        default:
            return state.currentUser
    }
}

export default userReducer;