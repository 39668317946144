import React, { useEffect, useState, useRef } from 'react';
// import Carousel from "react-material-ui-carousel";
import { makeStyles } from '@material-ui/core/styles';
import hostlr_1 from "../../assets/images/hostlr_1.jpg";

import "../../styles/BannerCarousel.scss";

// import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { Link } from "react-router-dom";

import {
    Card,
    CardMedia,
    Typography,
    Grid
} from '@material-ui/core';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

// import { useHistory } from "react-router-dom";
import SimpleBackdrop from "../CommonFunctions/SimpleBackdrop";
// import AppWaveSkeleton from "../CommonFunctions/AppSkeleton";

// API Calls
import ApiWrapper from "../../apis/DefaultApiWrapper";

// ENV Settings
import config from "../../config.json";

// Redux
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({

    Page: {
        margin: "5px 2px 5px 5px",
        color: "#494949"
	},

	CarouselArea: {
		marginTop: "-35px",
		position: "absolute"
	},
	
	SearchBar: {
		borderRadius :"50px",
		position: "absolute",
		top: "49vh",
		background: "white",
		width: "80%",
		left: "9%",
		alignContent: "center",
		// padding: "8px",
		display: "none",

		[theme.breakpoints.up("sm")]: {
			display: "block"
        }
	},

	mobileSearchBar: {
		borderRadius :"20px",
		position: "absolute",
		top: "44vh",
		background: "white",
		width: "80%",
		left: "9%",
		alignContent: "center",
		padding: "12px",
		display: "block",

		[theme.breakpoints.up("sm")]: {
			display: "none"
        }
	},

	searchHeader: {
		position: "absolute",
		top: "37vh",
		color: "white",
		textAlign: "center",
		left: "30%",
		fontSize: 40,
		display: "none",

		// [theme.breakpoints.up("sm")]: {
		// 	display: "block"
        // }
	},

	SearchIcon: {
		borderRadius: "50px"
	},

	root: {
		padding: '2px 4px',
		display: 'flex',
		alignItems: 'center',
		width: "100%",
	},
	
	input: {
		marginLeft: theme.spacing(1),
		flex: 1,
	},

	iconButton: {
		padding: 10,
	},

	divider: {
		height: 70,
		margin: 4,
	},

	searchDateInput: {
		marginTop: 0
	},

	searchInput: {
		marginTop: "25px"
	},

	searchInputWeb: {
		width: "60%",
		[theme.breakpoints.up("md")]: {
			left: "100%"
        }
	},

	searchButton: {
		fontSize: 25,
		float: "right",
		borderRadius: 50,
		marginTop: "10px",

		[theme.breakpoints.up("sm")]: {
			left: "20%",
		},

		[theme.breakpoints.up("md")]: {
			left: "40%"
        }
	},

	searchIcon: {
		fontSize: "30px !important",
		borderRadius: 50
	},

	CitiesContainer: {
		height: "90vh",
		background: "#f4fbfd"
	},

	CitiesContainerHeading: {
		// left: "30%",
		textAlign: "center",
		opacity: 0.3,
		fontSize: 100,
		top: "7%",
		position: "relative",
		fontFamily: "Snell Roundhand, cursive",
	},

	CityDisplayed: {
		top: "115%",
		left: "50%",
		fontSize: 30,
		fontWeight: 700,
		position: "absolute",
		marginBottom: "20px"

	},

	HostelContainer: {
		height: "90vh"
	},

	HostelContainerHeading: {
		// left: "30%",
		textAlign: "center",
		opacity: 0.3,
		fontSize: 100,
		top: "7%",
		position: "relative",
		fontFamily: "Snell Roundhand, cursive",
		
	},

	HostelDisplayed: {
		top: "205%",
		left: "45%",
		fontSize: 30,
		fontWeight: 700,
		position: "absolute",
		marginBottom: "20px"
	},

	// Card Medias
	card: {
		maxWidth: 300,
	},

	media: {
		marginLeft: "25%",
		top: "15px",
		height: 150,
		width: "50%",
		borderRadius: "50%",
		position: "relative"
	},

	HostelMedia: {
		height: 250,
		position: "relative"
	},

	CardInfo: {
		textAlign: "center"
	},

	CardTitle: {
		marginBottom: "10px",
		marginTop: "10px"
	},

	CitiesCarousel: {
		marginTop: "15vh",
		marginLeft: "3%"
    	// position: "absolute"
	},

	HostelCarousel: {
		marginTop: "15vh",
		marginLeft: "3%"
    	// position: "absolute"
	},

	// Carousel: {
	// 	left: "5%",
	// 	right: "5%"
	// }

	NoDecoration: {
		textDecoration: "none"
	},

	calendarWidth: {
		width: "100%",

		[theme.breakpoints.up("sm")]: {
			width: "80%"
        }
	},

	mobileSearchBarGrid: {
		width: "90%",
		marginLeft: "5% !important"
	}



}));


// function formatDate(date) {
// 	return ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))  + '/' + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +  '/' + date.getFullYear();
// }

function MaterialUIPickers(props) {

	const classes = useStyles()
	const { selectedDate,  setSelectedDate, calendarKey } = props;

	const handleDateChange = (date) => {
		setSelectedDate(date);
	};

	var today = new Date();

	var maxDate = new Date()
	maxDate.setDate(today.getDate() + 60);
  
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<Grid container justify="space-around">
				<KeyboardDatePicker
					margin="normal"
					className={classes.calendarWidth}
					id={calendarKey}
					label="Start Date"
					format="dd/MM/yyyy"
					value={selectedDate}
					minDate={today}
					maxDate={maxDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						'aria-label': 'change date',
					}}
				/>
			</Grid>
		</MuiPickersUtilsProvider>
	);
}

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    // const totalItems = props.length ? props.length : 3;
    // const mediaLength = totalItems - 1;
    // const mediaLength = 1;
	
	const item = props.item

	// const [shadow, setShadow] = React.useState(1);
	// const onMouseOver = () => {
	// 	setShadow(3)
	// }
	// const onMouseOut = () => {
	// 	setShadow(1)
	// }

    return (
        <Card raised className="Banner"
			// onMouseOver={onMouseOver}
			// onMouseOut={onMouseOut}
			// zdepth={shadow}
		>
            <Grid container spacing={0} className="BannerGrid">
				<Grid item xs={12} key={item.Name}>
					<CardMedia
						className="Media"
						image={item.Image}
						title={item.Name}
					>
						<Typography className="MediaCaption">
							{item.Name}
						</Typography>
					</CardMedia>

				</Grid>
            </Grid>
        </Card>
    )
}

// City Cards
function CitiesMediaCard(props) {
	const classes = props.classes;
	const item = props.item

	return (
		<Link
			to={`/city/${item.uuid}/hostels/`}
			className={classes.NoDecoration}
		>
			<Card className={classes.card}>
				<CardActionArea>
					<CardMedia
						key = {item.id}
						className={classes.media}
						image={item.city_image}
						title={item.name}
					/>
					<CardContent className={classes.CardInfo}>
						<Typography gutterBottom variant="h5" component="h2" className={classes.CardTitle}>
							{item.name}
						</Typography>
						<Typography variant="body2" color="textSecondary" component="p">
							{item.description}
						</Typography>
					</CardContent>
				</CardActionArea>
				{/* <CardActions>
					<Button size="small" color="primary">
					Share
					</Button>
					<Button size="small" color="primary">
					Learn More
					</Button>
				</CardActions> */}
			</Card>
		</Link>
	);
}

// Hostel cards
function HostelMediaCard(props) {

	const {classes, item, DefaultHostelImage } = props;

	return (
		<Card raised className={classes.card}
		>
			<CardMedia
				className={classes.HostelMedia}
				image={ item.image ? item.image : DefaultHostelImage }
				title={item.name}
			>
				<Typography className="HostelMediaCaption">
					{item.name}
				</Typography>
			</CardMedia>
		</Card>
	);
}

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
	  	items: 1,
	  	slidesToSlide: 1 // optional, default to 1.
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	}
};

const HostelcitiesCarouselSettings = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		slidesToSlide: 2
	},

	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 1
	},

	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1
	}
}

// const CustomDot = ({ onClick, ...rest }) => {
// 	const {
// 		onMove,
// 		index,
// 		active,
// 		carouselState: { currentSlide, deviceType }
// 	} = rest;
// 	const carouselItems = [hostlr_1, hostlr_2, hostlr_3, hostlr_1, hostlr_2, hostlr_3];
// 	// onMove means if dragging or swiping in progress.
// 	// active is provided by this lib for checking if the item is active or not.
// 	return (
// 		<button
// 			className={active ? "active" : "inactive"}
// 			onClick={() => onClick()}
// 		>
// 			{React.Children.toArray(carouselItems)[index]}
// 		</button>
// 	);
// };

function BannerCarousel(props) {

	const classes = useStyles()

	const { history, UploadedItems } = props;

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [CitiesList, setCitiesList] = useState([]);
	const [HostelsList, setHostelList] = useState([]);

	const [cityValue, setCityValue] = useState("");
	const [isCityError, setIsCityError] = useState(false);
	const [inputCityValue, setInputCityValue] = useState("");
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [isHostelOwner, setIsHostelOwner] = useState(true);

	const _isMounted = useRef(true);

	const userDetail = useSelector(state => state.user.currentUser);

	const hostlrUser = localStorage.getItem('hostlr_user')

	// API call to get CitiesList 
	useEffect(() => {

		if(!isHostelOwner) {
			_isMounted.current = true;

			// Update the document title using the browser API
			document.title = `Hostlr - Home`;
	
			const url = `${config.BASE_APP_URL}/cities/`;
	
			const options = {
				method:'get',
				headers: {
					"Content-Type": "application/json"
				}
			}
	
			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(_isMounted.current) {
						if(result.response){
							setCitiesList(result.cities);
						} else{
							setError(result.message);
						}
					}
					// setIsLoading(false);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					if(_isMounted.current) {
						console.log("error = ", error);
						// setIsLoading(false);
						setError(error);
					}
				}
			)
		}

		return () => { _isMounted.current = false }

	}, [isHostelOwner]); // eslint-disable-line react-hooks/exhaustive-deps

	console.log(error);

	// API call to get Hostel List 
	useEffect(() => {

		if(!isHostelOwner) {

			_isMounted.current = true;

			const url = `${config.BASE_APP_URL}/city/hostels/`;
	
			const options = {
				method:'get',
				headers: {
					"Content-Type": "application/json"
				}
			}
	
			ApiWrapper(url, options)
			.then(res => res.json())
			.then(
				(result) => {
					if(_isMounted.current) {
						if(result.response){
		
							setHostelList(result.hostels);
		
						} else{
							setError(result.message);
						}
					}
					setIsLoading(false);
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					if(_isMounted.current) {
						console.log("error = ", error);
						setIsLoading(false);
						setError(error);
					}
				}
			)
		}

		return () => { _isMounted.current = false }

	}, [isHostelOwner]); // eslint-disable-line react-hooks/exhaustive-deps


	useEffect(() => {

		_isMounted.current = true;

		if(userDetail && userDetail.is_tenant && hostlrUser === "tenant") {
			setIsHostelOwner(false);

		} else if(userDetail && userDetail.is_hostel_owner && hostlrUser === "host") {
			setIsHostelOwner(true);
			setIsLoading(true);

			setTimeout(() => {
				history.push(`/${userDetail.uuid}/hostels/`);
				return () => { _isMounted.current = false }
			}, 100);

		}else {
			setIsHostelOwner(false);
		}

		return () => { _isMounted.current = false }

	}, [userDetail]); // eslint-disable-line react-hooks/exhaustive-deps


	function searchHostels(event) {

		if(!cityValue || isCityError) {
			setIsCityError(true);
			return false
		} else {
			setIsLoading(true);
			setTimeout(() => {
				history.push(`/city/${cityValue.uuid}/hostels/`);
			}, 500);
		}
	}


    return (
        <div className="Page">

			<Carousel
				additionalTransfrom={0}
				arrows
				autoPlaySpeed={3000}
				centerMode={false}
				className=""
				containerClass="container"
				responsive={responsive}
				dotListClass=""
				draggable
				focusOnSelect={false}
				infinite={true}
				itemClass=""
				keyBoardControl
				minimumTouchDrag={80}
				renderButtonGroupOutside={false}
				renderDotsOutside={false}
				showDots={true}
				// customDot={<CustomDot />}
			>
				{
                    UploadedItems.BannerImages.map((item, index) => {
                        return <Banner item={item} key={index} />
                    })
                }
			</Carousel>

			<div className={classes.searchHeader}>Looking for near by hostel ? </div>

			<div className={classes.SearchBar}>
				<Grid container>

					<Grid item xs={4} className={classes.searchDateInput}>
						<MaterialUIPickers
							// key="datePicker"
							calendarKey="datePicker"
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					</Grid>
					<Divider className={classes.divider} orientation="vertical" />

					<Grid item xs={5} className={classes.searchInput}>
						<Autocomplete
							id="city"
							options={CitiesList}
							getOptionSelected={(option, value) => option && value ? option.name === value.name : ""}
							value={cityValue ? cityValue : ""}
							getOptionLabel={(option) => option ? option.name : ""}
							fullWidth
							// className={classes.searchInputWeb}
							onChange={(event, newValue) => {
								setCityValue(newValue);
								setIsCityError(false);

							}}
							inputValue={inputCityValue ? inputCityValue : ""}
							onInputChange={(event, newInputValue) => {
								setInputCityValue(newInputValue);
								setIsCityError(false);
							}}
							renderInput={
								(params) => <TextField
									{...params}
									// label="City"
									// margin="dense"
									error={isCityError ? true : false}
									helperText={isCityError ? "Select a city to view hostels" : ""}
								/>
							}
						/>
					</Grid>

					<Grid item xs={2}>
						<Button
							variant="contained"
							color="secondary"
							size="large"
							className={classes.searchButton}
							// className="SearchButton"
							startIcon={<SearchIcon className={classes.searchIcon}/>}
							onClick={searchHostels}
						>
							SEARCH
						</Button>
					</Grid>

				</Grid>
			</div>

			<div className={classes.mobileSearchBar}>
				<Grid container spacing={2} className={classes.mobileSearchBarGrid}>

					<Grid item xs={12}>
						<MaterialUIPickers
							// key="mobileDatePicker"
							calendarKey="mobileDatePicker"
							fullWidth
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					</Grid>
					{/* <Divider className={classes.divider} orientation="vertical" /> */}

					<Grid item xs={12}>
						<Autocomplete
							id="mobileCity"
							options={CitiesList}
							getOptionSelected={(option, value) => option && value ? option.name === value.name : ""}
							value={cityValue ? cityValue : ""}
							getOptionLabel={(option) => option ? option.name : ""}
							
							onChange={(event, newValue) => {
								setCityValue(newValue);
								setIsCityError(false);

							}}
							inputValue={inputCityValue ? inputCityValue : ""}
							onInputChange={(event, newInputValue) => {
								setInputCityValue(newInputValue);
								setIsCityError(false);
							}}
							renderInput={
								(params) => <TextField
									{...params}
									// label="City"
									// margin="dense"
									error={isCityError ? true : false}
									helperText={isCityError ? "Select a city to view hostels" : ""}
								/>
							}
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							variant="contained"
							color="secondary"
							size="large"
							fullWidth
							className="acceptButton"
							// className="SearchButton"
							startIcon={<SearchIcon className={classes.searchIcon}/>}
							onClick={searchHostels}
						>
							SEARCH
						</Button>
					</Grid>

				</Grid>
			</div>


			<div className={classes.CitiesContainer}>

				<div className={classes.CitiesContainerHeading}>
					Choose Your
				</div>

				<div className={classes.CityDisplayed}>
					CITY
				</div>

				<div className={classes.CitiesCarousel}>
					<Carousel
						// className={classes.Carousel}
						additionalTransfrom={0}
						arrows
						autoPlaySpeed={3000}
						centerMode={false}
						className=""
						containerClass="container"
						responsive={HostelcitiesCarouselSettings}
						dotListClass=""
						draggable
						focusOnSelect={false}
						infinite={false}
						itemClass=""
						keyBoardControl
						minimumTouchDrag={80}
						renderButtonGroupOutside={false}
						renderDotsOutside={false}
					>

						{
							CitiesList.map((item, index) => {
								return <CitiesMediaCard classes={classes} item={item} key={index} />
							})
						}
					</Carousel>
				</div>

			</div>

			<div className={classes.HostelContainer}>

				<div className={classes.HostelContainerHeading}>
					Choose Your
				</div>

				<div className={classes.HostelDisplayed}>
					HOSTEL
				</div>

				<div className={classes.HostelCarousel}>
					<Carousel
						additionalTransfrom={0}
						arrows
						autoPlaySpeed={3000}
						centerMode={false}
						className=""
						containerClass="container"
						responsive={HostelcitiesCarouselSettings}
						dotListClass=""
						draggable
						focusOnSelect={false}
						infinite={false}
						itemClass=""
						keyBoardControl
						minimumTouchDrag={80}
						renderButtonGroupOutside={false}
						renderDotsOutside={false}
					>
						{
							HostelsList.map((item, index) => {
								return (
									<Link to={`/hostel/${item.uuid}/`} key={index}>
										<HostelMediaCard 
											classes={classes} 
											item={item} 
											key={index} 
											DefaultHostelImage={UploadedItems.DefaultHostelImage}
										/>
									</Link> 
								)
							})
						}
					</Carousel>
				</div>

			</div>

			{ isLoading ? <SimpleBackdrop open={true} /> : <SimpleBackdrop open={false}/> }
        </div>
    )

}

export default BannerCarousel;