import React from "react";
import MaterialUILink from '@material-ui/core/Link';
import { Link } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import Divider from '@material-ui/core/Divider';

import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles((theme) => ({

    footer: {
        minHeight: "350px",
        color: "white",
        backgroundColor: "#232323"
    },

    // siteRelatedInfo: {
    //     paddingLeft: "7% !important",
    //     paddingTop: "3% !important"
    // },

    about: {
        paddingLeft: "3% !important",
        paddingTop: "3% !important",

        [theme.breakpoints.up("sm")]: {
			paddingLeft: "7% !important",
        }
    },

    support: {
        paddingLeft: "3% !important",
        paddingTop: "3% !important",

        [theme.breakpoints.up("sm")]: {
			paddingLeft: "10% !important",
        }
    },

    contact: {
        paddingLeft: "3% !important",
        paddingTop: "3% !important",

        [theme.breakpoints.up("sm")]: {
			paddingLeft: "12% !important",
        }
    },

    footerLink: {
        color: "white",
        marginBottom: "20px",
        textDecoration: "none",
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },

    leftSpace: {
        marginLeft: "10px"
    },

    socialSites: {
        paddingRight: "10px"
    },

    divider: {
        height: 2,
        marginTop: "20px",
        marginBottom: "20px"
    },

    copyright: {
        color: "white",
        textDecoration: "none",
    }

}));

function Footer() {

    // let copy = React.string(`{js|\u00a9|js}`);

    const classes = useStyles();
    return (
        <div className={classes.footer}>

            <Grid container spacing={1}>
                <Grid item xs={4} className={classes.about}>
                    <h3>
                        Who We Are
                    </h3> <br></br>

                    <Link to="/about-us/"
                        className={classes.footerLink}
                    >
                        {/* <text>
                            About Us {"\n"}
                        </text> */}
                        About Us
			        </Link>

                    <Link to="/careers/"
                        className={classes.footerLink}
                    >
                        Careers
			        </Link>

                    <Link to="/blog/"
                        className={classes.footerLink}
                    >
                        Blog
			        </Link>
                </Grid>

                <Grid item xs={4} className={classes.support}>
                    <h3>
                        Support
                    </h3> <br></br>

                    <Link to="/contact-us/"
                        className={classes.footerLink}
                    >
                        Contact Us
			        </Link>

                    <Link to="/terms-and-conditions/"
                        className={classes.footerLink}
                    >
                        Terms and Conditions
			        </Link>

                    <Link to="/privacy-policy/"
                        className={classes.footerLink}
                    >
                        Privacy Policy
			        </Link>
                </Grid>

                <Grid item xs={4} className={classes.contact}>
                    <h4>
                        Reach Us
                    </h4> <br></br>

                    <Link to="#"
                        className={classes.footerLink}
                    >
                        <PhoneOutlinedIcon fontSize="small" />
                        <span className={classes.leftSpace}>
                            +91 99 9999 9999
                        </span>
			        </Link>

                    <Link to="#"
                        className={classes.footerLink}
                    >
                        <EmailOutlinedIcon fontSize="small" />
                        <span className={classes.leftSpace}>
                            support@hostlr.in
                        </span>
			        </Link>

                    <Link to="#"
                        className={classes.footerLink}
                    >
                        <FacebookIcon className={classes.socialSites}/> 
                        <YouTubeIcon className={classes.socialSites}/> 
                        <TwitterIcon className={classes.socialSites}/> 
                        <LinkedInIcon className={classes.socialSites}/>
			        </Link>
                </Grid>
            </Grid>

            <Divider
                className={classes.divider}
            />

            <Grid container spacing={1} className={classes.siteRelatedInfo}>
                <Grid item xs={12} align="center">
                <Typography variant="body1">
			            {'Copyright © '}
			            <MaterialUILink href="https://hostlr.in/"
                            className={classes.copyright}
                            target="_blank"
                        >
				            Hostlr
			            </MaterialUILink> 2020 - {' '}
			            {new Date().getFullYear()}. All right reserved.
		            </Typography>
                    {/* &copy; Hostlr {new Date().getFullYear()}. All right reserved. */}
                </Grid>
            </Grid>

        </div>
    );
}

export default Footer;